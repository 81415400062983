import { useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { getPaginationProps } from "src/hooks/pagination";
import Api from "src/api";
import { Shipment, ShipmentStatus } from "src/api/shipments";
import { useAdminStore } from "src/store";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { parseShipmentStatus } from "src/helpers/shipments";

const SupplierShipments = () => {
  const { currentCompany } = useAdminStore();
  const params = useParams();
  const supplierId = Number(params.id);
  const navigate = useNavigate();

  const { data: supplier } = useQuery(Api.Suppliers.GET_SUPPLIER, {
    variables: {
      id: supplierId,
    },
    onError: () => {
      navigate(-1);
    },
  });

  const { data: shipments, loading } = useQuery<GraphQLMeta<Shipment>>(
    Api.Shipments.LIST_SHIPMENTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          supplierId,
          limit: 0,
        },
      },
    }
  );

  const [createShipment, { loading: creating }] = useMutation(
    Api.Shipments.CREATE_SHIPMENT,
    {
      onCompleted: ({ data }) => {
        navigate(`/suppliers/${supplierId}/shipments/${data.id}`);
      },
    }
  );

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={9}>Pedidos al Proveedor "{supplier?.data?.name}"</CCol>
              <CCol sm={3} className="text-right">
                <CLoadingButton
                  color="primary"
                  loading={creating}
                  disabled={loading || creating}
                  className="ml-3"
                  size="sm"
                  onClick={() => {
                    createShipment({
                      variables: {
                        input: {
                          companyId: currentCompany?.id,
                          supplierId,
                        },
                      },
                    });
                  }}
                >
                  Cargar nuevo pedido
                </CLoadingButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CSmartTable
              loading={loading}
              itemsPerPage={20}
              items={shipments?.data.data || []}
              {...getPaginationProps(shipments?.data.data ?? [])}
              columns={[
                { key: "id", label: "ID" },
                {
                  key: "status",
                  label: "Estado",
                  _props: { className: "text-center" },
                },
                {
                  key: "amount",
                  label: "Monto Pedido",
                  _props: { className: "text-right" },
                },
                {
                  key: "amountDelivered",
                  label: "Entregado / Confirmado",
                  _props: { className: "text-right" },
                },
                {
                  key: "orderedAt",
                  label: "Fecha de Pedido",
                  _props: { className: "text-right" },
                },
                {
                  key: "deliveredAt",
                  label: "Confirmacion / Entrega",
                  _props: { className: "text-right" },
                },
              ]}
              scopedColumns={{
                amount: (item: Shipment) => (
                  <td className="text-right">
                    {formatCurrency(item.orderedTotal)}
                  </td>
                ),
                amountDelivered: (item: Shipment) => (
                  <td className="text-right">
                    {item.status === ShipmentStatus.Delivered
                      ? formatCurrency(item.deliveredTotal)
                      : item.status === ShipmentStatus.Confirmed
                      ? formatCurrency(item.confirmedTotal)
                      : "-"}
                  </td>
                ),
                status: (item: Shipment) => (
                  <td className="text-center">
                    {parseShipmentStatus(item.status)}
                  </td>
                ),
                orderedAt: (item: Shipment) => (
                  <td className="text-right">
                    {item.orderedAt
                      ? dateFormat(item.orderedAt, "dd/MM/yyyy HH:mm")
                      : "-"}
                  </td>
                ),
                deliveredAt: (item: Shipment) => (
                  <td className="text-right">
                    {item.status === ShipmentStatus.Delivered
                      ? dateFormat(item.deliveredAt, "dd/MM/yyyy HH:mm")
                      : item.status === ShipmentStatus.Confirmed
                      ? dateFormat(item.confirmedAt, "dd/MM/yyyy HH:mm")
                      : "-"}
                  </td>
                ),
              }}
              clickableRows
              onRowClick={(shipment) => {
                navigate(`/suppliers/${supplierId}/shipments/${shipment.id}`);
              }}
              tableProps={{
                striped: true,
                hover: true,
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default SupplierShipments;
