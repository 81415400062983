import { Link, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
} from "@coreui/react-pro";

import { logEvent } from "src/helpers/analytics";
import FeatureAlert from "src/containers/FeatureAlert";
import Api from "src/api";
import { useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { Promotion } from "src/api/promotions";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";

const Promotions = () => {
  const navigate = useNavigate();
  const { data: promotions, loading } = useQuery<GraphQLMeta<Promotion>>(
    Api.Promotions.LIST_PROMOTIONS,
    { fetchPolicy: "no-cache" }
  );

  return (
    <>
      <FeatureAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={6} className="px-0">
                  Promociones
                </CCol>
                <CCol sm={6} className="row justify-content-end">
                  <Link
                    to="/promotions/new"
                    className="flex justify-content-end p-0"
                  >
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => {
                        logEvent("promotions.create");
                      }}
                    >
                      Crear Promoción
                    </CButton>
                  </Link>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={promotions?.data?.data ?? []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "dates",
                    label: "Fecha de Validez",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "items",
                    label: "Cantidad de Items",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "initialPrice",
                    label: "Precio de Venta",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "discount",
                    label: "Descuento",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "finalPrice",
                    label: "Precio Final",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  name: (item: Promotion) => (
                    <td>
                      {item.name}{" "}
                      {item.barcode && item.barcode !== ""
                        ? `(${item.barcode})`
                        : ""}
                    </td>
                  ),
                  dates: (item: Promotion) => (
                    <td className="text-center">
                      {item.dateFrom && item.dateTo
                        ? `Desde el ${dateFormat(
                            item.dateFrom,
                            "dd/MM/yyyy"
                          )} hasta el ${dateFormat(item.dateTo, "dd/MM/yyyy")}`
                        : item.dateFrom
                        ? `A partir del ${dateFormat(
                            item.dateFrom,
                            "dd/MM/yyyy"
                          )}`
                        : "Siempre"}
                    </td>
                  ),
                  items: (item: Promotion) => (
                    <td className="text-center">{item.items.length} items</td>
                  ),
                  initialPrice: (item: Promotion) => (
                    <td className="text-right">
                      {formatCurrency(item.initialPrice)}
                    </td>
                  ),
                  discount: (item: Promotion) => (
                    <td className="text-right">{item.discount} %</td>
                  ),
                  finalPrice: (item: Promotion) => (
                    <td className="text-right">
                      {formatCurrency(item.finalPrice)}
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(i) => navigate(`/promotions/${i.id}`)}
                tableProps={{
                  striped: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Promotions;
