const SvgComponent = ({ containerClassName = "", size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 30 30"
    className={containerClassName ?? ""}
  >
    <path d="M5 27c0-5 6.957-4.174 8-6.999V19c-3.778 0-5.914-1.884-5.914-1.884 1.974-1.643-.76-13.073 5.963-13.073 0 0 .907-1.043 2.08-1.043 8.218 0 5.51 12.41 7.635 14.154 0 0-1.968 1.846-5.765 1.846v1.001C18.043 22.826 25 22 25 27H5z" />
  </svg>
);

export default SvgComponent;
