import { useMutation } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import { Column } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import { LoaderCircle, Pencil, Save } from "lucide-react";
import { useState } from "react";
import Api from "src/api";
import { CashRegister } from "src/api/registers";
import { PaymentMethod, SaleOrigin, SalePayment } from "src/api/sales";
import { formatCurrency } from "src/helpers/numbers";
import { findPaymentMethod, paymentMethods } from "src/helpers/payments";
import { useAdminStore } from "src/store";

const SalePaymentsCard = ({
  payments,
  register,
  origin,
  editable = false,
}: {
  origin: SaleOrigin;
  payments: SalePayment[];
  register: CashRegister;
  editable?: boolean;
}) => {
  const { hasPermission } = useAdminStore();
  const [editing, setEditing] = useState<boolean>(false);
  const restColumns: Column[] = [];

  const canUpdate =
    hasPermission("UPDATE_SALE") &&
    [SaleOrigin.FrontMobile, SaleOrigin.FrontWeb].includes(origin);

  const [updateMutation, { loading }] = useMutation(
    Api.Sales.UPDATE_SALE_PAYMENT,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  if (editable && canUpdate) {
    restColumns.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
    });
  }

  const filteredPaymentMethods = sortBy(
    paymentMethods.filter((p) =>
      register?.config?.paymentMethods?.includes(p.identifier)
    ),
    "name"
  );

  const formik = useFormik({
    initialValues: {
      id: 0,
      type: "",
    },
    onSubmit: (input) => {
      if (!loading) {
        const paymentToUpdate = payments.find((p) => p.id === Number(input.id));

        if (paymentToUpdate?.type === input.type) {
          formik.resetForm();
          setEditing(false);
        } else {
          updateMutation({
            variables: {
              id: input.id,
              type: input.type,
            },
          });
        }
      }
    },
  });

  return (
    <CCard>
      <CCardHeader>
        <CCol sm={12}>Pagos</CCol>
      </CCardHeader>
      <CCardBody>
        <CSmartTable
          items={payments}
          columns={[
            { key: "type", label: "Tipo" },
            {
              key: "amount",
              label: "Monto",
              _props: { className: "text-right" },
            },
            ...restColumns,
          ]}
          scopedColumns={{
            type: (item: SalePayment) => (
              <td>
                {item.type !== PaymentMethod.Discount && editing ? (
                  <CFormSelect
                    onChange={formik.handleChange}
                    name="type"
                    defaultValue={item.type}
                  >
                    {filteredPaymentMethods.map((paymentMethod) => (
                      <option key={paymentMethod.id} value={paymentMethod.type}>
                        {paymentMethod.name}
                      </option>
                    ))}
                  </CFormSelect>
                ) : (
                  findPaymentMethod(item.type)?.name
                )}
              </td>
            ),
            amount: (item: SalePayment) => (
              <td className="text-right">{formatCurrency(item.amount)}</td>
            ),
            actions: (item: SalePayment) => (
              <td className="text-right">
                {item.type !== PaymentMethod.Discount && (
                  <CTooltip
                    content={
                      editing ? "Guardar cambio" : "Editar metodo de pago"
                    }
                  >
                    <CButton
                      size="sm"
                      type="button"
                      color="primary"
                      onClick={() => {
                        if (!editing) {
                          formik.setFieldValue("id", item.id);
                          formik.setFieldValue("type", item.type);
                          setEditing(true);
                        } else {
                          formik.handleSubmit();
                        }
                      }}
                    >
                      {!editing ? (
                        <Pencil />
                      ) : loading ? (
                        <LoaderCircle />
                      ) : (
                        <Save />
                      )}
                    </CButton>
                  </CTooltip>
                )}
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default SalePaymentsCard;
