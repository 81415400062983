import { CRow, CFormInput, CButton } from "@coreui/react-pro";
import { TrashIcon } from "lucide-react";
import { CreatePromotion } from "src/api/promotions";
import { calculateProfit, formatCurrency } from "src/helpers/numbers";

export const calculateTotals = (items: CreatePromotion["items"]) => {
  const initialPrice = items.reduce(
    (prev, curr) => Number(curr.initialPrice) + Number(prev),
    0
  );

  const finalPrice = items.reduce(
    (prev, curr) => Number(curr.finalPrice) + Number(prev),
    0
  );

  return {
    initialPrice,
    discount: Math.abs(calculateProfit(initialPrice, finalPrice)),
    finalPrice,
  };
};

const TotalsRow = ({ items }) => {
  const totals = calculateTotals(items);

  return (
    <CRow>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "4px",
        }}
      >
        <CFormInput
          className="plain-input-title"
          placeholder="Totales"
          readOnly
          disabled
        />
        <CFormInput
          className="plain-input-title"
          placeholder={formatCurrency(totals.initialPrice)}
          readOnly
          disabled
        />
        <CFormInput
          className="plain-input-title"
          placeholder={`${totals.discount} %`}
          readOnly
          disabled
        />
        <CFormInput
          className="plain-input-title"
          placeholder={formatCurrency(totals.finalPrice)}
          readOnly
          disabled
        />
        <CButton
          type="button"
          size="sm"
          color="default"
          disabled
          className="plain-button"
        >
          <TrashIcon size={18} color="transparent" />
        </CButton>
      </div>
    </CRow>
  );
};

export default TotalsRow;
