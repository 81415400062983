import { Link, useLocation, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CFormInput,
  CForm,
} from "@coreui/react-pro";

import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { useQuery } from "@apollo/client";

import { useFormik } from "formik";
import { logEvent } from "src/helpers/analytics";
import { queryStringToObject } from "src/helpers/strings";
import { Warehouse } from "src/api/warehouses";
import FeatureAlert from "src/containers/FeatureAlert";
import { PaymentStatus } from "src/api/companies";
import Pagination from "src/components/Pagination";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { TextSearch } from "lucide-react";

const WarehousesScreen = () => {
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const { hasPermission, user, currentCompany } = useAdminStore();
  const {
    data: warehouses,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Warehouse>>(Api.Warehouses.LIST_WAREHOUSES, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    "warehouses",
    refetch
  );

  if (user?.isAdmin) {
    navigate("/");
  }

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search ?? "",
    },
    onSubmit: (input) => {
      logEvent(`warehouses.search`, {
        input,
      });

      resetAndSearch({
        page: 1,
        search: input.search,
      });
    },
  });

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  return (
    <>
      <FeatureAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Depósitos
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end">
                  {hasPermission("CREATE_WAREHOUSE") && (
                    <Link
                      to="/warehouses/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        color="primary"
                        size="sm"
                        onClick={() => {
                          logEvent(`warehouses.navigate-to-create`);
                        }}
                      >
                        Crear depósito
                      </CButton>
                    </Link>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar..."
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />

                    <CButton
                      color="primary"
                      type="submit"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <TextSearch />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={warehouses?.data.data ?? []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "address",
                    label: "Direccion y Teléfono",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "stores",
                    label: "Puntos de Venta Abastecidos",
                    _props: { className: "font-weight-bold" },
                  },
                ]}
                scopedColumns={{
                  name: (item: Warehouse) => <td>{item.name}</td>,
                  address: (item: Warehouse) => (
                    <td>
                      {item.streetName} {item.streetNumber}{" "}
                      {item.phone ? `(${item.phone})` : ""}
                    </td>
                  ),
                  stores: (item: Warehouse) => (
                    <td>{item.stores.map((store) => store.name).join(", ")}</td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows
                onRowClick={(item) => {
                  if (!isDebtor) {
                    logEvent(`warehouses.navigate-to-warehouse`, {
                      warehouses: {
                        id: item.id,
                      },
                    });

                    navigate(`/warehouses/${item.id}`);
                  }
                }}
              />

              <Pagination
                meta={warehouses}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default WarehousesScreen;
