import { useMutation, useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CCardFooter,
  CLoadingButton,
  CFormLabel,
  CButton,
  CTooltip,
  CBadge,
} from "@coreui/react-pro";
import { FieldArray, Form, Formik } from "formik";
import { TrashIcon } from "lucide-react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import Api from "src/api";
import { Product } from "src/api/products";
import {
  UpdatePromotion,
  Promotion,
  PromotionsSchema,
  PromotionItem,
} from "src/api/promotions";
import SmartSelect, {
  SmartSelectProps,
  SmartSelectType,
} from "src/components/SmartSelect";
import {
  calculateProfit,
  decimalNumber,
  formatCurrency,
  removePercentageFromValue,
} from "src/helpers/numbers";
import FeatureAlert from "src/containers/FeatureAlert";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import { sub } from "date-fns";
import TotalsRow, { calculateTotals } from "./components/TotalsRow";
import { getValidity } from "src/helpers/validation";
import { Store, StoreType } from "src/api/stores";
import { sortBy } from "lodash";
import { dateFormat } from "src/helpers/dates";
import { useProductError } from "src/hooks/useProductError";
import {
  calculateFixedPriceDiscounts,
  usefulPromos,
} from "src/helpers/promotions";

const EditPromotion = () => {
  const { currentCompany, user } = useAdminStore();

  const navigate = useNavigate();
  const params = useParams();
  const promotionId = Number(params.id);

  if (!promotionId) {
    navigate("/promotions");
  }

  const { data: promotion } = useQuery<GraphQLFind<Promotion>>(
    Api.Promotions.GET_PROMOTION,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: promotionId,
      },
    }
  );

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: { filters: { limit: 0, type: StoreType.Store } },
    }
  );

  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          limit: 0,
          trashed: 0,
        },
      },
    }
  );

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${
        product.barcodes.length > 0
          ? product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      }`,
      value: product.id,
    }));
  }, [productsList]);

  const storesList = useMemo(() => {
    if (!stores?.data.data) {
      return [];
    }

    return [
      { value: 0, name: "Todos" },
      ...sortBy(stores.data.data, "name").map((store) => ({
        name: user?.isAdmin
          ? `${store.name} (${store.company.name})`
          : store.name,
        value: store.id,
        type: store.type,
      })),
    ];
  }, [stores?.data.data, user?.isAdmin]);

  if (!promotion) {
    return <AppLoader />;
  }

  return (
    <>
      <FeatureAlert />

      <PromotionForm
        products={products}
        productsList={productsList?.data?.data ?? []}
        promotion={promotion.data}
        stores={storesList}
      />
    </>
  );
};

const PromotionForm = ({
  products,
  productsList,
  promotion,
  stores,
}: {
  products: SmartSelectProps["options"];
  productsList: Product[];
  promotion: Promotion;
  stores: SmartSelectProps["options"];
}) => {
  const { handlePromoErrorMessage } = useProductError();
  const [updateMutation, { loading: updating }] = useMutation(
    Api.Promotions.UPDATE_PROMOTION,
    {
      onCompleted: () => {
        window.location.reload();
      },
      onError: (error) => {
        handlePromoErrorMessage(error);
      },
    }
  );

  return (
    <Formik<UpdatePromotion>
      initialValues={{
        storeId: promotion.storeId ?? 0,
        name: promotion.name,
        barcode: promotion.barcode,
        dateFrom: promotion.dateFrom,
        dateTo: promotion.dateTo ? sub(promotion.dateTo, { hours: 3 }) : null,
        initialPrice: promotion.initialPrice,
        discount: promotion.discount,
        finalPrice: promotion.finalPrice,
        items: promotion.items.map((item) => ({
          productId: item.productId,
          productName: item.product.name,
          initialPrice: item.initialPrice,
          discount: item.discount,
          finalPrice: item.finalPrice,
        })),
      }}
      validationSchema={PromotionsSchema}
      onSubmit={(values) => {
        if (!updating) {
          const input: UpdatePromotion = {
            storeId: Number(values.storeId) !== 0 ? values.storeId : null,
            name: values.name,
            barcode: values.barcode,
            dateFrom: values.dateFrom,
            items: [],
            ...calculateTotals(values.items),
          };

          if (values.dateTo) {
            input.dateTo = values.dateTo;
          }

          input.items.push(
            ...values.items.map((item) => ({
              productId: item.productId,
              productName: item.productName,
              initialPrice: Number(item.initialPrice),
              discount: Number(item.discount),
              finalPrice: Number(item.finalPrice),
            }))
          );

          updateMutation({
            variables: { id: promotion.id, input },
          });
        }
      }}
    >
      {({
        handleChange,
        errors,
        handleSubmit,
        setFieldValue,
        isValid,
        values,
      }) => {
        const applyPromo = (
          config: (() => string | null) | Pick<PromotionItem, "discount">[]
        ) => {
          if (typeof config === "function") {
            const value = config();

            const items = calculateFixedPriceDiscounts(
              values.items,
              Number(value)
            );

            setFieldValue("items", items);
          } else {
            setFieldValue(
              "items",
              values.items.map((item, index) => ({
                ...item,
                discount: config[index].discount,
                finalPrice: removePercentageFromValue(
                  item.initialPrice,
                  config[index].discount
                ),
              }))
            );
          }
        };

        return (
          <Form>
            <CRow>
              <CCol xl={12}>
                <CCard>
                  <CCardHeader>
                    <CRow className="align-items-center justify-content-center">
                      <CCol sm={12}>Promoción: "{promotion.name}"</CCol>
                    </CRow>
                  </CCardHeader>
                  <CCardBody>
                    <CForm>
                      <CRow>
                        <CCol sm={4}>
                          <CFormInput
                            onChange={handleChange}
                            name="name"
                            floatingLabel="Nombre"
                            defaultValue={values.name}
                            {...getValidity(values.name, errors.name)}
                          />
                        </CCol>
                        <CCol sm={2}>
                          <CFormInput
                            onChange={handleChange}
                            name="dateFrom"
                            floatingLabel="Fecha Inicio"
                            defaultValue={
                              values.dateFrom
                                ? dateFormat(values.dateFrom, "yyyy-MM-dd", {
                                    remove: { hours: 3 },
                                  })
                                : ""
                            }
                            type="date"
                          />
                        </CCol>
                        <CCol sm={2}>
                          <CFormInput
                            onChange={handleChange}
                            name="dateTo"
                            floatingLabel="Fecha Fin"
                            defaultValue={
                              values.dateTo
                                ? dateFormat(values.dateTo, "yyyy-MM-dd", {
                                    remove: { hours: 3 },
                                  })
                                : ""
                            }
                            type="date"
                          />
                        </CCol>
                        <CCol sm={4}>
                          <CFormInput
                            onChange={handleChange}
                            name="barcode"
                            floatingLabel="Código de Barra"
                            defaultValue={values.barcode}
                          />
                        </CCol>
                      </CRow>

                      <CRow className="mt-3">
                        <CCol sm={3}>
                          <CFormLabel>Punto de Venta</CFormLabel>
                          <SmartSelect
                            type={SmartSelectType.Stores}
                            options={stores}
                            name="storeId"
                            placeholder="Selecciona Punto de Venta"
                            onChange={(e) => {
                              setFieldValue("storeId", Number(e));
                            }}
                            value={values.storeId?.toString()}
                          />
                        </CCol>
                      </CRow>
                      <CRow className="my-3">
                        <CCol className="product-search" sm={5}>
                          <CFormLabel>Agregar Producto</CFormLabel>
                          <SmartSelect
                            name="productId"
                            search
                            placeholder="Selecciona un producto"
                            emptyLabel="No tienes productos stockeables"
                            options={products}
                            value={"0"}
                            onChange={(e) => {
                              const product = productsList.find(
                                (p) => p.id === e
                              );

                              if (product) {
                                setFieldValue("items", [
                                  ...values.items,
                                  {
                                    productId: product.id,
                                    productName: product.name,
                                    initialPrice: product.price,
                                    discount: 0,
                                    finalPrice: product.price,
                                  },
                                ]);
                              }
                            }}
                          />
                        </CCol>
                        <CCol sm={5}>
                          <CFormLabel>Promociones comunes</CFormLabel>

                          <div className="d-flex gap-2 mt-2">
                            {usefulPromos.map((promo, index) => {
                              const disabled =
                                values.items.length === 0 ||
                                (promo.items &&
                                  values.items.length !== promo.items);

                              return (
                                <CBadge
                                  onClick={() => {
                                    if (!disabled) {
                                      applyPromo(promo.config);
                                    }
                                  }}
                                  key={index}
                                  color={disabled ? "secondary" : "primary"}
                                >
                                  {promo.label}
                                </CBadge>
                              );
                            })}
                          </div>
                        </CCol>
                      </CRow>

                      {values.items.length > 0 && (
                        <>
                          <CRow>
                            <CCol>
                              <CFormLabel>Productos de la Promo</CFormLabel>
                            </CCol>
                          </CRow>

                          <CRow>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                marginBottom: "4px",
                              }}
                            >
                              <CFormInput
                                className="plain-input-title"
                                placeholder="Producto"
                                readOnly
                                disabled
                              />
                              <CFormInput
                                className="plain-input-title"
                                placeholder="Precio de Venta"
                                readOnly
                                disabled
                              />
                              <CFormInput
                                className="plain-input-title"
                                placeholder="Descuento (%)"
                                readOnly
                                disabled
                              />
                              <CFormInput
                                className="plain-input-title"
                                placeholder="Precio Final"
                                readOnly
                                disabled
                              />
                              <CButton
                                type="button"
                                size="sm"
                                color="default"
                                disabled
                                className="plain-button"
                              >
                                <TrashIcon size={18} color="transparent" />
                              </CButton>
                            </div>
                          </CRow>

                          <CRow>
                            <FieldArray name="items">
                              {({ remove }) => (
                                <div>
                                  {values.items.length > 0 &&
                                    values.items.map((item, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <CFormInput
                                          name={`items[${index}].productId`}
                                          placeholder="Producto"
                                          readOnly
                                          disabled
                                          className="plain-input pl-0 mr-2"
                                          value={item.productName}
                                          required
                                        />
                                        <CFormInput
                                          name={`items[${index}].initialPrice`}
                                          readOnly
                                          disabled
                                          className="plain-input pl-0 mr-2"
                                          value={formatCurrency(
                                            item.initialPrice
                                          )}
                                          placeholder="Precio de Venta"
                                        />
                                        <CFormInput
                                          name={`items[${index}].discount`}
                                          type="number"
                                          placeholder="Descuento (%)"
                                          value={item.discount}
                                          onChange={(e) => {
                                            const val = decimalNumber(
                                              e.target.value,
                                              100
                                            );

                                            if (val === null) {
                                              setFieldValue(
                                                `items[${index}].finalPrice`,
                                                item.initialPrice
                                              );
                                              return;
                                            }

                                            setFieldValue(
                                              `items[${index}].discount`,
                                              val
                                            );
                                            setFieldValue(
                                              `items[${index}].finalPrice`,
                                              removePercentageFromValue(
                                                item.initialPrice,
                                                Number(val)
                                              )
                                            );
                                          }}
                                          min="0"
                                          max="100"
                                        />
                                        <CFormInput
                                          name={`items[${index}].finalPrice`}
                                          type="number"
                                          placeholder="Precio Final"
                                          min="0"
                                          value={item.finalPrice}
                                          onChange={(e) => {
                                            const val = decimalNumber(
                                              e.target.value,
                                              item.initialPrice
                                            );

                                            if (val === null) {
                                              setFieldValue(
                                                `items[${index}].discount`,
                                                0
                                              );
                                              return;
                                            }

                                            setFieldValue(
                                              `items[${index}].finalPrice`,
                                              val
                                            );
                                            setFieldValue(
                                              `items[${index}].discount`,
                                              Math.abs(
                                                calculateProfit(
                                                  item.initialPrice,
                                                  Number(val)
                                                )
                                              )
                                            );
                                          }}
                                        />

                                        <CTooltip content="Eliminar item">
                                          <CButton
                                            type="button"
                                            size="sm"
                                            color="danger"
                                            onClick={() => remove(index)}
                                            className="d-flex align-items-center"
                                          >
                                            <TrashIcon size={18} />
                                          </CButton>
                                        </CTooltip>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </FieldArray>
                          </CRow>

                          <TotalsRow items={values.items} />
                        </>
                      )}
                    </CForm>
                  </CCardBody>
                  <CCardFooter>
                    <CRow>
                      <CCol sm={12} className="text-right">
                        <CLoadingButton
                          type="button"
                          size="sm"
                          loading={updating}
                          disabled={updating || !isValid}
                          onClick={handleSubmit}
                          className="btn btn-primary"
                        >
                          Guardar
                        </CLoadingButton>
                      </CCol>
                    </CRow>
                  </CCardFooter>
                </CCard>
              </CCol>
            </CRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPromotion;
