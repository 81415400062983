import { Link, useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { PermissionGroup } from "src/api/permissions";
import Pagination from "src/components/Pagination";

const Groups = () => {
  const navigate = useNavigate();
  const { page, pageChange } = usePagination("groups");
  const { data: groups, loading } = useQuery<GraphQLMeta<PermissionGroup>>(
    Api.Permissions.LIST_GROUPS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={6} xs="6" className="px-0">
                Grupos de Permisos
              </CCol>
              <CCol sm={6} xs="6" className="row justify-content-end">
                <Link to="/groups/new" className="flex justify-content-end p-0">
                  <CButton color="primary" size="sm">
                    Crear Grupo
                  </CButton>
                </Link>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CSmartTable
              itemsPerPage={20}
              loading={loading}
              items={groups?.data.data || []}
              columns={[
                { key: "id", label: "ID" },
                {
                  key: "name",
                  label: "Nombre",
                  _props: { className: "font-weight-bold" },
                },
                { key: "permissions", label: "Cantidad de Permisos" },
                { key: "company", label: "Empresa" },
              ]}
              scopedColumns={{
                name: (item: PermissionGroup) => <td>{item.name}</td>,
                permissions: (item: PermissionGroup) => (
                  <td>{item.permissionsCount} permisos</td>
                ),
                company: (item: PermissionGroup) => (
                  <td>{item.company?.name ?? "-"}</td>
                ),
              }}
              tableProps={{
                striped: true,
                hover: true,
              }}
              clickableRows
              onRowClick={(item) => navigate(`/groups/${item.id}`)}
            />

            <Pagination meta={groups} page={page} pageChange={pageChange} />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Groups;
