import { useMemo } from "react";
import { CFormCheck, CSmartTable } from "@coreui/react-pro";
import { Permission, PermissionMap } from "src/api/permissions";
import { GraphQLMeta } from "src/types";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { mapPermissions } from "src/helpers/permissions";

const StorePermissions = ({ formik }) => {
  const { data: permissions } = useQuery<GraphQLMeta<Permission>>(
    Api.Permissions.LIST_PERMISSIONS,
    {
      variables: {
        filters: {
          limit: 0,
          type: "Store",
        },
      },
    }
  );

  const mappedPermissions = useMemo(
    () => mapPermissions(permissions?.data.data ?? []),
    [permissions]
  );

  const onPermissionChange = (e: any) => {
    const userPermissions = formik.values.permissions;
    const { checked, name: permissionKey } = e.target;

    if (checked) {
      formik.setFieldValue("permissions", [...userPermissions, permissionKey]);
    } else {
      formik.setFieldValue(
        "permissions",
        userPermissions.filter((p) => p !== permissionKey)
      );
    }
  };

  return (
    <CSmartTable
      itemsPerPage={mappedPermissions.length}
      items={mappedPermissions}
      columns={[
        {
          key: "entity",
          label: "Entidad",
        },
        {
          key: "create",
          label: "Crear",
          _props: {
            className: "text-center",
          },
        },
        {
          key: "update",
          label: "Editar",
          _props: {
            className: "text-center",
          },
        },
        {
          key: "delete",
          label: "Eliminar",
          _props: {
            className: "text-center",
          },
        },
        {
          key: "list",
          label: "Listar",
          _props: {
            className: "text-center",
          },
        },
        {
          key: "show",
          label: "Mostrar",
          _props: {
            className: "text-center",
          },
        },
      ]}
      scopedColumns={{
        entity: (item: PermissionMap) => (
          <td className="font-weight-bold">
            <span className="font-weight-bold">{item.label}</span>
          </td>
        ),
        create: (item: PermissionMap) => {
          if (!item.CREATE) {
            return <td />;
          }

          const permKey = `s_${item.CREATE.id}`;

          return (
            <td className="text-center">
              <CFormCheck
                name={permKey}
                checked={formik.values.permissions.includes(permKey)}
                onChange={onPermissionChange}
              />
            </td>
          );
        },
        update: (item: PermissionMap) => {
          if (!item.UPDATE) {
            return <td />;
          }

          const permKey = `s_${item.UPDATE.id}`;

          return (
            <td className="text-center">
              <CFormCheck
                name={permKey}
                checked={formik.values.permissions.includes(permKey)}
                onChange={onPermissionChange}
              />
            </td>
          );
        },
        delete: (item: PermissionMap) => {
          if (!item.DELETE) {
            return <td />;
          }

          const permKey = `s_${item.DELETE.id}`;

          return (
            <td className="text-center">
              <CFormCheck
                name={permKey}
                checked={formik.values.permissions.includes(permKey)}
                onChange={onPermissionChange}
              />
            </td>
          );
        },
        list: (item: PermissionMap) => {
          if (!item.LIST) {
            return <td />;
          }

          const permKey = `s_${item.LIST.id}`;

          return (
            <td className="text-center">
              <CFormCheck
                name={permKey}
                checked={formik.values.permissions.includes(permKey)}
                onChange={onPermissionChange}
              />
            </td>
          );
        },
        show: (item: PermissionMap) => {
          if (!item.SHOW) {
            return <td />;
          }

          const permKey = `s_${item.SHOW.id}`;

          return (
            <td className="text-center">
              <CFormCheck
                name={permKey}
                checked={formik.values.permissions.includes(permKey)}
                onChange={onPermissionChange}
              />
            </td>
          );
        },
      }}
    />
  );
};

export default StorePermissions;
