import { Link } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
} from "@coreui/react-pro";

import { logEvent } from "src/helpers/analytics";
import FeatureAlert from "src/containers/FeatureAlert";
import Api from "src/api";
import { useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { BankPromotion } from "src/api/bankPromotions";

const BankPromotions = () => {
  const { data: bankPromotions, loading } = useQuery<
    GraphQLMeta<BankPromotion>
  >(Api.BankPromotions.LIST_BANK_PROMOTIONS);

  return (
    <>
      <FeatureAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={6} className="px-0">
                  Promociones Bancarias
                </CCol>
                <CCol sm={6} className="row justify-content-end">
                  <Link
                    to="/banks/new"
                    className="flex justify-content-end p-0"
                  >
                    <CButton
                      size="sm"
                      color="primary"
                      onClick={() => {
                        logEvent("banks.create");
                      }}
                    >
                      Crear Promoción Bancaria
                    </CButton>
                  </Link>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={bankPromotions?.data?.data ?? []}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  {
                    key: "monday",
                    label: "L",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "tuesday",
                    label: "M",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "wednesday",
                    label: "M",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "thursday",
                    label: "J",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "friday",
                    label: "V",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "saturday",
                    label: "S",
                    _props: { className: "text-center" },
                  },
                  {
                    key: "sunday",
                    label: "D",
                    _props: { className: "text-center" },
                  },
                ]}
                scopedColumns={{
                  monday: (item: BankPromotion) => (
                    <td className="text-center">{item.monday ? "✅" : "❌"}</td>
                  ),
                  tuesday: (item: BankPromotion) => (
                    <td className="text-center">
                      {item.tuesday ? "✅" : "❌"}
                    </td>
                  ),
                  wednesday: (item: BankPromotion) => (
                    <td className="text-center">
                      {item.wednesday ? "✅" : "❌"}
                    </td>
                  ),
                  thursday: (item: BankPromotion) => (
                    <td className="text-center">
                      {item.thursday ? "✅" : "❌"}
                    </td>
                  ),
                  friday: (item: BankPromotion) => (
                    <td className="text-center">{item.friday ? "✅" : "❌"}</td>
                  ),
                  saturday: (item: BankPromotion) => (
                    <td className="text-center">
                      {item.saturday ? "✅" : "❌"}
                    </td>
                  ),
                  sunday: (item: BankPromotion) => (
                    <td className="text-center">{item.sunday ? "✅" : "❌"}</td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default BankPromotions;
