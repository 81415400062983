import { gql } from "@apollo/client";

export const SELLS_BY_CATEGORY_AND_DATE = gql`
  query sellsByCategoriesAndDate($filters: DateAndStoreFilters!) {
    data: sellsByCategoriesAndDate(filters: $filters) {
      entity: category {
        id
        name
      }
      items {
        product {
          id
          name

          category {
            name
          }

          suppliers {
            supplier {
              name
            }
          }
        }
        quantity
        cost
        price
      }
    }
  }
`;

export const SELLS_BY_SUPPLIER_AND_DATE = gql`
  query sellsBySuppliersAndDate($filters: DateAndStoreFilters!) {
    data: sellsBySuppliersAndDate(filters: $filters) {
      entity: supplier {
        id
        name
      }
      items {
        product {
          id
          name

          category {
            name
          }

          suppliers {
            supplier {
              name
            }
          }
        }
        quantity
        cost
        price
      }
    }
  }
`;

export const SELLS_BETWEEN_DATES = gql`
  query sellsBetweenDates($filters: DateAndStoreFilters!) {
    data: sellsBetweenDates(filters: $filters) {
      category {
        id
        name
      }
      items {
        product {
          id
          name

          suppliers {
            supplier {
              name
            }
          }
        }
        quantity
        cost
        price
      }
    }
  }
`;
