import { useMutation } from "@apollo/client";
import {
  CButton,
  CCol,
  CFormLabel,
  CFormSwitch,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import Api from "src/api";
import { StockType } from "src/api/stock";
import { useAdminStore } from "src/store";

export type ProductsConfigModalForwardedRef = {
  open: (
    id: number,
    name: string,
    type: "category" | "supplier" | "company"
  ) => void;
  close: () => void;
};

const ProductsConfigModal: ForwardRefRenderFunction<
  ProductsConfigModalForwardedRef,
  unknown
> = (_, ref) => {
  const { hasPermission, currentCompany } = useAdminStore();
  const canEditProduct = hasPermission("UPDATE_PRODUCT");
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState<
    "category" | "supplier" | "company" | undefined
  >("category");
  const [entityId, setEntityId] = useState<number>();
  const [entityName, setEntityName] = useState<string>();

  const [updateByCategory, { loading: categoryLoading }] = useMutation(
    Api.Categories.UPDATE_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [updateBySupplier, { loading: supplierLoading }] = useMutation(
    Api.Suppliers.UPDATE_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [updateByCompany, { loading: companyLoading }] = useMutation(
    Api.Companies.UPDATE_PRODUCTS,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      entityId: 0,
      stockeable: false,
      multipleBarcodes: false,
      productExpires: false,
      isGranel: false,
      isFractionable: false,
    },
    onSubmit: (data) => {
      if (!categoryLoading && !supplierLoading && !companyLoading) {
        const config = {
          stockeable: data.stockeable,
          multipleBarcodes: data.multipleBarcodes,
          productExpires: data.productExpires,
          isGranel: data.isGranel,
          isFractionable: data.isFractionable,
        };

        if (type === "category") {
          updateByCategory({
            variables: {
              categoryId: entityId,
              config,
            },
          });
        } else if (type === "supplier") {
          updateBySupplier({
            variables: {
              supplierId: entityId,
              config,
            },
          });
        } else if (type === "company") {
          updateByCompany({
            variables: {
              companyId: entityId,
              config,
            },
          });
        }
      }
    },
  });

  const handleClose = useCallback(() => {
    if (!categoryLoading && !supplierLoading && !companyLoading) {
      setEntityId(undefined);
      setEntityName(undefined);
      setShowModal(false);
      setType(undefined);
      formik.resetForm();
    }
  }, [categoryLoading, companyLoading, formik, supplierLoading]);

  useImperativeHandle(
    ref,
    () => ({
      open: (
        id: number,
        name: string,
        type: "category" | "supplier" | "company"
      ) => {
        setEntityId(id);
        setEntityName(name);
        setShowModal(true);
        setType(type);
      },
      close: () => {
        handleClose();
      },
    }),
    [handleClose]
  );

  return (
    <CModal
      alignment="center"
      backdrop="static"
      visible={showModal}
      onClose={() => {
        if (!categoryLoading && supplierLoading && !companyLoading) {
          setShowModal(false);
        }
      }}
    >
      <CModalHeader closeButton={false}>
        Actualizar configuraciones de producto
      </CModalHeader>
      <CModalBody>
        <CRow className="mb-3 text-center">
          <h4>
            {type === "category" && `Categoría: ${entityName}`}
            {type === "supplier" && `Proveedor: ${entityName}`}
            {type === "company" && `Empresa: ${entityName}`}
          </h4>
        </CRow>
        <CRow className="mt-3">
          <CCol md="9" className="table-center">
            <CFormLabel htmlFor="multipleBarcodes">
              Multiple Códigos de Barra
            </CFormLabel>
          </CCol>
          <CCol md="3" className="d-flex justify-content-end">
            <CFormSwitch
              id="multipleBarcodes"
              name="multipleBarcodes"
              disabled={!canEditProduct}
              defaultChecked={formik.values.multipleBarcodes}
              onChange={formik.handleChange}
            />
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md="9" className="table-center">
            <CFormLabel htmlFor="productExpires">
              ¿Productos con vencimiento?
            </CFormLabel>
          </CCol>
          <CCol md="3" className="d-flex justify-content-end">
            <CFormSwitch
              id="productExpires"
              name="productExpires"
              disabled={!canEditProduct}
              defaultChecked={formik.values.productExpires}
              onChange={formik.handleChange}
            />
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md="9" className="table-center">
            <CFormLabel htmlFor="isGranel">
              ¿Productos vendidos a granel?
            </CFormLabel>
          </CCol>
          <CCol md="3" className="d-flex justify-content-end">
            <CFormSwitch
              id="isGranel"
              name="isGranel"
              disabled={!canEditProduct}
              defaultChecked={formik.values.isGranel}
              onChange={formik.handleChange}
            />
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md="9" className="table-center">
            <CFormLabel htmlFor="isFractionable">
              ¿Productos fraccionables?
            </CFormLabel>
          </CCol>
          <CCol md="3" className="d-flex justify-content-end">
            <CFormSwitch
              id="isFractionable"
              name="isFractionable"
              disabled={!canEditProduct}
              defaultChecked={formik.values.isFractionable}
              onChange={formik.handleChange}
            />
          </CCol>
        </CRow>

        {currentCompany?.config.stock === StockType.Mixed && (
          <CRow className="mt-3">
            <CCol md="9" className="table-center">
              <CFormLabel htmlFor="stockeable">¿Maneja Stock?</CFormLabel>
            </CCol>
            <CCol md="3" className="d-flex justify-content-end">
              <CFormSwitch
                id="stockeable"
                name="stockeable"
                disabled={!canEditProduct}
                defaultChecked={formik.values.stockeable}
                onChange={formik.handleChange}
              />
            </CCol>
          </CRow>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          disabled={categoryLoading || supplierLoading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancelar
        </CButton>

        <CLoadingButton
          loading={categoryLoading || supplierLoading}
          disabled={categoryLoading || supplierLoading}
          size="sm"
          onClick={() => formik.handleSubmit()}
          color="success"
        >
          Continuar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ProductsConfigModal);
