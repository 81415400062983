import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CCol,
  CButton,
  CForm,
  CFormInput,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";

import { useMutation } from "@apollo/client";
import {
  PriceListUpdate,
  PriceListItem,
  PriceList,
  importPriceList,
} from "src/api/price-lists";
import { OnChangeEvent } from "src/types";
import { formatCurrency } from "src/helpers/numbers";
import AddItemModal from "./AddItemModal";
import { useFormik } from "formik";
import Api from "src/api";
import writeXlsxFile from "write-excel-file";
import { useAdminStore } from "src/store";
import SuccessModal from "./SuccessModal";
import AlertDialog, {
  AlertDialogForwardedRef,
} from "src/components/AlertDialog";
import { FileDown, FileUp, Plus, Trash } from "lucide-react";

const PriceListItems: ForwardRefRenderFunction<
  any,
  { priceListId: number; refetch: () => void; priceList: PriceList }
> = ({ priceListId, refetch, priceList }, ref) => {
  const { hasPermission } = useAdminStore();
  const alertDialogRef = useRef<AlertDialogForwardedRef>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const addItemModalRef = useRef<any>();
  const successModalRef = useRef<any>();
  const canEditPriceList = hasPermission("UPDATE_PRICE_LIST");

  const [priceListLoading, setPriceListLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const [updateMutation, { loading: updLoading }] = useMutation(
    Api.PriceLists.UPDATE_PRICE_LIST,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const [deleteItemMutation] = useMutation(
    Api.PriceLists.DELETE_PRICE_LIST_ITEM,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const downloadFile = async () => {
    if (!priceList?.items) {
      return;
    }

    const excelData: { value: string | number }[][] = [
      [
        {
          value: "ID",
        },
        {
          value: "ID Producto",
        },
        {
          value: "Nombre",
        },
        {
          value: "Descripción",
        },
        {
          value: "IVA",
        },
        {
          value: "Precio",
        },
      ],
    ];

    priceList.items.forEach((priceList) => {
      excelData.push([
        {
          value: priceList.id,
        },
        {
          value: priceList.productId,
        },
        {
          value: priceList.name,
        },
        {
          value: priceList.description ?? "",
        },
        {
          value: priceList.tax ?? 0,
        },
        {
          value: priceList.price ?? 0,
        },
      ]);
    });

    const fileName = `Lista de precios "${priceList.name}"`;

    writeXlsxFile(excelData, {
      fileName: `${fileName}.xlsx`,
    });
  };

  const importFile = async (e) => {
    if (e.target.value !== "" && fileInputRef.current?.files?.[0]) {
      setPriceListLoading(true);

      const formData = new FormData();

      formData.append("file", fileInputRef.current.files[0]);
      formData.append("priceListId", priceListId.toString());

      try {
        const response = await importPriceList(formData);

        if (response) {
          const createProductIds = response?.changes?.create ?? [];
          const updateProductIds = [
            ...(response?.changes?.update ?? []),
            ...(response?.changes?.delete ?? []),
          ];

          successModalRef.current.open(createProductIds, updateProductIds);
        } else {
          alertDialogRef.current?.show(
            "Importar Lista de Precios",
            "El archivo que has subido no tiene ninguna diferencia con tu lista de precios actual."
          );
        }
      } catch (e: any) {
        if (e.message === "invalid_file") {
          alert(
            "El archivo es inválido, por favor revisa que sea el correcto."
          );
        } else {
          alert("No se ha podido cargar la lista de precios.");
        }
      } finally {
        fileInputRef.current.value = "";
        setPriceListLoading(false);
      }
    }
  };

  const onDeleteItem = (itemId: number) => {
    deleteItemMutation({
      variables: {
        id: itemId,
      },
    });
  };

  const openFilePrompt = () => fileInputRef.current?.click();

  const onValueChange = (e: any) => setQuery(e.currentTarget.value);

  const filteredPriceList = useMemo(() => {
    setIsLoading(true);

    let result = priceList?.items ?? [];

    if (query !== "") {
      result = result.filter((product) => {
        return (
          product.name?.toLowerCase().includes(query?.toLowerCase()) ||
          product.description?.toLowerCase().includes(query?.toLowerCase())
          // || product.barcode?.toLowerCase().includes(query?.toLowerCase())
        );
      });
    }

    return result.slice(0, 15);
  }, [priceList?.items, query]);

  useEffect(() => {
    setIsLoading(false);
  }, [filteredPriceList]);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => buttonRef.current?.click(),
    }),
    []
  );

  const formik = useFormik<PriceListUpdate>({
    initialValues: {
      name: priceList.name,
      dateFrom: priceList.dateFrom,
      dateTo: priceList.dateTo,
      items: priceList.items,
      profit: priceList.profit,
    },
    onSubmit: (body) => {
      if (!updLoading) {
        const data: PriceListUpdate = {
          name: body.name,
          dateFrom: new Date(body.dateFrom),
          dateTo: body.dateTo ? new Date(body.dateTo) : null,
          profit: body.profit,
          items: [] as PriceListItem[],
        };

        const items = body.items.filter((item) => item && item.dirty);

        items.forEach((value) => {
          const item: PriceListItem = {} as PriceListItem;

          if (typeof value.name !== "undefined") {
            item.name = value.name;
          }

          if (typeof value.price !== "undefined") {
            item.price = Number(value.price);
          }

          if (item.name || item.price) {
            item.id = value.id;

            data.items.push(item);
          }
        });

        updateMutation({
          variables: {
            id: priceListId,
            input: data,
          },
        });
      }
    },
  });

  const handleChange = (e: OnChangeEvent, key: string, itemId: number) => {
    const { value } = e.currentTarget;
    const index = formik.initialValues.items.findIndex((i) => i.id === itemId);
    const isDirty = formik.initialValues.items[index][key] !== value;

    formik.setFieldValue(`items[${index}][${key}]`, value);
    formik.setFieldValue(`items[${index}][dirty]`, isDirty);
  };

  return (
    <>
      <CForm onSubmit={formik.handleSubmit}>
        <CRow className="align-items-center justify-content-center mb-4">
          <CCol
            sm={12}
            className="flex justify-content-end"
            style={{ flexWrap: "nowrap" }}
          >
            <CFormInput placeholder="Buscar..." onChange={onValueChange} />
            {canEditPriceList && (
              <CButton
                type="button"
                color="info"
                className="ml-2"
                disabled={priceListLoading}
                onClick={openFilePrompt}
              >
                <FileUp />
              </CButton>
            )}
            <CButton
              type="button"
              color="success"
              disabled={priceListLoading}
              className="ml-2"
              onClick={downloadFile}
            >
              <FileDown />
            </CButton>
            {canEditPriceList && (
              <CButton
                color="primary"
                type="button"
                className="ml-2"
                onClick={() => addItemModalRef.current?.open()}
              >
                <Plus />
              </CButton>
            )}
          </CCol>
        </CRow>

        {isLoading ? (
          <span>Cargando</span>
        ) : (
          <CSmartTable
            items={filteredPriceList}
            columns={[
              {
                key: "name",
                label: "Nombre",
              },

              {
                key: "price",
                label: "Precio",
                _props: { className: "text-right" },
              },
              {
                key: "newPrice",
                label: "Nuevo Precio",
                _props: { className: "text-right" },
              },
              {
                key: "actions",
                label: "Acciones",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              name: (item: PriceListItem) => (
                <td align="right">
                  <span
                    className="font-weight-bold"
                    style={{
                      minHeight: 34,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CFormInput
                      readOnly={!canEditPriceList}
                      id={`items[${item.id}][name]`}
                      defaultValue={item.name}
                      name={`items[${item.id}][name]`}
                      onChange={(e) => handleChange(e, "name", item.id)}
                    />
                  </span>
                </td>
              ),
              price: (item: PriceListItem) => (
                <td align="right">
                  <span
                    style={{
                      minHeight: 34,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {formatCurrency(item.price)}
                  </span>
                </td>
              ),
              newPrice: (item: PriceListItem) => (
                <td align="right" width="120">
                  <CFormInput
                    readOnly={!canEditPriceList}
                    id={`items[${item.id}][price]`}
                    defaultValue={item.price}
                    className="text-right"
                    name={`items[${item.id}][price]`}
                    onChange={(e) => handleChange(e, "price", item.id)}
                  />
                </td>
              ),
              actions: (item: PriceListItem) => (
                <td align="right" width="70">
                  <CButton
                    disabled={!canEditPriceList}
                    onClick={() => onDeleteItem(item.id)}
                    type="button"
                    color="danger"
                  >
                    <Trash />
                  </CButton>
                </td>
              ),
            }}
            tableProps={{
              striped: true,
            }}
          />
        )}
        <CButton
          type="submit"
          color="primary"
          ref={buttonRef}
          style={{ display: "none" }}
        />
      </CForm>
      <CForm>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={importFile}
        />
      </CForm>

      <AddItemModal ref={addItemModalRef} priceListId={priceListId} />
      <SuccessModal ref={successModalRef} priceListId={priceListId} />
      <AlertDialog ref={alertDialogRef} />
    </>
  );
};
export default forwardRef(PriceListItems);
