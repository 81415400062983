import { useLazyQuery, useQuery } from "@apollo/client";
import { CCol, CFormLabel, CLoadingButton, CRow } from "@coreui/react-pro";
import { sortBy } from "lodash";
import { useState } from "react";
import Api from "src/api";
import { BatchItem } from "src/api/batches";
import { Warehouse } from "src/api/warehouses";
import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { findBatchType } from "src/helpers/batches";
import { dateFormat, getLastSixMonthsWeeks, WeekLine } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import writeXlsxFile, { SheetData } from "write-excel-file";

const weeks = getLastSixMonthsWeeks();

const BatchesPerWeek = () => {
  const { user } = useAdminStore();
  const [warehouseId, setwarehouseId] = useState<number>(0);
  const [weekNumber, setWeekNumber] = useState<number>(weeks[0].number);

  const { data: warehouses } = useQuery<GraphQLMeta<Warehouse>>(
    Api.Warehouses.LIST_WAREHOUSES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const [fetchBatches, { loading: fetching }] = useLazyQuery<
    GraphQLFind<BatchItem[]>
  >(Api.Reports.BATCHES_PER_WEEK, {
    onCompleted: ({ data }) => {
      const week = weeks.find((week) => week.number === weekNumber);
      const warehouse = warehouses?.data.data.find((w) => w.id === warehouseId);

      if (week && warehouse) {
        writeExcel(week, warehouse, data);
      }
    },
  });

  if (!warehouses?.data?.data) {
    return <AppLoader />;
  }

  return (
    <>
      <CRow>
        <CCol md="4">
          <CFormLabel>
            <strong>Depósito</strong>
          </CFormLabel>
          <SmartSelect
            type={SmartSelectType.Warehouses}
            name="warehouseId"
            search
            options={[
              ...(sortBy(warehouses?.data.data ?? [], "name").map(
                (warehouse) => ({
                  name: user?.isAdmin
                    ? `${warehouse.name} (${warehouse.company.name})`
                    : warehouse.name,
                  value: warehouse.id,
                  types: warehouse.stores.map((s) => s.type),
                })
              ) ?? []),
            ]}
            resultsCount={10}
            placeholder="Selecciona Depósito"
            onChange={(e) => {
              logEvent("reports.batches-per-week.set-warehouse", {
                warehouseId: e,
              });

              setwarehouseId(Number(e));
            }}
            value={warehouseId.toString()}
          />
        </CCol>
        <CCol md="4">
          <CFormLabel>
            <strong>Semana</strong>
          </CFormLabel>
          <SmartSelect
            name="weekNumber"
            value={weekNumber.toString()}
            onChange={(value) => {
              setWeekNumber(Number(value));
            }}
            options={weeks.map((week) => ({
              value: week.number,
              name: week.label,
            }))}
          />
        </CCol>
      </CRow>
      <CRow className="text-right mt-3">
        <CCol sm={9} />
        <CCol sm={3}>
          <CLoadingButton
            disabled={warehouseId <= 0}
            color="primary"
            size="sm"
            loading={fetching}
            onClick={() => {
              const week = weeks.find((week) => week.number === weekNumber);

              fetchBatches({
                variables: {
                  filters: {
                    warehouseId,
                    dateFrom: week?.dateFrom,
                    dateTo: week?.dateTo,
                  },
                },
              });
            }}
          >
            Descargar Reporte
          </CLoadingButton>
        </CCol>
      </CRow>
    </>
  );
};

const writeExcel = (
  week: WeekLine,
  warehouse: Warehouse,
  items: BatchItem[]
) => {
  const data: SheetData = [
    [
      {
        value: "Tipo",
      },
      {
        value: "Producto",
      },
      {
        value: "Categoría",
      },
      {
        value: "Proveedor(es)",
      },
      {
        value: "Costo",
        align: "right",
      },
      {
        value: "Cantidad",
        align: "center",
      },
      {
        value: "Subtotal ($)",
        align: "right",
      },
      {
        value: "Fecha de Ingreso",
      },
    ],
  ];

  items.forEach((item) => {
    data.push([
      {
        value: findBatchType(item.type)?.name,
      },
      {
        value: item.product?.name,
      },
      {
        value: item.product.category?.name ?? "",
      },
      {
        value:
          item.product.suppliers?.map((s) => s.supplier?.name).join("; ") ?? "",
      },
      {
        value: formatCurrency(item.cost),
        align: "right",
      },
      {
        value: item.quantity,
        align: "center",
      },
      {
        value: item.cost * item.quantity,
        align: "right",
      },
      {
        value: dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm"),
      },
    ]);
  });

  writeXlsxFile(data, {
    sheet: `Semana ${week.number} - ${week.year}`,
    fileName: `Ingresos en Depósito ${warehouse.name}.xlsx`,
  });
};

export default BatchesPerWeek;
