import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CFormSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Company } from "src/api/companies";
import { StoreCreate, StoreSchema, StoreType } from "src/api/stores";
import { useLocation, useNavigate } from "react-router";
import { useFormik } from "formik";

import { GraphQLFind } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { storeTypes } from "src/helpers/stores";

const NewStore = () => {
  const location = useLocation();
  const { companyId } = location.state ?? {};
  const navigate = useNavigate();

  if (!companyId) {
    navigate("/");
  }

  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      variables: {
        id: companyId,
      },
    }
  );

  if (!company?.data) {
    return null;
  }

  return (
    <CCol lg={12}>
      <Form company={company.data} />
    </CCol>
  );
};

const Form = ({ company }: { company: Company }) => {
  const navigate = useNavigate();

  const [mutation, { loading }] = useMutation(Api.Stores.CREATE_STORE, {
    onCompleted: () => {
      navigate(`/companies/${company.id}#stores`);
    },
  });

  const formik = useFormik<StoreCreate>({
    initialValues: {
      companyId: company.id,
      name: "",
      streetName: "",
      streetNumber: "",
      phone: "",
      website: "",
      province: "",
      city: "",
      email: "",
      type: StoreType.Store,
      utid: company.isFranchise ? "" : company.utid,
    },
    onSubmit: (data) => {
      if (!loading) {
        mutation({
          variables: {
            input: {
              ...data,
              name: data.name?.trim(),
            },
          },
        });
      }
    },
    validationSchema: StoreSchema,
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Nuevo Punto de Venta</CCardHeader>
        <CCardBody>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel>Empresa</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{company.name}</p>
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="name">Tipo</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormSelect
                id="type"
                placeholder="Tipo"
                name="type"
                onChange={formik.handleChange}
                defaultValue={formik.values.type}
                {...getValidity(formik.values.type, formik.errors.type)}
              >
                {storeTypes.map((storeType) => (
                  <option key={storeType.id} value={storeType.id}>
                    {storeType.label}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="streetName">Calle</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetName"
                placeholder="Calle"
                name="streetName"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetName,
                  formik.errors.streetName
                )}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetNumber"
                placeholder="Altura"
                name="streetNumber"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetNumber,
                  formik.errors.streetNumber
                )}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="city">Ciudad</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="city"
                placeholder="Ciudad"
                name="city"
                onChange={formik.handleChange}
                {...getValidity(formik.values.city, formik.errors.city)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="province">Provincia</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="province"
                placeholder="Provincia"
                name="province"
                onChange={formik.handleChange}
                {...getValidity(formik.values.province, formik.errors.province)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="phone">Teléfono</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="phone"
                placeholder="Teléfono"
                name="phone"
                onChange={formik.handleChange}
                {...getValidity(formik.values.phone, formik.errors.phone)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="website">Sitio Web</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="website"
                placeholder="Sitio Web"
                name="website"
                type="url"
                onChange={formik.handleChange}
                {...getValidity(formik.values.website, formik.errors.website)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol md="3">
              <CFormLabel htmlFor="email">Email</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="email"
                placeholder="Email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                {...getValidity(formik.values.email, formik.errors.email)}
              />
            </CCol>
          </CRow>
          {company.isFranchise && (
            <CRow className="mt-2">
              <CCol md="3">
                <CFormLabel htmlFor="utid">CUIT</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="utid"
                  placeholder="CUIT"
                  name="utid"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.utid, formik.errors.utid)}
                />
              </CCol>
            </CRow>
          )}
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="6"></CCol>
            <CCol md="6" className="flex justify-content-end">
              <CLoadingButton
                color="primary"
                loading={loading}
                size="sm"
                disabled={loading || !formik.isValid}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default NewStore;
