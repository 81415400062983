import { useMutation } from "@apollo/client";
import {
  CButton,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router";
import Api from "src/api";
import { Sale } from "src/api/sales";
import { ForwardedModalProps } from "src/types";

const CancelSaleModal: ForwardRefRenderFunction<ForwardedModalProps> = (
  _,
  ref
) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [sale, setSale] = useState<Sale>();

  const [mutation, { loading: cancelling }] = useMutation(
    Api.Sales.CANCEL_SALE,
    {
      onCompleted: () => {
        handleDismiss();
        navigate("/sales");
      },
    }
  );

  const handleDismiss = () => {
    setVisible(false);
    setSale(undefined);
  };

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: Sale) => {
        setSale(s);
        setVisible(true);
      },
    }),
    []
  );

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>Eliminar Venta #{sale?.id}</CModalHeader>
      <CModalBody>¿Estas seguro que quieres cancelar esta venta?</CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>

        <CLoadingButton
          loading={cancelling}
          size="sm"
          color="danger"
          onClick={() => {
            if (sale) {
              mutation({
                variables: {
                  id: sale.id,
                  restock: false,
                },
              });
            }
          }}
        >
          Cancelar Venta
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(CancelSaleModal);
