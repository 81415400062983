import { useEffect, useRef, useState } from "react";
import {
  CCol,
  CButton,
  CCardFooter,
  CRow,
  CModal,
  CModalHeader,
  CModalFooter,
  CNavItem,
  CNav,
  CNavLink,
  CTabPane,
  CTabContent,
  CSmartTable,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { CompanyWithStores } from "src/api/companies";
import { Store } from "src/api/stores";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { useLocation, useNavigate, useParams } from "react-router";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";
import { findPointOfSale, parsePointOfSaleType } from "src/helpers/stores";
import PlanTab from "./components/PlanTab";
import FormTab from "./components/FormTab";
import { CompanyPlan, Plan } from "src/api/plans";

const TabIndex = ["#basic", "#stores", "#plan"];

const EditCompany = () => {
  const location = useLocation();
  const params = useParams();
  const { hash, pathname, state } = location;
  const companyId = state?.companyId
    ? Number(state.companyId)
    : Number(params.id);
  const initialTab = TabIndex.find((index) => index === hash)
    ? TabIndex.findIndex((index) => index === hash)
    : 0;

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<number>(initialTab);
  const { data: plans } = useQuery<GraphQLMeta<Plan>>(Api.Plans.LIST_PLANS, {
    fetchPolicy: "no-cache",
  });
  const { data: company, refetch } = useQuery<GraphQLFind<CompanyWithStores>>(
    Api.Companies.GET_COMPANY,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: companyId,
      },
    }
  );
  const { data: companyPlan } = useQuery<GraphQLFind<CompanyPlan>>(
    Api.Plans.GET_COMPANY_PLAN,
    {
      fetchPolicy: "no-cache",
      variables: {
        companyId,
      },
    }
  );

  const onTabChange = (newIndex: number) => {
    navigate(`${pathname}${TabIndex[newIndex]}`);
    setCurrentTab(newIndex);
  };

  useEffect(() => {
    if (state?.refetch) {
      refetch();
    }
  }, [state, refetch]);

  if (!company?.data || !companyPlan || !plans?.data.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form
        company={company.data}
        companyPlan={companyPlan.data ?? null}
        currentTab={currentTab}
        plans={plans.data.data}
        onTabChange={onTabChange}
      />
    </CCol>
  );
};

type FormRefProps = {
  delete: () => void;
  submit: () => void;
};

const Form = ({
  company,
  companyPlan,
  currentTab,
  onTabChange,
  plans,
}: {
  company: CompanyWithStores;
  companyPlan: CompanyPlan;
  currentTab: number;
  onTabChange: (index: number) => void;
  plans: Plan[];
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const formTabRef = useRef<FormRefProps>(null);
  const planTabRef = useRef<FormRefProps>(null);

  return (
    <CCard>
      <CCardHeader>Empresa: {company.name}</CCardHeader>
      <CCardBody>
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink onClick={() => onTabChange(0)} active={currentTab === 0}>
              Datos Básicos
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(1)} active={currentTab === 1}>
              Puntos de Venta
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(2)} active={currentTab === 2}>
              Plan
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent className="px-0 py-1">
          <CTabPane visible={currentTab === 0} className="py-3">
            <FormTab
              company={company}
              ref={formTabRef}
              onLoading={(value: boolean) => setLoading(value)}
            />
          </CTabPane>
          <CTabPane visible={currentTab === 1} className="py-3">
            <CSmartTable
              itemsPerPage={company?.stores.length ?? 20}
              items={company?.stores || []}
              columns={[
                { key: "icon", label: "", _props: { className: "w-2" } },
                {
                  key: "name",
                  label: "Nombre",
                },
                { key: "type", label: "Tipo" },
                {
                  key: "address",
                  label: "Dirección",
                },
                {
                  key: "phone",
                  label: "Teléfono",
                },
                {
                  key: "sector",
                  label: "Rubro",
                },
              ]}
              scopedColumns={{
                icon: (item: Store) => {
                  const currentType = findPointOfSale(item.type);
                  const StoreIcon = () => currentType?.icon;

                  return (
                    <td align="center">
                      <StoreIcon />
                    </td>
                  );
                },
                name: (item: Store) => (
                  <td>
                    <span>{item.name}</span>
                  </td>
                ),
                address: (item: Store) => (
                  <td>
                    <span>
                      {item.streetName} {item.streetNumber}
                    </span>
                  </td>
                ),
                type: (item: Store) => (
                  <td>{parsePointOfSaleType(item.type)}</td>
                ),
                sector: (item: Store) => <td>{item.sector?.name}</td>,
                phone: (item: Store) => (
                  <td>
                    <span>{item.phone}</span>
                  </td>
                ),
              }}
              clickableRows
              onRowClick={(item) => {
                navigate(`/stores/${item.id}`);
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CTabPane>
          <CTabPane visible={currentTab === 2} className="py-1">
            <PlanTab
              companyPlan={companyPlan}
              ref={planTabRef}
              onLoading={(value: boolean) => setLoading(value)}
              company={company}
              plans={plans}
            />
          </CTabPane>
        </CTabContent>
      </CCardBody>
      <CCardFooter className="px-3">
        <CRow className="px-0 align-items-center">
          {currentTab === 0 ? (
            <CCol md="12" className="flex justify-content-end">
              <CButton
                size="sm"
                type="button"
                color="danger"
                className="mr-2"
                onClick={() => setShowModal(true)}
              >
                Eliminar
              </CButton>

              <CModal
                alignment="center"
                visible={showModal}
                onClose={() => setShowModal(false)}
              >
                <CModalHeader closeButton>
                  ¿Estás seguro de eliminar esta empresa?
                </CModalHeader>
                <CModalFooter>
                  <CButton
                    size="sm"
                    color="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </CButton>
                  <CLoadingButton
                    size="sm"
                    color="danger"
                    disabled={loading}
                    loading={loading}
                    onClick={() => formTabRef.current?.delete()}
                  >
                    Si, quiero eliminarlo
                  </CLoadingButton>
                </CModalFooter>
              </CModal>

              <CLoadingButton
                loading={loading}
                disabled={loading}
                size="sm"
                type="button"
                color="primary"
                onClick={() => formTabRef.current?.submit()}
              >
                Guardar
              </CLoadingButton>
            </CCol>
          ) : currentTab === 1 ? (
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                color="primary"
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  logEvent("company.new-store", {
                    company,
                  });

                  navigate("/stores/new", {
                    state: { companyId: company.id },
                  });
                }}
              >
                Nuevo Punto de Venta
              </CLoadingButton>
            </CCol>
          ) : (
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                loading={loading}
                disabled={loading}
                type="button"
                color="primary"
                onClick={() => planTabRef.current?.submit()}
              >
                Guardar
              </CLoadingButton>
            </CCol>
          )}
        </CRow>
      </CCardFooter>
    </CCard>
  );
};

export default EditCompany;
