import { gql } from "@apollo/client";
import { User } from "./users";
import { Warehouse } from "./warehouses";
import { Product } from "./products";
import { Nullable } from "src/types";

export type BatchItem = {
  id: number;
  quantity: number;
  cost: number;

  type: BatchType;
  updatedAt: Date;
  expiresAt: Nullable<Date>;

  product: Pick<
    Product,
    "id" | "price" | "name" | "barcodes" | "suppliers" | "category"
  >;
  user: Pick<User, "id" | "name" | "lastname">;
};

export enum BatchType {
  Return = "RETURN",
  Invoice = "INVOICE",
  Count = "COUNT",
  Quote = "QUOTE",
  NewProduct = "NEW_PRODUCT",
  Move = "MOVE",
  Shipment = "SHIPMENT",
}

export type BatchSupplier = {
  supplierId: number;
};

export type Batch = {
  id: number;
  status: "OPEN" | "FULFILLED" | "CLOSED";
  user: Pick<User, "id" | "name" | "lastname">;
  type: BatchType;
  notes: string;
  warehouse: Warehouse;
  items: BatchItem[];
  suppliers: BatchSupplier[];

  createdAt: Date;
  updatedAt: Date;
};

export const LIST_BATCHES = gql`
  query batches($filters: BatchesFilters!) {
    data: batches(filters: $filters) {
      data {
        id
        status
        type

        user {
          id
          name
          lastname
        }

        warehouse {
          id
          name
        }

        createdAt
        updatedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_BATCH = gql`
  query batch($id: Int!) {
    data: batch(id: $id) {
      id
      status
      type
      notes

      user {
        id
        name
        lastname
      }

      warehouse {
        id
        name
        companyId

        stores {
          id
        }
      }

      suppliers {
        supplierId
      }

      items {
        id
        quantity
        cost
        expiresAt

        product {
          id
          name
          price
          barcodes {
            barcode
          }
        }

        user {
          id
          name
          lastname
        }
      }

      createdAt
      updatedAt
    }
  }
`;

export const CREATE_BATCH = gql`
  mutation createBatch($input: CreateBatchInput!) {
    data: createBatch(input: $input) {
      id
    }
  }
`;

export const ADD_ITEM_TO_BATCH = gql`
  mutation addItemToBatch($input: AddItemToBatchInput!) {
    data: addItemToBatch(input: $input) {
      id
    }
  }
`;

export const DELETE_ITEM_FROM_BATCH = gql`
  mutation deleteItemFromBatch($id: Int!) {
    data: deleteItemFromBatch(id: $id) {
      id
    }
  }
`;

export const UPDATE_BATCH = gql`
  mutation updateBatch($id: Int!, $input: UpdateBatchInput!) {
    data: updateBatch(id: $id, input: $input) {
      id
      type
      notes

      suppliers {
        supplierId
      }
    }
  }
`;

export const CLOSE_BATCH = gql`
  mutation closeBatch($id: Int!) {
    data: closeBatch(id: $id) {
      id
    }
  }
`;

export const FULFILL_BATCH = gql`
  mutation fulfillBatch($id: Int!) {
    data: fulfillBatch(id: $id) {
      id
      productsToUpdate
    }
  }
`;

export const INSERT_INTO_BATCHES = gql`
  mutation insertIntoBatches($shipmentId: Int!, $warehouseId: Int!) {
    data: insertIntoBatches(
      shipmentId: $shipmentId
      warehouseId: $warehouseId
    ) {
      id
      warehouseId
    }
  }
`;
