import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Store } from "src/api/stores";
import { CashRegisterCreate } from "src/api/registers";
import { useNavigate } from "react-router";
import { useFormik } from "formik";

import Api from "src/api";
import { GraphQLFind } from "src/types";
import { getValidity } from "src/helpers/validation";
import { AppLoader } from "src/components/Loader/Loader";

const NewRegister = ({ storeId }: { storeId: Store["id"] }) => {
  const navigate = useNavigate();

  if (!storeId) {
    navigate("/");
  }

  const { data: store } = useQuery<GraphQLFind<Store>>(Api.Stores.GET_STORE, {
    variables: {
      id: Number(storeId),
    },
  });

  if (!store?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form store={store.data} />
    </CCol>
  );
};

const Form = ({ store }: { store: Store }) => {
  const navigate = useNavigate();

  const [mutation, { loading }] = useMutation(Api.Registers.CREATE_REGISTER, {
    onCompleted: () => {
      if (store) {
        navigate(`/stores/${store.id}#registers`);
      }
    },
  });

  const formik = useFormik<CashRegisterCreate>({
    initialValues: {
      name: "",
      description: "",
      storeId: store.id,
    },
    onSubmit: (data) => {
      if (!loading) {
        mutation({
          variables: {
            ...data,
            storeId: store.id,
          },
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Nueva Caja Registradora</CCardHeader>
        <CCardBody>
          <CRow className="form-row static">
            <CCol md="3">
              <CFormLabel>Punto de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{store.name}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="description">Descripción</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="description"
                placeholder="Descripción"
                name="description"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.description,
                  formik.errors.description
                )}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="9"></CCol>
            <CCol md="3" className="flex justify-content-end">
              <CLoadingButton
                loading={loading}
                size="sm"
                color="primary"
                disabled={loading}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default NewRegister;
