import {
  CAlert,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { LoginUser } from "src/api/auth";
import yup, { getValidity } from "src/helpers/validation";
import { useFormik } from "formik";
import { useAdminStore } from "src/store";
import { useMutation } from "@apollo/client";
import { AppVersion, UserWithToken } from "src/types";
import Api from "src/api";
import { logEvent } from "src/helpers/analytics";
import PasswordInput from "src/components/PasswordInput";
import { getFeatureFlags } from "src/helpers/database";

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const Login = () => {
  const { setUser, setCurrentCompany } = useAdminStore();

  const [mutation, { loading, error }] = useMutation(Api.Auth.LOGIN, {
    onCompleted: (data: { user: UserWithToken }) => {
      setUser(data.user);
      setCurrentCompany(data.user.companies[0]);

      getFeatureFlags({ companyId: data.user.companies[0].id });

      logEvent("login.success", {
        id: data.user.id,
        email: data.user.email,
      });
    },
    onError: (err) => {
      logEvent("login.error", {
        error: err.message,
      });
    },
  });

  const formik = useFormik<LoginUser>({
    initialValues: {
      email: "",
      password: "",
      version: AppVersion,
      service: "ADMIN",
    },
    onSubmit: (data: LoginUser) => {
      logEvent("login.submit", {
        email: formik.values.email,
      });

      if (!loading) {
        mutation({
          variables: {
            input: data,
          },
        });
      }
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
  });

  const errorMessage = () => {
    if (!error) {
      return "";
    }

    if (error.message === "invalid_credentials") {
      return "Los datos ingresados son incorrectos.";
    }

    if (error.message === "service_not_found") {
      return "No tienes acceso a este servicio.";
    }

    return "Ha ocurrido un error. Vuelve a intentar.";
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" xl="6" sm="12" xs="12">
            <CCardGroup>
              <CCard className="px-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1>Ingreso</h1>
                    <p className="text-muted">Ingresar a tu cuenta</p>
                    <CFormInput
                      type="email"
                      name="email"
                      floatingLabel="Email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      className="mb-3"
                      {...getValidity(formik.values.email, formik.errors.email)}
                    />
                    <PasswordInput
                      autoComplete="current-password"
                      floatingLabel="Contraseña"
                      placeholder="Contraseña"
                      name="password"
                      onChange={formik.handleChange}
                      className="mb-3"
                      {...getValidity(
                        formik.values.password,
                        formik.errors.password
                      )}
                    />

                    {error && <CAlert color="danger">{errorMessage()}</CAlert>}
                    <CRow>
                      <CCol xs="12" className="text-center">
                        <CLoadingButton
                          type="submit"
                          loading={loading}
                          disabled={loading}
                          color="primary"
                          className="px-4"
                        >
                          {loading ? "Ingresando..." : "Ingresar"}
                        </CLoadingButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <CRow className="justify-content-center">
          <span className="text-center font-3 mt-3">Versión {AppVersion}</span>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
