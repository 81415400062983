import { useMutation } from "@apollo/client";
import {
  CAlert,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import Api from "src/api";
import { Company, CompanyUpdate } from "src/api/companies";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";

const CompanySettings = ({ company }: { company: Company }) => {
  const { hasPermission } = useAdminStore();
  const canEdit = hasPermission("UPDATE_COMPANY");
  const [mutation, { loading }] = useMutation(Api.Companies.UPDATE_COMPANY, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const formik = useFormik<CompanyUpdate>({
    initialValues: company,
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          name: values.name,
          legalName: values.legalName,
          utid: values.utid,
          streetName: values.streetName,
          streetNumber: values.streetNumber,
          province: values.province,
          city: values.city,
          zipCode: values.zipCode,
        };

        logEvent("settings.company.submit", {
          company,
          input,
        });

        mutation({
          variables: {
            id: company.id,
            input,
          },
        });
      }
    },
  });

  return (
    <CForm>
      <CRow>
        <CCol md="3">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Nombre de Fantasía"
            defaultValue={formik.values.name}
            name="name"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="3">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Razón Social"
            defaultValue={formik.values.legalName}
            name="legalName"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="2">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="CUIT"
            defaultValue={formik.values.utid}
            name="utid"
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol md="3">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Calle"
            defaultValue={formik.values.streetName}
            name="streetName"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="2">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Altura"
            defaultValue={formik.values.streetNumber}
            name="streetNumber"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="2">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Provincia"
            defaultValue={formik.values.province}
            name="province"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="2">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Ciudad"
            defaultValue={formik.values.city}
            name="city"
            onChange={formik.handleChange}
          />
        </CCol>
        <CCol md="2">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Código Postal"
            defaultValue={formik.values.zipCode}
            name="zipCode"
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mt-3">
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              loading={loading}
              disabled={loading}
              color="primary"
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </CForm>
  );
};

export default CompanySettings;
