import { gql } from "@apollo/client";
import request from "./request";
import { Store } from "./stores";
import yup from "src/helpers/validation";
import { Company } from "./companies";
import { Nullable } from "src/types";

export const CreateSaleInvoiceSchema = yup.object().shape({
  invoiceTypeId: yup.number().min(1).required(),
  customerId: yup.string().numeric().min(-1).required(),
  dniType: yup.string().when("customerId", {
    is: (value: string) => Number(value) === -1,
    then: () => yup.number().min(1),
  }),
  dni: yup.string().when("customerId", {
    is: (value: string) => Number(value) === -1,
    then: () => yup.string().dniOrCuit(),
  }),
});

export const FetchUtidSchema = yup.object().shape({
  utid: yup.string().dniOrCuit().required(),
  genre: yup.string(),
});

export const SendInvoiceEmailSchema = yup.object().shape({
  cae: yup.string().required(),
  email: yup.string().email().required(),
});

export type AfipService = {
  id: number;
  service: string;
  createdAt: Date;
  expiresAt: Date;
};

export type AfipCertificate = {
  id: number;
  filename: string;
  utid: string;

  active: boolean;
  generatedAt: Date;
  expiresAt: Date;

  createdAt: Date;
  updatedAt: Date;
};

export type AfipStatus = {
  isFranchise: boolean;
  status: "CONNECTED" | "NOT_CONNECTED" | "UNAVAILABLE";
  auth: string;
  app: string;
  db: string;
  store: Store;
  company: Company;
  services: AfipService[];
};

export type AfipInvoiceType = {
  id: number;
  name: string;
  anonymous: boolean;
};

export enum ElectronicInvoiceOrigin {
  TiendaNube = "TIENDA_NUBE",
  MercadoLibre = "MERCADO_LIBRE",
  MercadoShops = "MERCADO_SHOPS",
}

export type ElectronicInvoice = {
  id: number;
  cae: string;
  entityId: string;
  invoiceTypeId: number;
  origin: ElectronicInvoiceOrigin;
  voucher: AfipInvoice;
  afipInvoice: AfipInvoice;
  createdAt: Date;
  total: number;
  relatedTo: Nullable<number>;
};

export type AfipPointOfSale = {
  id: number;
  type: string;
  blocked: boolean;
};

export type AfipVoucher = {
  id: number;
  name: string;
  dateFrom: Date;
  dateTo: Date;
};

export type AfipCurrency = AfipVoucher;

export type AfipDocument = AfipVoucher;

export type AfipConcept = AfipVoucher;

export type AfipInvoice = {
  concept: AfipConcept;
  document: AfipDocument;
  dniNumber: number;
  date: Date;
  total: number;
  subtotal: number;
  tax: number;
  serviceDateFrom: Date;
  serviceDateTo: Date;
  paymentExpirationDate: Date;
  currency: AfipCurrency;
  quotation: number;
  result: string;
  cae: string;
  type: string;
  expirationDate: Date;
  processDate: Date;
  poinfOfSale: AfipPointOfSale;
  voucher: AfipVoucher;
  packId: Nullable<number>;
};

export const GET_STATUS = gql`
  query ($companyId: Int!) {
    data: afipStatus(companyId: $companyId) {
      status
      app
      db
      auth

      services {
        id
        service
        createdAt
        expiresAt
      }

      isFranchise
      store {
        id
        name
        type

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
          posId
        }
      }
      company {
        id
      }
    }
  }
`;

export const LIST_INVOICE_TYPES = gql`
  query ($companyId: Int!, $storeId: Int) {
    data: invoiceTypes(companyId: $companyId, storeId: $storeId) {
      id
      name
      anonymous
    }
  }
`;

export const LIST_POINT_OF_SALES = gql`
  query ($companyId: Int!, $storeId: Int) {
    data: pointOfSales(companyId: $companyId, storeId: $storeId) {
      id
      type
      blocked
    }
  }
`;

export const LIST_DOCUMENT_TYPES = gql`
  query ($companyId: Int!, $storeId: Int) {
    data: documentTypes(companyId: $companyId, storeId: $storeId) {
      id
      name
    }
  }
`;

export const LIST_CERTIFICATES = gql`
  query {
    data: certificates {
      id
      utid
      active
      generatedAt
      expiresAt
      createdAt
    }
  }
`;

export const FETCH_UTID = gql`
  query fetchByUtid($utid: String!, $genre: String) {
    data: fetchByUtid(utid: $utid, genre: $genre) {
      name
      lastName
      legalName
      address {
        streetName
        streetNumber
        zipCode
        province
      }
      id
      type
      taxType
      taxes {
        id
        name
        period
      }
      startDate
    }
  }
`;

export const CREATE_SALE_INVOICE = gql`
  mutation ($input: CreateSaleInvoiceInput!) {
    data: createSaleInvoice(input: $input) {
      id
    }
  }
`;

export const importAfipFiles = (body: FormData) =>
  request.post(`/imports/afip`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const uploadCertificate = (body: FormData) =>
  request.put(`/imports/afip`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const downloadCertificate = (id: number) =>
  request.get(`/imports/afip/${id}`, {
    responseType: "blob",
  });

export const DOWNLOAD_ELECTRONIC_INVOICE = (
  saleId: number,
  cae: string,
  type: "credit" | "invoice"
): Promise<{ fileName: string; data: BlobPart }> =>
  request.post(
    `/invoices/${saleId}/invoice/${cae}`,
    {
      type,
    },
    {
      responseType: "blob",
    }
  );

export const parseAfipStatus = ({ status }: AfipStatus) => {
  switch (status) {
    case "CONNECTED":
      return "Conectado";

    case "NOT_CONNECTED":
      return "Sin conectar";

    case "UNAVAILABLE":
      return "No disponible";

    default:
      return "";
  }
};

export const getCreditNoteId = (invoiceTypeId: number) => {
  switch (invoiceTypeId) {
    case 1:
      return 3;

    case 6:
      return 9;

    case 11:
      return 13;
  }

  return 0;
};
