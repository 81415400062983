import { useLocation, useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";

import Pagination from "src/components/Pagination/Pagination";

import { useAdminStore } from "src/store";
import { PaymentMethod, Sale } from "src/api/sales";
import { parsePaymentMethods } from "src/helpers/payments";
import { FileText } from "lucide-react";

const RappiSales = () => {
  const { hasPermission } = useAdminStore();
  const canSeeSale = hasPermission("SHOW_SALE");
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const params = useParams();
  const storeId = Number(params.storeId);

  if (!storeId) {
    navigate("/rappi");
  }

  const {
    data: sales,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Sale>>(Api.Sales.LIST_SALES, {
    variables: {
      filters: {
        storeId,
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: -1,
      },
    },
  });
  const { page, pageChange } = usePagination(`rappi/${storeId}/sales`, refetch);

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Ventas
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                items={
                  sales?.data.data.map((sale) => ({
                    ...sale,
                    _props: { color: sale.deletedAt ? "danger" : "default" },
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "store",
                    label: "Punto de Venta",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "user", label: "Vendedor" },
                  { key: "payments", label: "Métodos de Pago" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "date",
                    label: "Fecha",
                    _props: { className: "font-weight-bold text-right" },
                  },
                  {
                    key: "file",
                    label: "",
                    _props: { className: "text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  store: (sale: Sale) => <td>{sale.store.name}</td>,
                  user: (sale: Sale) => (
                    <td>
                      {sale.user?.name} {sale.user?.lastname}
                    </td>
                  ),
                  payments: (sale: Sale) => (
                    <td>{parsePaymentMethods(sale.payments)}</td>
                  ),
                  amount: (sale: Sale) => (
                    <td className="text-right">
                      {formatCurrency(
                        sale.payments
                          .filter(
                            (payment) =>
                              ![
                                PaymentMethod.Discount,
                                PaymentMethod.CustomerDiscount,
                              ].includes(payment.type)
                          )
                          .reduce((prev, curr) => prev + curr.amount, 0)
                      )}
                    </td>
                  ),
                  date: (sale: Sale) => (
                    <td className="text-right">
                      {dateFormat(sale.date, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                  file: (sale: Sale) => (
                    <td className="text-right">
                      {sale.invoices?.length > 0 && <FileText color="black" />}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: canSeeSale,
                }}
                clickableRows={canSeeSale}
                onRowClick={(sale) => {
                  if (canSeeSale) {
                    navigate(`/sales/${sale.id}`);
                  }
                }}
              />

              <Pagination meta={sales} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default RappiSales;
