import { useMutation } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CSmartTable,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import Api from "src/api";
import { Product, ProductFraction } from "src/api/products";
import { formatCurrency } from "src/helpers/numbers";
import { useProductError } from "src/hooks/useProductError";

const ProductFractions = ({
  product,
  productPrice,
  refetch,
}: {
  product: Product;
  productPrice: number;
  refetch: () => void;
}) => {
  const { handleProductErrorMessage } = useProductError();
  const [createMutation, { loading: creating }] = useMutation(
    Api.Products.CREATE_FRACTION,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
      onError: (error) => {
        handleProductErrorMessage(error);
      },
    }
  );

  const [deleteMutation, { loading: deleting }] = useMutation(
    Api.Products.DELETE_FRACTION,
    {
      onCompleted: () => {
        refetch();
        formik.resetForm();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      productId: product.id,
      name: product.name,
      sku: `${product.sku}-fraction-${product.fractions.length + 1}`,
      quantity: 0.1,
      profit: 0,
      price: productPrice * 0.1,
      barcode: "",
      type: "EAN13",
    },
    onSubmit: (values) => {
      if (!creating && !deleting) {
        createMutation({
          variables: {
            productId: values.productId,
            name: values.name,
            sku: values.sku,

            quantity: values.quantity,
            profit: 0,
            price: productPrice * formik.values.quantity,

            barcode: values.barcode,
            type: values.type,
          },
        });
      }
    },
  });

  return (
    <CCard className="mt-3">
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>Fracciones</CCardHeader>
        <CCardBody>
          <CSmartTable
            items={[...product.fractions, {}]}
            itemsPerPage={product.fractions.length + 1}
            columns={[
              {
                key: "quantity",
                label: "Fracción",
                _props: { className: "text-right" },
              },
              { key: "name", label: "Nombre" },
              { key: "sku", label: "SKU" },
              {
                key: "price",
                label: "Precio",
                _props: { className: "text-right" },
              },
              { key: "type", label: "Tipo de Código" },
              { key: "barcode", label: "Código de Barra" },
              {
                key: "actions",
                label: "Acciones",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              quantity: (item: ProductFraction) =>
                item.id ? (
                  <td className="text-right">{item.quantity}</td>
                ) : (
                  <td>
                    <CFormInput
                      name="quantity"
                      defaultValue={item.quantity ?? 0.1}
                      onChange={formik.handleChange}
                      type="number"
                      min={0.01}
                      step={0.1}
                      max={0.99}
                    />
                  </td>
                ),
              name: (item: ProductFraction) =>
                item.id ? (
                  <td>{item.name}</td>
                ) : (
                  <td>
                    <CFormInput
                      name="name"
                      defaultValue={product.name}
                      onChange={formik.handleChange}
                      placeholder="XXX12323212"
                    />
                  </td>
                ),
              sku: (item: ProductFraction, index: number) =>
                item.id ? (
                  <td>{item.sku}</td>
                ) : (
                  <td>
                    <CFormInput
                      name="sku"
                      value={formik.values.sku}
                      onChange={formik.handleChange}
                    />
                  </td>
                ),
              price: (item: ProductFraction) => (
                <td className="text-right">
                  {formatCurrency(
                    item.id
                      ? productPrice !== product.price
                        ? productPrice * item.quantity
                        : item.price
                      : productPrice * formik.values.quantity
                  )}
                </td>
              ),
              barcode: (item: ProductFraction) =>
                item.id ? (
                  <td>{item.barcode}</td>
                ) : (
                  <td>
                    <CFormInput
                      name="barcode"
                      onChange={formik.handleChange}
                      placeholder="XXX12323212"
                    />
                  </td>
                ),
              type: (item: ProductFraction) =>
                item.id ? (
                  <td>{item.type}</td>
                ) : (
                  <td>
                    <CFormSelect
                      id="type"
                      name="type"
                      onChange={formik.handleChange}
                      value={formik.values.type}
                    >
                      <option key="0" value="0">
                        Seleccione Tipo
                      </option>
                      <option value="EAN13">EAN13</option>
                      <option value="DUN14">DUN14</option>
                    </CFormSelect>
                  </td>
                ),
              actions: (item: ProductFraction) => (
                <td align="right">
                  {item.id ? (
                    <CLoadingButton
                      onClick={() =>
                        deleteMutation({
                          variables: {
                            id: item.id,
                          },
                        })
                      }
                      disabled={deleting || creating}
                      size="sm"
                      color="danger"
                    >
                      Eliminar Fracción
                    </CLoadingButton>
                  ) : (
                    <CLoadingButton
                      onClick={() => formik.handleSubmit()}
                      disabled={deleting || creating}
                      size="sm"
                      color="success"
                    >
                      Crear Fracción
                    </CLoadingButton>
                  )}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
              hover: true,
            }}
          />
        </CCardBody>
      </CForm>
    </CCard>
  );
};

export default ProductFractions;
