import { gql } from "@apollo/client";
import { Product } from "./products";
import { Nullable } from "src/types";
import yup from "src/helpers/validation";

export const PromotionsSchema = yup.object().shape({
  name: yup.string().required(),
  items: yup.array().min(2),
});

export type PromotionItem = {
  productId: number;
  initialPrice: number;
  discount: number;
  finalPrice: number;

  product: Product;
};

export type Promotion = {
  id: number;
  companyId: number;
  storeId: Nullable<number>;
  userId: number;
  name: string;
  barcode: string;

  initialPrice: number;
  discount: number;
  finalPrice: number;

  items: PromotionItem[];

  dateFrom: Date;
  dateTo?: Nullable<Date>;
};

export type CreatePromotion = Omit<Promotion, "id" | "userId" | "items"> & {
  items: (Omit<PromotionItem, "product"> & { productName: string })[];
};

export type UpdatePromotion = Omit<CreatePromotion, "companyId">;

export const LIST_PROMOTIONS = gql`
  query promotions($filters: PromotionFilters) {
    data: promotions(filters: $filters) {
      data {
        id
        name
        barcode
        dateFrom
        dateTo

        initialPrice
        discount
        finalPrice

        items {
          id
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_PROMOTION = gql`
  query promotion($id: Int!) {
    data: promotion(id: $id) {
      id
      storeId
      name
      barcode
      dateFrom
      dateTo

      items {
        productId
        initialPrice
        discount
        finalPrice

        product {
          name
        }
      }
    }
  }
`;

export const CREATE_PROMOTION = gql`
  mutation createPromotion($input: CreatePromotionInput!) {
    data: createPromotion(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  mutation updatePromotion($id: Int!, $input: UpdatePromotionInput!) {
    data: updatePromotion(id: $id, input: $input) {
      id
    }
  }
`;
