import { CreatePromotion } from "src/api/promotions";
import { calculateProfit } from "./numbers";

export const usefulPromos = [
  {
    label: "2x1",
    config: [{ discount: 0 }, { discount: 100 }],
    items: 2,
  },
  {
    label: "3x2",
    config: [{ discount: 0 }, { discount: 0 }, { discount: 100 }],
    items: 3,
  },
  {
    label: "3x1",
    config: [{ discount: 0 }, { discount: 100 }, { discount: 100 }],

    items: 3,
  },
  {
    label: "4x1",
    config: [
      { discount: 0 },
      { discount: 100 },
      { discount: 100 },
      { discount: 100 },
    ],
    items: 4,
  },
  {
    label: "5x1",
    config: [
      { discount: 0 },
      { discount: 100 },
      { discount: 100 },
      { discount: 100 },
      { discount: 100 },
    ],
    items: 5,
  },
  {
    label: "2do al 50%",
    config: [{ discount: 0 }, { discount: 50 }],
    items: 2,
  },
  {
    label: "N x Monto Fijo",
    config: (): string | null => {
      return window.prompt("Ingrese el monto fijo", "0");
    },
  },
];

export const calculateFixedPriceDiscounts = (
  items: CreatePromotion["items"],
  finalPrice: number
) => {
  const totalActual = items.reduce(
    (total, item) => total + item.initialPrice,
    0
  );

  const totalDiscount = totalActual - finalPrice;

  if (totalDiscount < 0) {
    throw new Error("El monto final no puede ser mayor que el total actual.");
  }

  let accumulatedFinalPrice = 0;

  const updatedItems = items.map((item) => {
    const proporcion = item.initialPrice / totalActual;
    const discount = parseFloat((totalDiscount * proporcion).toFixed(2));
    const itemFinalPrice = parseFloat(
      (item.initialPrice - discount).toFixed(2)
    );

    accumulatedFinalPrice += itemFinalPrice;

    return {
      ...item,
      finalPrice: itemFinalPrice,
      discount: Math.abs(calculateProfit(item.initialPrice, itemFinalPrice)),
    };
  });

  const difference = parseFloat(
    (finalPrice - accumulatedFinalPrice).toFixed(2)
  );

  if (difference !== 0) {
    const lastItem = updatedItems[updatedItems.length - 1];

    lastItem.finalPrice = parseFloat(
      (lastItem.finalPrice + difference).toFixed(2)
    );
    lastItem.discount = Math.abs(
      calculateProfit(lastItem.initialPrice, lastItem.finalPrice)
    );
  }

  return updatedItems;
};
