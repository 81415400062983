import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLoadingButton,
  CRow,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import { FooterItem } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import Api from "src/api";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { formatCurrency, removePercentageFromValue } from "src/helpers/numbers";
import { GraphQLMeta, GraphQLMutation, Nullable } from "src/types";
import { Quote, QuoteItem } from "src/api/quotes";
import { useNavigate } from "react-router";
import { PriceList, PriceListItem } from "src/api/price-lists";
import { AppLoader } from "src/components/Loader/Loader";
import { useAdminStore } from "src/store";
import { Customer } from "src/api/customers";
import { getCustomer } from "src/helpers/sales";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import { TrashIcon } from "lucide-react";
import { add } from "date-fns/add";

const PriceListSelector = ({
  priceList,
  onChange,
}: {
  priceList?: Nullable<PriceList>;
  onChange: (priceList: PriceList) => void;
}) => {
  const { data: priceLists, loading } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  if (loading || !priceLists?.data.data) {
    return <AppLoader />;
  }

  return (
    <CRow>
      <CCol sm={4}>
        <CFormLabel>Lista de precios</CFormLabel>
        <SmartSelect
          name="priceListId"
          search
          type={SmartSelectType.Stores}
          placeholder="Selecciona una lista de precios"
          emptyLabel="No tienes listas de precios"
          options={priceLists.data.data.map((priceList) => ({
            name: priceList.name,
            value: priceList.id,
            type: priceList.store.type,
          }))}
          onChange={(e) => {
            const pli = priceLists.data.data.find((p) => p.id === Number(e));

            if (pli) {
              onChange(pli);
            }
          }}
          value={priceList?.id.toString()}
        />
      </CCol>
    </CRow>
  );
};

const QuoteForm = () => {
  const { currentCompany } = useAdminStore();
  const navigate = useNavigate();
  const quantityRef = useRef<HTMLInputElement>(null);
  const [priceList, setPriceList] = useState<PriceList>();
  const [date, setDate] = useState<Date>(new Date());
  const [validUntil, setValidUntil] = useState<Date>(add(date, { days: 7 }));
  const [notes, setNotes] = useState<string>("");
  const [customer, setCustomer] = useState<Pick<Customer, "id" | "utid">>({
    id: -1,
    utid: "",
  });
  const [items, setItems] = useState<
    Pick<
      QuoteItem,
      | "productId"
      | "tax"
      | "priceListItemId"
      | "discount"
      | "price"
      | "quantity"
    >[]
  >([]);

  const { data: priceListItemList } = useQuery<GraphQLMeta<PriceListItem>>(
    Api.PriceLists.LIST_PRICE_LIST_ITEMS,
    {
      fetchPolicy: "no-cache",
      skip: !priceList?.id,
      variables: {
        filters: {
          priceListId: priceList?.id,
          limit: 0,
        },
      },
    }
  );

  const { data: customers } = useQuery<GraphQLMeta<Customer>>(
    Api.Customers.LIST_CUSTOMERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
        },
      },
    }
  );

  const [createMutation, { loading: creating }] = useMutation<
    GraphQLMutation<Quote>
  >(Api.Quotes.CREATE_QUOTE, {
    onCompleted: ({ data }) => {
      navigate(`/quotes/${data.id}`);
    },
  });

  const products = useMemo(() => {
    if (!priceListItemList?.data.data) {
      return [];
    }

    return priceListItemList.data.data.map((priceListItem) => ({
      name: `${priceListItem.name} - ${
        priceListItem.product.barcodes.length > 0
          ? priceListItem.product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      } `,
      value: priceListItem.id,
    }));
  }, [priceListItemList]);

  const columns = [
    {
      key: "product",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    {
      key: "quantity",
      label: "Cantidad",
      _props: { className: "text-center font-weight-bold" },
    },
    {
      key: "price",
      label: "Precio Unitario",
      _props: { className: "text-right font-weight-bold" },
    },
    {
      key: "discount",
      label: "Descuento (%)",
      _props: { className: "text-right font-weight-bold" },
    },
    {
      key: "subtotal",
      label: "Subtotal",
      _props: { className: "text-right font-weight-bold" },
    },
    {
      key: "actions",
      label: "",
      _props: { className: "text-right" },
    },
  ];

  const generateFooter = () => {
    const footerStack: (FooterItem | string)[] = [
      {
        label: "Totales",
      },
      {
        label: `${items.length} items`,
        _props: { className: "text-center" },
      },
      "",
      "",
      {
        label: formatCurrency(
          items.reduce(
            (prev, curr) =>
              prev +
              removePercentageFromValue(curr.price, curr.discount) *
                curr.quantity,
            0
          )
        ),
        _props: { className: "text-right" },
      },
      "",
    ];

    return footerStack;
  };

  const formik = useFormik({
    initialValues: {
      productId: 0,
      priceListItemId: 0,
      product: {
        name: "",
      },
      price: 0,
      tax: 0,
      quantity: 0,
    },
    onSubmit: (values) => {
      if (values.productId > 0 && values.quantity > 0) {
        setItems((prev) => {
          if (prev.find((item) => item.productId === values.productId)) {
            return prev.map((prevItem) => {
              if (prevItem.productId === values.productId) {
                return {
                  ...prevItem,
                  quantity: prevItem.quantity + values.quantity,
                };
              }

              return prevItem;
            });
          }

          const newItem = {
            productId: values.productId,
            priceListItemId: values.priceListItemId,
            product: {
              name: values.product.name,
            },
            price: values.price,
            tax: values.tax,
            discount: 0,
            quantity: values.quantity,
          };

          return [...(prev ?? {}), newItem];
        });

        formik.resetForm();
      }
    },
  });

  const deleteItem = (item: QuoteItem) => {
    setItems((prev) =>
      prev.filter((i) => i.priceListItemId !== item.priceListItemId)
    );
  };

  const updateItem = (item: QuoteItem, value: number) => {
    setItems((prev) =>
      prev.map((i) => {
        if (i.priceListItemId === item.priceListItemId) {
          return {
            ...i,
            discount: value,
          };
        }

        return i;
      })
    );
  };

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={12}>Presupuesto</CCol>
            </CRow>
          </CCardHeader>
          {!priceList ? (
            <CCardBody>
              <PriceListSelector
                onChange={setPriceList}
                priceList={priceList}
              />
            </CCardBody>
          ) : (
            <>
              <CCardBody>
                <CForm className="mb-3" onSubmit={formik.handleSubmit}>
                  <CRow>
                    <CFormLabel>
                      Lista de Precios: <strong>{priceList.name}</strong>
                    </CFormLabel>
                  </CRow>
                  <CRow>
                    <CCol sm={5}>
                      <CFormLabel htmlFor="customerId">Cliente</CFormLabel>
                      <SmartSelect
                        name="customerId"
                        search
                        type={SmartSelectType.Customers}
                        placeholder="Selecciona un cliente"
                        emptyLabel="No tienes clientes"
                        options={
                          customers?.data.data.map((c) => ({
                            value: c.id,
                            name: getCustomer(c),
                            genre: c.genre,
                          })) ?? []
                        }
                        onChange={(e) => {
                          const currentCustomer = customers?.data.data.find(
                            (c) => c.id === Number(e)
                          );

                          if (currentCustomer) {
                            setCustomer({
                              id: currentCustomer.id,
                              utid: currentCustomer.utid,
                            });
                          } else {
                            setCustomer({
                              id: -1,
                              utid: "",
                            });
                          }
                        }}
                        value={customer?.id?.toString()}
                      />
                    </CCol>
                    <CCol sm={3}>
                      <CFormLabel htmlFor="date">Fecha</CFormLabel>
                      <CFormInput
                        type="date"
                        name="date"
                        defaultValue={format(date, "yyyy-MM-dd")}
                        onChange={(e) => {
                          setDate(
                            parse(e.target.value, "yyyy-MM-dd", new Date())
                          );
                        }}
                      />
                    </CCol>
                    <CCol sm={3}>
                      <CFormLabel htmlFor="validUntil">
                        Fecha de Expiracion
                      </CFormLabel>
                      <CFormInput
                        type="date"
                        name="validUntil"
                        defaultValue={format(validUntil, "yyyy-MM-dd")}
                        onChange={(e) => {
                          setValidUntil(
                            parse(e.target.value, "yyyy-MM-dd", new Date())
                          );
                        }}
                      />
                    </CCol>
                  </CRow>
                  <CRow className="mt-3">
                    <CCol className="product-search" sm={5}>
                      <CFormLabel>Producto</CFormLabel>
                      <SmartSelect
                        name="productId"
                        search
                        placeholder="Selecciona un producto"
                        emptyLabel="No tienes productos stockeables"
                        options={products}
                        onChange={(e) => {
                          const priceListItem =
                            priceListItemList?.data.data.find(
                              (p) => p.id === e
                            );

                          if (priceListItem) {
                            formik.setValues({
                              productId: priceListItem.productId,
                              priceListItemId: priceListItem.id,
                              price: priceListItem.price,
                              product: {
                                name: priceListItem.name,
                              },
                              tax: priceListItem.tax,
                              quantity: formik.values.quantity,
                            });

                            quantityRef.current?.focus();
                          }
                        }}
                        value={formik.values.priceListItemId.toString()}
                      />
                    </CCol>
                    <CCol sm={2}>
                      <CFormLabel>Precio</CFormLabel>
                      <CFormInput
                        value={formik.values.price}
                        name="price"
                        type="number"
                        min={0}
                        onChange={formik.handleChange}
                      />
                    </CCol>
                    <CCol sm={2}>
                      <CFormLabel>Cantidad</CFormLabel>
                      <CFormInput
                        value={formik.values.quantity}
                        name="quantity"
                        ref={quantityRef}
                        min={1}
                        type="number"
                        onChange={formik.handleChange}
                      />
                    </CCol>
                    <CCol
                      sm={3}
                      className="d-flex align-items-end justify-content-end"
                    >
                      <CLoadingButton color="primary" type="submit" size="sm">
                        Agregar
                      </CLoadingButton>
                    </CCol>
                  </CRow>
                </CForm>

                <CSmartTable
                  itemsPerPage={items.length ?? 10}
                  items={items}
                  columns={columns}
                  noItemsLabel="Aun no has agregado items a este presupuesto"
                  footer={generateFooter()}
                  scopedColumns={{
                    product: (item: QuoteItem) => <td>{item.product.name}</td>,
                    quantity: (item: QuoteItem) => (
                      <td align="center">{item.quantity}</td>
                    ),
                    price: (item: QuoteItem) => (
                      <td align="right">{formatCurrency(item.price)}</td>
                    ),
                    subtotal: (item: QuoteItem) => (
                      <td align="right">
                        {formatCurrency(
                          removePercentageFromValue(item.price, item.discount) *
                            item.quantity
                        )}
                      </td>
                    ),
                    discount: (item: QuoteItem) => (
                      <td align="right" width="150">
                        <CFormInput
                          onChange={(e) => {
                            const value = Number(e.target.value);

                            updateItem(item, value);
                          }}
                          className="text-right"
                          max={100}
                          min={0}
                        />
                      </td>
                    ),
                    actions: (item: QuoteItem) => (
                      <td align="right">
                        <CTooltip content="Eliminar item">
                          <CButton
                            type="button"
                            size="sm"
                            color="danger"
                            onClick={() => deleteItem(item)}
                            className="d-flex align-items-center"
                          >
                            <TrashIcon size={18} />
                          </CButton>
                        </CTooltip>
                      </td>
                    ),
                  }}
                />
                <CRow>
                  <CCol sm={6}>
                    <CFormTextarea
                      style={{ height: 120, resize: "none" }}
                      name="notes"
                      label="Notas"
                      rows={6}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CRow>
                  <CCol className="d-flex justify-content-end">
                    <CLoadingButton
                      color="success"
                      loading={creating}
                      disabled={creating}
                      size="sm"
                      onClick={() => {
                        if (!creating) {
                          createMutation({
                            variables: {
                              input: {
                                date,
                                validUntil,
                                customer: {
                                  id: customer.id,
                                  utid: customer.utid ?? "",
                                },
                                notes,
                                storeId: priceList.store.id,
                                priceListId: priceList.id,
                                items: items.map((item) => ({
                                  productId: item.productId,
                                  priceListItemId: item.priceListItemId,
                                  quantity: item.quantity,
                                  price: item.price,
                                  tax: item.tax,
                                  discount: item.discount,
                                })),
                              },
                            },
                          });
                        }
                      }}
                    >
                      Crear
                    </CLoadingButton>
                  </CCol>
                </CRow>
              </CCardFooter>
            </>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default QuoteForm;
