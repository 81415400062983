import { useMutation, useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CCardFooter,
  CLoadingButton,
  CFormLabel,
  CButton,
  CTooltip,
  CBadge,
} from "@coreui/react-pro";
import { FieldArray, Form, Formik } from "formik";
import { TrashIcon } from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import Api from "src/api";
import { Product } from "src/api/products";
import {
  CreatePromotion,
  PromotionItem,
  PromotionsSchema,
} from "src/api/promotions";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import {
  calculateProfit,
  decimalNumber,
  formatCurrency,
  removePercentageFromValue,
} from "src/helpers/numbers";
import FeatureAlert from "src/containers/FeatureAlert";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";
import TotalsRow, { calculateTotals } from "./components/TotalsRow";
import { getValidity } from "src/helpers/validation";
import { startOfDay } from "date-fns/startOfDay";
import { Store, StoreType } from "src/api/stores";
import { sortBy } from "lodash";
import { dateFormat } from "src/helpers/dates";
import { add } from "date-fns/add";
import { useProductError } from "src/hooks/useProductError";
import {
  calculateFixedPriceDiscounts,
  usefulPromos,
} from "src/helpers/promotions";

const NewPromotion = () => {
  const { currentCompany, user } = useAdminStore();
  const navigate = useNavigate();
  const { handlePromoErrorMessage } = useProductError();

  const [createMutation, { loading: creating }] = useMutation(
    Api.Promotions.CREATE_PROMOTION,
    {
      onCompleted: ({ data }) => {
        navigate(`/promotions/${data.id}`);
      },
      onError: (error) => {
        handlePromoErrorMessage(error);
      },
    }
  );

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: { filters: { limit: 0, type: StoreType.Store } },
    }
  );

  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          limit: 0,
          trashed: 0,
        },
      },
    }
  );

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${
        product.barcodes.length > 0
          ? product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      }`,
      value: product.id,
    }));
  }, [productsList]);

  const storesList = useMemo(() => {
    if (!stores?.data.data) {
      return [];
    }

    return [
      { value: 0, name: "Todos" },
      ...sortBy(stores.data.data, "name").map((store) => ({
        name: user?.isAdmin
          ? `${store.name} (${store.company.name})`
          : store.name,
        value: store.id,
        type: store.type,
      })),
    ];
  }, [stores?.data.data, user?.isAdmin]);

  return (
    <>
      <FeatureAlert />

      <Formik<CreatePromotion>
        initialValues={{
          storeId: 0,
          companyId: currentCompany?.id ?? 0,
          name: "",
          barcode: "",
          initialPrice: 0,
          discount: 0,
          finalPrice: 0,
          dateFrom: startOfDay(new Date()),
          dateTo: null,
          items: [],
        }}
        validateOnChange={false}
        validationSchema={PromotionsSchema}
        isInitialValid={false}
        onSubmit={(values) => {
          if (!creating) {
            const input: CreatePromotion = {
              companyId: values.companyId,
              storeId: Number(values.storeId) !== 0 ? values.storeId : null,
              name: values.name,
              barcode: values.barcode,
              dateFrom: add(values.dateFrom, { hours: 3 }),
              items: [],
              ...calculateTotals(values.items),
            };

            if (values.dateTo) {
              input.dateTo = values.dateTo;
            }

            input.items.push(
              ...values.items.map((item) => ({
                productId: item.productId,
                productName: item.productName,
                initialPrice: Number(item.initialPrice),
                discount: Number(item.discount),
                finalPrice: Number(item.finalPrice),
              }))
            );

            createMutation({
              variables: { input },
            });
          }
        }}
      >
        {({
          handleChange,
          errors,
          handleSubmit,
          setFieldValue,
          isValid,
          values,
        }) => {
          const applyPromo = (
            config: (() => string | null) | Pick<PromotionItem, "discount">[]
          ) => {
            if (typeof config === "function") {
              const value = config();

              const items = calculateFixedPriceDiscounts(
                values.items,
                Number(value)
              );

              setFieldValue("items", items);
            } else {
              setFieldValue(
                "items",
                values.items.map((item, index) => ({
                  ...item,
                  discount: config[index].discount,
                  finalPrice: removePercentageFromValue(
                    item.initialPrice,
                    config[index].discount
                  ),
                }))
              );
            }
          };

          return (
            <Form>
              <CRow>
                <CCol xl={12}>
                  <CCard>
                    <CCardHeader>
                      <CRow className="align-items-center justify-content-center">
                        <CCol sm={12}>Nueva Promoción</CCol>
                      </CRow>
                    </CCardHeader>
                    <CCardBody>
                      <CForm>
                        <CRow>
                          <CCol sm={4}>
                            <CFormInput
                              onChange={handleChange}
                              name="name"
                              floatingLabel="Nombre"
                              {...getValidity(values.name, errors.name)}
                            />
                          </CCol>
                          <CCol sm={2}>
                            <CFormInput
                              onChange={handleChange}
                              name="dateFrom"
                              defaultValue={dateFormat(
                                values.dateFrom,
                                "yyyy-MM-dd"
                              )}
                              floatingLabel="Fecha Inicio"
                              type="date"
                            />
                          </CCol>
                          <CCol sm={2}>
                            <CFormInput
                              onChange={handleChange}
                              name="dateTo"
                              floatingLabel="Fecha Fin"
                              type="date"
                            />
                          </CCol>
                          <CCol sm={4}>
                            <CFormInput
                              onChange={handleChange}
                              name="barcode"
                              floatingLabel="Código de Barra"
                            />
                          </CCol>
                        </CRow>

                        <CRow className="mt-3">
                          <CCol sm={3}>
                            <CFormLabel>Punto de Venta</CFormLabel>
                            <SmartSelect
                              type={SmartSelectType.Stores}
                              options={storesList}
                              name="storeId"
                              placeholder="Selecciona Punto de Venta"
                              onChange={(e) => {
                                setFieldValue("storeId", Number(e));
                              }}
                              value={values.storeId?.toString()}
                            />
                          </CCol>
                        </CRow>

                        <CRow className="my-3">
                          <CCol className="product-search" sm={5}>
                            <CFormLabel>Agregar Producto</CFormLabel>
                            <SmartSelect
                              name="productId"
                              search
                              placeholder="Selecciona un producto"
                              emptyLabel="No tienes productos stockeables"
                              options={products}
                              value={"0"}
                              onChange={(e) => {
                                const product = productsList?.data.data.find(
                                  (p) => p.id === e
                                );

                                if (product) {
                                  setFieldValue("items", [
                                    ...values.items,
                                    {
                                      productId: product.id,
                                      productName: product.name,
                                      initialPrice: product.price,
                                      discount: 0,
                                      finalPrice: product.price,
                                    },
                                  ]);
                                }
                              }}
                            />
                          </CCol>

                          <CCol sm={5}>
                            <CFormLabel>Promociones comunes</CFormLabel>

                            <div className="d-flex gap-2 mt-2">
                              {usefulPromos.map((promo, index) => {
                                const disabled =
                                  values.items.length === 0 ||
                                  (promo.items &&
                                    values.items.length !== promo.items);

                                return (
                                  <CBadge
                                    onClick={() => {
                                      if (!disabled) {
                                        applyPromo(promo.config);
                                      }
                                    }}
                                    key={index}
                                    color={disabled ? "secondary" : "primary"}
                                  >
                                    {promo.label}
                                  </CBadge>
                                );
                              })}
                            </div>
                          </CCol>
                        </CRow>

                        {values.items.length > 0 && (
                          <>
                            <CRow>
                              <CCol>
                                <CFormLabel>Productos de la Promo</CFormLabel>
                              </CCol>
                            </CRow>

                            <CRow>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  marginBottom: "4px",
                                }}
                              >
                                <CFormInput
                                  className="plain-input-title"
                                  placeholder="Producto"
                                  readOnly
                                  disabled
                                />
                                <CFormInput
                                  className="plain-input-title"
                                  placeholder="Precio de Venta"
                                  readOnly
                                  disabled
                                />
                                <CFormInput
                                  className="plain-input-title"
                                  placeholder="Descuento (%)"
                                  readOnly
                                  disabled
                                />
                                <CFormInput
                                  className="plain-input-title"
                                  placeholder="Precio Final"
                                  readOnly
                                  disabled
                                />
                                <CButton
                                  type="button"
                                  size="sm"
                                  color="default"
                                  disabled
                                  className="plain-button"
                                >
                                  <TrashIcon size={18} color="transparent" />
                                </CButton>
                              </div>
                            </CRow>

                            <CRow>
                              <FieldArray name="items">
                                {({ remove }) => (
                                  <div>
                                    {values.items.length > 0 &&
                                      values.items.map((item, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <CFormInput
                                            name={`items[${index}].productId`}
                                            placeholder="Producto"
                                            readOnly
                                            disabled
                                            className="plain-input pl-0 mr-2"
                                            value={item.productName}
                                            required
                                          />
                                          <CFormInput
                                            name={`items[${index}].initialPrice`}
                                            readOnly
                                            disabled
                                            className="plain-input pl-0 mr-2"
                                            value={formatCurrency(
                                              item.initialPrice
                                            )}
                                            placeholder="Precio de Venta"
                                          />
                                          <CFormInput
                                            name={`items[${index}].discount`}
                                            type="number"
                                            placeholder="Descuento (%)"
                                            value={item.discount}
                                            onChange={(e) => {
                                              const val = decimalNumber(
                                                e.target.value,
                                                100
                                              );

                                              if (val === null) {
                                                setFieldValue(
                                                  `items[${index}].finalPrice`,
                                                  item.initialPrice
                                                );
                                                return;
                                              }

                                              setFieldValue(
                                                `items[${index}].discount`,
                                                val
                                              );
                                              setFieldValue(
                                                `items[${index}].finalPrice`,
                                                removePercentageFromValue(
                                                  item.initialPrice,
                                                  Number(val)
                                                )
                                              );
                                            }}
                                            min="0"
                                            max="100"
                                          />
                                          <CFormInput
                                            name={`items[${index}].finalPrice`}
                                            type="number"
                                            placeholder="Precio Final"
                                            min="0"
                                            value={item.finalPrice}
                                            onChange={(e) => {
                                              const val = decimalNumber(
                                                e.target.value,
                                                item.initialPrice
                                              );

                                              if (val === null) {
                                                setFieldValue(
                                                  `items[${index}].discount`,
                                                  0
                                                );
                                                return;
                                              }

                                              setFieldValue(
                                                `items[${index}].finalPrice`,
                                                val
                                              );
                                              setFieldValue(
                                                `items[${index}].discount`,
                                                Math.abs(
                                                  calculateProfit(
                                                    item.initialPrice,
                                                    Number(val)
                                                  )
                                                )
                                              );
                                            }}
                                          />

                                          <CTooltip content="Eliminar item">
                                            <CButton
                                              type="button"
                                              size="sm"
                                              color="danger"
                                              onClick={() => remove(index)}
                                              className="d-flex align-items-center"
                                            >
                                              <TrashIcon size={18} />
                                            </CButton>
                                          </CTooltip>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </FieldArray>
                            </CRow>

                            <TotalsRow items={values.items} />
                          </>
                        )}
                      </CForm>
                    </CCardBody>
                    <CCardFooter>
                      <CRow>
                        <CCol sm={12} className="text-right">
                          <CLoadingButton
                            type="submit"
                            size="sm"
                            loading={creating}
                            disabled={creating || !isValid}
                            onClick={handleSubmit}
                            className="btn btn-primary"
                          >
                            Crear
                          </CLoadingButton>
                        </CCol>
                      </CRow>
                    </CCardFooter>
                  </CCard>
                </CCol>
              </CRow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default NewPromotion;
