import { useMutation, useQuery } from "@apollo/client";
import {
  CCol,
  CForm,
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CCardFooter,
  CButton,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import Api from "src/api";
import { Company } from "src/api/companies";
import { PermissionGroup, UpdatePermissionGroup } from "src/api/permissions";
import { logEvent } from "src/helpers/analytics";
import { getValidity } from "src/helpers/validation";
import { GraphQLFind, GraphQLMeta } from "src/types";
import CompanyPermissions from "./components/CompanyPermissions";
import StorePermissions from "./components/StorePermissions";

const EditGroup = () => {
  const params = useParams();
  const navigate = useNavigate();
  const groupId = Number(params.id);

  if (!groupId) {
    navigate("/groups");
  }

  const { data: group } = useQuery<GraphQLFind<PermissionGroup>>(
    Api.Permissions.GET_GROUP,
    {
      variables: {
        id: groupId,
      },
    }
  );
  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  if (!group?.data || !companies?.data.data) {
    return null;
  }

  return <Form group={group.data} companies={companies.data.data} />;
};

const Form = ({
  group,
  companies,
}: {
  group: PermissionGroup;
  companies: Company[];
}) => {
  const [mutation, { loading }] = useMutation(Api.Permissions.UPDATE_GROUP, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const formik = useFormik<UpdatePermissionGroup>({
    initialValues: {
      ...group,
      permissions: group.permissions.map((perm) => perm.key),
    },
    onSubmit: (data) => {
      if (!loading) {
        const input = {
          name: data.name,
          companyId: data.companyId,
          permissions: data.permissions,
        };

        logEvent("permissions-group.update", {
          input,
          group,
        });

        mutation({
          variables: {
            id: group.id,
            input,
          },
        });
      }
    },
  });

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Editar Grupo de Permisos: {group.name}</CCardHeader>
          <CCardBody>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  defaultValue={formik.values.name}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="companyId">Empresa</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  disabled
                  id="companyId"
                  name="companyId"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.companyId}
                  {...getValidity(
                    formik.values.companyId,
                    formik.errors.companyId
                  )}
                >
                  <option key="0" value="0">
                    Seleccione Empresa
                  </option>
                  {companies.map((company: Company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="mt-3 mt-3">
              <CFormLabel>Permisos de Empresa</CFormLabel>
              <CompanyPermissions formik={formik} />
            </CRow>
            <CRow className="mt-3 mt-3">
              <CFormLabel>Permisos de Punto de Venta</CFormLabel>
              <StorePermissions formik={formik} />
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className="px-0">
              <CCol md="9"></CCol>
              <CCol md="3" className="flex justify-content-end">
                <CButton
                  color="primary"
                  size="sm"
                  disabled={loading || !formik.isValid}
                  type="submit"
                >
                  Editar
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default EditGroup;
