import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CMultiSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Company } from "src/api/companies";
import { Store, StoreSchema } from "src/api/stores";
import { useNavigate } from "react-router";
import { useFormik } from "formik";

import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { useAdminStore } from "src/store";
import { WarehouseCreate } from "src/api/warehouses";
import { logEvent } from "src/helpers/analytics";

const NewWarehouse = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAdminStore();

  if (!currentCompany?.id) {
    navigate("/");
  }

  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      variables: {
        id: currentCompany?.id,
      },
    }
  );

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          companyId: currentCompany?.id,
          withWarehouse: false,
        },
      },
    }
  );

  if (!company?.data || !stores?.data.data) {
    return null;
  }

  return (
    <CCol lg={12}>
      <Form company={company.data} stores={stores.data.data} />
    </CCol>
  );
};

const Form = ({ company, stores }: { company: Company; stores: Store[] }) => {
  const navigate = useNavigate();

  const [mutation, { loading }] = useMutation(Api.Warehouses.CREATE_WAREHOUSE, {
    onCompleted: ({ data: warehouse }) => {
      navigate(`/warehouses/${warehouse.id}`);
    },
  });

  const formik = useFormik<WarehouseCreate>({
    initialValues: {
      companyId: company.id,
      name: "",
      streetName: "",
      streetNumber: "",
      phone: "",
      notes: "",
      stores: [],
    },
    onSubmit: (data) => {
      if (!loading) {
        const input = {
          ...data,
        };

        logEvent("warehouses.create", {
          data: input,
        });

        mutation({
          variables: {
            input: data,
          },
        });
      }
    },
    validationSchema: StoreSchema,
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Nuevo Depósito</CCardHeader>
        <CCardBody>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel>Empresa</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{company.name}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="stores">Puntos de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CMultiSelect
                id="stores"
                options={stores.map((store) => ({
                  value: store.id,
                  text: store.name,
                  label: store.name,
                  selected: formik.values.stores.includes(store.id),
                }))}
                placeholder="Puntos de Venta"
                onChange={(e) => {
                  formik.setFieldValue(
                    "stores",
                    e.map(({ value }) => value)
                  );
                }}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="streetName">Calle</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetName"
                placeholder="Calle"
                name="streetName"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetName,
                  formik.errors.streetName
                )}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetNumber"
                placeholder="Altura"
                name="streetNumber"
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetNumber,
                  formik.errors.streetNumber
                )}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="phone">Teléfono</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="phone"
                placeholder="Teléfono"
                name="phone"
                onChange={formik.handleChange}
                {...getValidity(formik.values.phone, formik.errors.phone)}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md="3">
              <CFormLabel htmlFor="notes">Notas</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="notes"
                placeholder="Notas"
                name="notes"
                onChange={formik.handleChange}
                {...getValidity(formik.values.notes, formik.errors.notes)}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="6"></CCol>
            <CCol md="6" className="flex justify-content-end">
              <CLoadingButton
                size="sm"
                color="primary"
                loading={loading}
                disabled={loading || !formik.isValid}
                type="submit"
              >
                Crear
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default NewWarehouse;
