import { useNavigate } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { Store } from "src/api/stores";
import { findPointOfSale } from "src/helpers/stores";
import FeatureAlert from "src/containers/FeatureAlert";
import { useAdminStore } from "src/store";
import Pagination from "src/components/Pagination";
import { getCompanyPaymentStatus } from "src/helpers/payments";
import { PaymentStatus } from "src/api/companies";

const StoresScreen = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAdminStore();
  const { page, pageChange } = usePagination("stores");
  const { data: stores, loading } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
          permission: "LIST_STORES",
        },
      },
    }
  );

  const columns = [
    { key: "icon", label: "", _props: { className: "w-2" } },
    {
      key: "name",
      label: "Nombre",
      _props: { className: "font-weight-bold" },
    },
    { key: "address", label: "Dirección" },
    { key: "phone", label: "Teléfono" },
  ];

  if (stores?.data.data[0].company.isFranchise) {
    columns.push({ key: "utid", label: "UTID" });
  }

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  return (
    <>
      <FeatureAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>Puntos de Venta</CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={stores?.data.data.length ?? 20}
                loading={loading}
                items={stores?.data.data || []}
                columns={columns}
                scopedColumns={{
                  address: (item: Store) => (
                    <td>
                      {item.streetName} {item.streetNumber}, {item.city},{" "}
                      {item.city}
                    </td>
                  ),
                  icon: (item: Store) => {
                    const currentType = findPointOfSale(item.type);
                    const StoreIcon = () => currentType?.icon;

                    return (
                      <td align="center">
                        <StoreIcon />
                      </td>
                    );
                  },
                  utid: (item: Store) => <td>{item.utid}</td>,
                }}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows
                onRowClick={(item) => {
                  if (!isDebtor) {
                    navigate(`/stores/${item.id}`);
                  }
                }}
              />

              <Pagination meta={stores} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default StoresScreen;
