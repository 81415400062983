import { Store } from "./stores";
import { User } from "./users";
import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { PaymentMethod } from "./sales";
import request from "./request";

export const RegisterSchema = yup.object().shape({
  storeId: yup.string().integer().required(),
  name: yup.string().required(),
});

export type Balance = {
  type: PaymentMethod;
  amount: number;
};

export type CashRegisterConfig = {
  invoiceTypes: {
    id: number;
    name: string;
  }[];
  autoInvoicePaymentMethods: string[];
  paymentMethods: string[];
  defaultPaymentMethod: string;
  postSaleAction: string;
  acceptDiscounts: number;
  autoInvoice: number;
  defaultDiscount: number;
  printerPaperSize: number;
  transactionTypes: number[];
  granelBarcode: string;
};

export type CashRegisterMetadata = {
  mercadoPagoRegisterId: number;
  mercadoPagoQR: {
    image: string;
    templatePdf: string;
    templateImage: string;
  };
};

export type CashRegister = {
  id: number;
  storeId: number;
  name: string;
  savedBalance: number;
  description: string | null;
  config: CashRegisterConfig;
  store: Store;
  sessions?: CashRegisterSession[];
  session?: CashRegisterSession;
  metadata?: CashRegisterMetadata;
};

export enum TransactionKind {
  AddMoney = "AddMoney",
  Sell = "Sell",
  WithdrawMoney = "WithdrawMoney",
  Unknown = "Unknown",
  RescueMoney = "RescueMoney",
}

export type CashTransactionType = {
  id: number;
  companyId?: number;
  key: string;
  name: string;
  type: TransactionKind;
};

export type CashRegisterSession = {
  id: number;
  closedAt: string | null;
  openedAt: string;
  user: User;
  register: CashRegister;
  initialAmount: number;
};

export type CashRegisterCreate = Pick<
  CashRegister,
  "storeId" | "name" | "description"
>;

export type CashRegisterUpdate = Pick<CashRegister, "name" | "description">;

export const LIST_REGISTERS = gql`
  query registers($filters: CashRegisterFilters) {
    data: registers(filters: $filters) {
      data {
        id
        name
        store {
          id
          name
          streetName
          streetNumber
          companyId
          type

          metadata {
            mercadoPagoPOSId
          }

          config {
            invoiceTypes {
              id
              name
              anonymous
            }
          }
        }

        metadata {
          mercadoPagoRegisterId
          mercadoPagoQR {
            image
            templatePdf
          }
        }
        transactions {
          type
          amount
        }
        session {
          id
          openedAt
          closedAt
          user {
            name
            lastname
          }
        }
        config {
          invoiceTypes {
            id
            name
            anonymous
          }
          posId
          defaultPaymentMethod
          paymentMethods
          postSaleAction
          acceptDiscounts
          autoInvoice
          autoInvoicePaymentMethods
          defaultDiscount
          printerPaperSize
        }
        sessions {
          id
          openedAt
          closedAt
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const LIST_FLAT_REGISTERS = gql`
  query getFlatCashRegisters($filters: CashRegisterFilters) {
    data: registers(filters: $filters) {
      data {
        id
        name
        store {
          id
          name
          streetName
          streetNumber
          companyId

          metadata {
            mercadoPagoPOSId
          }

          config {
            invoiceTypes {
              id
              name
              anonymous
            }
          }
        }
        metadata {
          mercadoPagoRegisterId
          mercadoPagoQR {
            image
            templatePdf
          }
        }
        config {
          invoiceTypes {
            id
            name
            anonymous
          }
          posId
          defaultPaymentMethod
          paymentMethods
          postSaleAction
          acceptDiscounts
          autoInvoice
          autoInvoicePaymentMethods
          defaultDiscount
          printerPaperSize
          transactionTypes
          granelBarcode
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_REGISTER = gql`
  query getCashRegister($id: Int!) {
    data: register(id: $id) {
      id
      name
      description
      savedBalance
      store {
        name
        type
      }
      transactions {
        type
        amount
      }
      sessions {
        id
        initialAmount
        openedAt
        closedAt
        user {
          name
          lastname
        }
      }
      session {
        id
        openedAt
        closedAt

        user {
          name
          lastname
        }
      }
    }
  }
`;

export const LIST_TRANSACTION_TYPES = gql`
  query transactionTypes($filters: TransactionTypeFilters) {
    data: transactionTypes(filters: $filters) {
      data {
        id
        name
        type
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const CREATE_REGISTER = gql`
  mutation createRegister(
    $storeId: Int!
    $name: String!
    $description: String
  ) {
    data: createRegister(
      storeId: $storeId
      name: $name
      description: $description
    ) {
      id
    }
  }
`;

export const UPDATE_REGISTER = gql`
  mutation updateRegister($id: Int!, $name: String!, $description: String) {
    data: updateRegister(id: $id, name: $name, description: $description) {
      id
    }
  }
`;

export const DELETE_REGISTER = gql`
  mutation deleteRegister($id: Int!) {
    data: deleteRegister(id: $id) {
      id
    }
  }
`;

export const CLOSE_REGISTER = gql`
  mutation closeRegister($sessionId: Int!, $registerId: Int!) {
    data: closeRegister(sessionId: $sessionId, registerId: $registerId) {
      id
    }
  }
`;

export const GET_BALANCE = gql`
  query balance($registerId: Int!, $sessionId: Int!) {
    data: balance(registerId: $registerId, sessionId: $sessionId) {
      type
      amount
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation updateRegisterConfig($id: Int!, $input: UpdateRegisterConfigInput!) {
    data: updateRegisterConfig(id: $id, input: $input) {
      id
    }
  }
`;

export const CREATE_TRANSACTION_TYPE = gql`
  mutation createTransactionType($input: TransactionTypeInput!) {
    data: createTransactionType(input: $input) {
      id
    }
  }
`;

export const downloadReport = (registerId: number, sessionId: number) => {
  return request.post(
    `/registers/${registerId}/sessions/${sessionId}/report`,
    { type: "full" },
    {
      responseType: "blob",
    }
  );
};
