import { useMutation } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CFormCheck,
  CFormLabel,
  CCardFooter,
  CLoadingButton,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import { useNavigate } from "react-router";
import Api from "src/api";
import { CreateBankPromotion } from "src/api/bankPromotions";

import FeatureAlert from "src/containers/FeatureAlert";
import { dateFormat } from "src/helpers/dates";
import { paymentMethods } from "src/helpers/payments";

const NewBankPromotion = () => {
  const navigate = useNavigate();
  const [createMutation, { loading: creating }] = useMutation(
    Api.BankPromotions.CREATE_BANK_PROMOTION,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const formik = useFormik<CreateBankPromotion>({
    initialValues: {
      name: "",
      discount: 0,
      dateFrom: undefined,
      dateTo: undefined,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      paymentMethods: [],
    },
    onSubmit: (values) => {
      if (!creating) {
        const input = {
          ...values,
          dateFrom: values.dateFrom ? dateFormat(values.dateFrom) : undefined,
          dateTo: values.dateTo ? dateFormat(values.dateTo) : undefined,
        };

        createMutation({
          variables: {
            input,
          },
        });
      }
    },
  });

  return (
    <>
      <FeatureAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={12}>Nueva Promoción Bancaria</CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm>
                <CRow>
                  <CCol sm={3}>
                    <CFormInput
                      onChange={formik.handleChange}
                      name="name"
                      floatingLabel="Nombre"
                    />
                  </CCol>
                  <CCol sm={2}>
                    <CFormInput
                      onChange={formik.handleChange}
                      name="discount"
                      floatingLabel="Descuento (%)"
                      type="number"
                    />
                  </CCol>
                  <CCol sm={3}>
                    <CFormInput
                      onChange={formik.handleChange}
                      name="dateFrom"
                      floatingLabel="Fecha Inicio"
                      type="date"
                    />
                  </CCol>
                  <CCol sm={3}>
                    <CFormInput
                      onChange={formik.handleChange}
                      name="dateTo"
                      floatingLabel="Fecha Fin"
                      type="date"
                    />
                  </CCol>
                </CRow>
                <CRow className="mt-3">
                  <CCol sm={12}>
                    <CFormLabel>Días</CFormLabel>
                    <br />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="monday"
                      inline
                      checked={formik.values.monday}
                      label="Lunes"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="tuesday"
                      inline
                      checked={formik.values.tuesday}
                      label="Martes"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="wednesday"
                      inline
                      checked={formik.values.wednesday}
                      label="Miercoles"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="thursday"
                      inline
                      checked={formik.values.thursday}
                      label="Jueves"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="friday"
                      inline
                      checked={formik.values.friday}
                      label="Viernes"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="saturday"
                      inline
                      checked={formik.values.saturday}
                      label="Sabado"
                    />
                    <CFormCheck
                      onChange={formik.handleChange}
                      name="sunday"
                      inline
                      checked={formik.values.sunday}
                      label="Domingo"
                    />
                  </CCol>
                </CRow>
                <CRow className="mt-3">
                  <CCol md="12">
                    <CFormLabel>Métodos de Pago</CFormLabel>
                    <br />
                    {sortBy(
                      paymentMethods.filter(
                        ({ id, digital }) => id > 0 && !digital
                      ),
                      "name"
                    ).map((paymentMethod) => (
                      <CFormCheck
                        inline
                        key={paymentMethod.id}
                        id={`paymentMethod-${paymentMethod.id}`}
                        type="checkbox"
                        name="paymentMethods"
                        value={paymentMethod.identifier}
                        label={paymentMethod.name}
                        defaultChecked={formik.values?.paymentMethods.includes(
                          paymentMethod.identifier
                        )}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            formik.setFieldValue("paymentMethods", [
                              ...formik.values.paymentMethods,
                              e.currentTarget.value,
                            ]);
                          } else {
                            formik.setFieldValue(
                              "paymentMethods",
                              formik.values.paymentMethods.filter(
                                (pm) => pm !== e.currentTarget.value
                              )
                            );
                          }
                        }}
                      />
                    ))}
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CRow>
                <CCol sm={12} className="text-right">
                  <CLoadingButton
                    type="button"
                    size="sm"
                    loading={creating}
                    disabled={creating}
                    onClick={formik.handleSubmit}
                    className="btn btn-primary"
                  >
                    Guardar
                  </CLoadingButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default NewBankPromotion;
