import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router";
import { Product } from "src/api/products";
import { ApolloErrorWithData } from "src/types";

export type ErrorMessageOptions = {
  confirmDialogRef?: React.MutableRefObject<any>;
};

export const useProductError = () => {
  const navigate = useNavigate();

  const handleProductErrorMessage = (
    error: ApolloError,
    options?: ErrorMessageOptions
  ) => {
    const { graphQLErrors, message } = error as ApolloErrorWithData<{
      product: Product;
    }>;

    const errorProduct = graphQLErrors?.[0]?.data?.product;

    if (message === "product_already_exists") {
      options?.confirmDialogRef?.current?.open({
        id: errorProduct.id,
      });
    } else if (message === "barcode_already_exists") {
      if (
        window.confirm(
          "El código de barra ya esta en uso en otro producto. Quieres ir a ese producto?"
        )
      ) {
        navigate(`/products/${errorProduct.id}`, {
          state: { trashed: true },
        });
      }
    } else if (message === "sku_already_exists") {
      if (
        window.confirm(
          "El SKU ya esta en uso en otro producto. Quieres ir a ese producto?"
        )
      ) {
        navigate(`/products/${errorProduct.id}`, {
          state: { trashed: true },
        });
      }
    } else if (message === "fraction_already_exists") {
      window.alert("Este producto ya tiene esa fracción.");
    }
  };

  const handlePromoErrorMessage = (error: ApolloError) => {
    const { message } = error as ApolloErrorWithData<{
      product: Product;
    }>;

    if (
      message === "barcode_already_exists" ||
      message === "barcode_exists_in_promo"
    ) {
      return alert("El código de barra ya esta en uso en un producto.");
    }
  };

  return { handleProductErrorMessage, handlePromoErrorMessage };
};
