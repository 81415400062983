import { createContext, PropsWithChildren, useEffect, useRef } from "react";
import newSocketClient, {
  ClientToServerEvents,
  ServerToClientEvents,
} from "src/services/socket";
import { Socket } from "socket.io-client";

import { UserWithToken } from "src/types";
import Config from "src/config";

type SocketContextProps = {
  client: Socket<ServerToClientEvents, ClientToServerEvents> | null;
};

export const SocketContext = createContext<Partial<SocketContextProps>>({});

export const SocketProvider = ({
  children,
  user,
}: PropsWithChildren<{ user: UserWithToken }>) => {
  const refClient = useRef<Socket<
    ServerToClientEvents,
    ClientToServerEvents
  > | null>(null);

  useEffect(() => {
    if (Config.socketEnabled) {
      if (!refClient.current) {
        refClient.current = newSocketClient(user);
      }
    }

    return () => {
      if (refClient.current) {
        refClient.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider value={{ client: refClient.current }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
