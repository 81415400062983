import { useLazyQuery, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react-pro";
import { useRef } from "react";
import { Link, useNavigate } from "react-router";
import Api from "src/api";
import { Category, CategoryStock } from "src/api/categories";
import Pagination from "src/components/Pagination/Pagination";
import PlanAlert from "src/containers/PlanAlert";
import { logEvent } from "src/helpers/analytics";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";
import ProductsConfigModal, {
  ProductsConfigModalForwardedRef,
} from "../products/components/ProductsConfigModal";
import { Cog, FileDown, Loader, ShoppingCart } from "lucide-react";
import writeXlsxFile from "write-excel-file";
import { truncateString } from "src/helpers/strings";
import { formatCurrency } from "src/helpers/numbers";

const CategoriesScreen = () => {
  const configModalRef = useRef<ProductsConfigModalForwardedRef>(null);
  const navigate = useNavigate();
  const { user } = useAdminStore();
  const { page, pageChange } = usePagination("categories");
  const { data: categories, loading } = useQuery<GraphQLMeta<Category>>(
    Api.Categories.LIST_CATEGORIES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );

  const [fetchStock, { loading: fetching }] = useLazyQuery(
    Api.Categories.GET_CATEGORY_STOCK,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        downloadStock(data);
      },
    }
  );

  const downloadStock = (warehouseStock: CategoryStock[]) => {
    const excelData: any[][] = [[]];

    warehouseStock.forEach(({ products, category }, index) => {
      const excelSheet: any[] = [];

      excelSheet.push([
        { value: "Producto" },
        { value: "Categoría" },
        { value: "Cantidad" },
        { value: "Costo por Unidad" },
        { value: "Costo Total" },
        { value: "Importe por Unidad" },
        { value: "Importe Total" },
      ]);

      if (products.length === 0) {
        excelData[index] = excelSheet;

        return;
      }

      products.forEach((product) => {
        excelSheet.push([
          {
            value: product.name,
          },
          {
            value: category.name,
          },
          {
            value: product.stock,
          },
          {
            value: product.cost,
          },
          {
            value: product.cost * product.stock,
          },
          {
            value: product.price,
          },
          {
            value: product.price * product.stock,
          },
        ]);
      });

      const { stock, cost, price } = products.reduce(
        (prev, curr) => {
          return {
            ...prev,
            stock: prev.stock + curr.stock,
            cost: prev.cost + curr.cost * curr.stock,
            price: prev.price + curr.price * curr.stock,
          };
        },
        {
          stock: 0,
          cost: 0,
          price: 0,
        }
      );

      excelSheet.push([
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: stock,
        },
        {
          value: "",
        },
        {
          value: formatCurrency(cost),
        },
        {
          value: "",
        },
        {
          value: formatCurrency(price),
        },
      ]);

      excelData[index] = excelSheet;
    });

    const reportName = `Stock Valorizado de la Categoría ${warehouseStock[0].category.name}`;

    writeXlsxFile(excelData, {
      sheets: warehouseStock.map(
        ({ warehouse, products }) =>
          `${truncateString(warehouse.name.replaceAll("/", "-"), 20)} (${
            products.length
          })`
      ),
      fileName: `${reportName}.xlsx`,
    });
  };

  const fields = [
    { key: "id", label: "ID" },
    { key: "name", label: "Nombre", className: "font-weight-bold" },
    { key: "identifier", label: "Identificador" },
    { key: "count", label: "# de Productos" },
    { key: "notes", label: "Notas" },
    { key: "actions", label: "Acciones", _props: { className: "text-right" } },
  ];

  if (user?.isAdmin) {
    fields.push({ key: "company", label: "Empresa" });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={9} xs="6" className="px-0">
                  Categorías
                </CCol>
                <CCol sm={3} xs="6" className="row justify-content-end">
                  <Link
                    to="/categories/new"
                    className="flex justify-content-end p-0"
                  >
                    <CButton size="sm" color="primary">
                      Crear Categoría
                    </CButton>
                  </Link>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={categories?.data.data || []}
                columns={fields}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  company: (item: Category) => <td>{item.company.name}</td>,
                  notes: (item: Category) => <td>{item.notes}</td>,
                  count: (item: Category) => {
                    const productsCount = item.products.length;

                    return (
                      <td>
                        {productsCount} producto
                        {productsCount === 0 || productsCount > 1 ? "s" : ""}
                      </td>
                    );
                  },
                  actions: (item: Category) => (
                    <td className="text-right">
                      <CDropdown variant="btn-group" alignment="end">
                        <CButton
                          type="button"
                          color="info"
                          size="sm"
                          onClick={() => {
                            navigate(`/categories/${item.id}`);
                          }}
                        >
                          Editar Categoría
                        </CButton>

                        <CDropdownToggle color="info" size="sm" split />

                        <CDropdownMenu>
                          <CDropdownItem
                            as="button"
                            onClick={() => {
                              logEvent("categories.navigate", {
                                date: item,
                              });

                              navigate(`/categories/${item.id}/products`);
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <ShoppingCart size={18} />
                              Ver productos
                            </div>
                          </CDropdownItem>

                          <CDropdownItem
                            as="button"
                            onClick={(e) => {
                              configModalRef.current?.open(
                                item.id,
                                item.name,
                                "category"
                              );
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              <Cog size={18} />
                              Configurar productos
                            </div>
                          </CDropdownItem>

                          <CDropdownItem
                            as="button"
                            onClick={() => {
                              if (!fetching) {
                                fetchStock({
                                  variables: {
                                    categoryId: item.id,
                                  },
                                });
                              }
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center justify-content-between">
                              {fetching ? (
                                <Loader size={18} />
                              ) : (
                                <FileDown size={18} />
                              )}
                              Stock Valorizado
                            </div>
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </td>
                  ),
                }}
              />

              <Pagination
                meta={categories}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <ProductsConfigModal ref={configModalRef} />
    </>
  );
};

export default CategoriesScreen;
