import { QuoteStatus } from "src/api/quotes";

export const parseQuoteStatus = (status: QuoteStatus) => {
  switch (status) {
    case QuoteStatus.Accepted:
      return { label: "Aceptado", color: "success" };

    case QuoteStatus.Sent:
      return { label: "Enviado a cliente", color: "warning" };

    case QuoteStatus.SaleCreated:
      return { label: "Venta creada", color: "info" };

    case QuoteStatus.Rejected:
      return { label: "Rechazado", color: "danger" };

    case QuoteStatus.Cancelled:
      return { label: "Cancelado", color: "dark" };

    case QuoteStatus.InProgress:
    default:
      return { label: "Abierto", color: "primary" };
  }
};
