import { CRow, CCol, CWidgetStatsA } from "@coreui/react-pro";
import { memo } from "react";
import { Summary } from "src/api/dashboard";
import { CashRegisterAction } from "src/api/sales";
import { formatCurrency } from "src/helpers/numbers";
import { useAdminStore } from "src/store";

const WidgetsDropdown = ({
  checkingAccount = 0,
  avgTicket = 0,
  money,
  customers = 0,
  profit = 0,
  incomes = 0,
  categories = 0,
  suppliers = 0,
  sales = 0,
  multiplier = 1,
}: Partial<
  Pick<
    Summary,
    | "money"
    | "profit"
    | "customers"
    | "incomes"
    | "sales"
    | "categories"
    | "suppliers"
    | "avgTicket"
  > & { multiplier: number; checkingAccount: number }
>) => {
  const { hasPermission } = useAdminStore();
  const canSeeProducts = hasPermission("LIST_PRODUCTS");
  const canSeeSales = hasPermission("LIST_SALES");
  const canSeeCustomers = hasPermission("LIST_CLIENTS");

  const addMoney =
    money?.find((m) => m.type === CashRegisterAction.AddMoney)?.amount ?? 0;
  const withdrawMoney =
    money?.find((m) => m.type === CashRegisterAction.WithdrawMoney)?.amount ??
    0;

  const sizing = {
    xs: 6 * multiplier,
    sm: 6 * multiplier,
    lg: 3 * multiplier,
    xl: 2 * multiplier,
  };

  return (
    <CRow className="flex-1">
      {customers > 0 && canSeeCustomers && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="primary"
            value={customers.toString()}
            title="Clientes nuevos este año"
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {suppliers > 0 && canSeeProducts && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="primary"
            value={suppliers.toString()}
            title={suppliers === 1 ? "Proveedor" : "Proveedores"}
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {categories > 0 && canSeeProducts && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="warning"
            value={categories.toString()}
            title={categories === 1 ? "Categoría" : "Categorías"}
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {sales > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="warning"
            value={sales.toString()}
            title={sales === 1 ? "Venta" : "Ventas"}
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {incomes > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="danger"
            value={formatCurrency(incomes, true)}
            title={`Ingresos ${checkingAccount > 0 ? " en Pago" : ""}`}
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {checkingAccount > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="secondary"
            value={formatCurrency(checkingAccount)}
            title="Cuenta Corriente"
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {(profit < 0 || profit > 0) && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="primary"
            value={formatCurrency(profit, true)}
            title="Ganancias"
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {avgTicket > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="info"
            value={formatCurrency(avgTicket)}
            title="Ticket medio"
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {addMoney > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="success"
            value={formatCurrency(addMoney, true)}
            title="Ingreso de Efectivo"
            className="pb-2 padding-2"
          />
        </CCol>
      )}

      {withdrawMoney > 0 && canSeeSales && (
        <CCol className="mb-2" {...sizing}>
          <CWidgetStatsA
            color="secondary"
            value={formatCurrency(withdrawMoney, true)}
            title="Retiro de Efectivo"
            className="pb-2 padding-2"
          />
        </CCol>
      )}
    </CRow>
  );
};

export default memo(WidgetsDropdown);
