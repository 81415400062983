import { useLazyQuery } from "@apollo/client";
import { CCol, CFormLabel, CLoadingButton, CRow } from "@coreui/react-pro";
import { useState } from "react";
import Api from "src/api";
import { PaymentMethod } from "src/api/sales";
import RangeAndStorePicker from "src/components/RangeAndStorePicker";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { dateFormat } from "src/helpers/dates";
import { paymentMethods } from "src/helpers/payments";
import {
  FilterValues,
  generateSalesReport,
  SellsByEntity,
} from "src/helpers/reports";

const SalesReport = () => {
  const [filterValues, setFilterValues] = useState<FilterValues>({
    dateFrom: dateFormat(new Date(), "yyyy-MM-dd"),
    dateTo: dateFormat(new Date(), "yyyy-MM-dd"),
    paymentMethod: undefined,
  });
  const [fetchCategoryReport, { loading: loadingCategory }] = useLazyQuery<{
    data: SellsByEntity[];
  }>(Api.Excels.SELLS_BY_CATEGORY_AND_DATE, {
    onCompleted: ({ data }) => {
      generateSalesReport(data, filterValues);
    },
    fetchPolicy: "no-cache",
  });

  const [fetchSupplierReport, { loading: loadingSupplier }] = useLazyQuery<{
    data: SellsByEntity[];
  }>(Api.Excels.SELLS_BY_SUPPLIER_AND_DATE, {
    onCompleted: ({ data }) => {
      generateSalesReport(data, filterValues);
    },
    fetchPolicy: "no-cache",
  });

  const loading = loadingCategory || loadingSupplier;

  return (
    <>
      <RangeAndStorePicker
        refetch={({ variables }: { variables: FilterValues }) => {
          setFilterValues(variables);
        }}
        defaultLabel="Seleccione Punto de Venta"
        eventTitle="excels.sell-products.filter"
      >
        <CCol sm={3}>
          <CFormLabel>
            <strong>Método de Pago</strong>
          </CFormLabel>
          <SmartSelect
            type={SmartSelectType.PaymentMethods}
            name="paymentMethod"
            value={filterValues.paymentMethod}
            options={[
              { value: 0, name: "Todos" },
              ...(paymentMethods
                ?.filter(({ id, digital }) => !digital && id > 0)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((paymentMethod) => ({
                  value: paymentMethod.type,
                  name: paymentMethod.name,
                  type: paymentMethod.type,
                })) ?? []),
            ]}
            onChange={(e) => {
              const paymentMethod = e;

              setFilterValues((prev) => ({
                ...prev,
                paymentMethod:
                  paymentMethod !== ""
                    ? (paymentMethod as PaymentMethod)
                    : undefined,
              }));
            }}
          />
        </CCol>
      </RangeAndStorePicker>

      <CRow className="mt-3">
        <CCol className="text-right" sm={12}>
          <CLoadingButton
            color="primary"
            onClick={() => {
              fetchCategoryReport({
                variables: {
                  filters: {
                    dateFrom: filterValues.dateFrom,
                    dateTo: filterValues.dateTo,
                    storeId: filterValues.storeId,
                    paymentMethod: filterValues.paymentMethod,
                  },
                },
              });
            }}
            loading={loadingCategory}
            size="sm"
            disabled={filterValues.storeId === undefined || loading}
          >
            Descargar Reporte por Categoría
          </CLoadingButton>

          <CLoadingButton
            color="info"
            className="ml-2"
            onClick={() => {
              fetchSupplierReport({
                variables: {
                  filters: {
                    dateFrom: filterValues.dateFrom,
                    dateTo: filterValues.dateTo,
                    storeId: filterValues.storeId,
                    paymentMethod: filterValues.paymentMethod,
                  },
                },
              });
            }}
            loading={loadingSupplier}
            size="sm"
            disabled={filterValues.storeId === undefined || loading}
          >
            Descargar Reporte por Proveedor
          </CLoadingButton>
        </CCol>
      </CRow>
    </>
  );
};

export default SalesReport;
