import classNames from "classnames";
import { memo } from "react";
import SelectSearch, {
  DomProps,
  OptionSnapshot,
  SelectSearchOption,
  SelectSearchProps,
} from "react-select-search";
import { PaymentMethod } from "src/api/sales";
import { StoreType } from "src/api/stores";
import { parseMethodItem } from "src/helpers/payments";
import { findPointOfSale } from "src/helpers/stores";
import { normalizeChars } from "src/helpers/strings";
import Icon from "../Icon";
import { Book, Building } from "lucide-react";
import Woman from "../Icon/Woman";
import Man from "../Icon/Man";

export type SmartSelectProps = Omit<
  SelectSearchProps,
  "filterOptions" | "onBlur" | "onFocus"
> & {
  resultsCount?: number;
  emptyValue?: number | string;
  emptyLabel?: string;
  invalid?: boolean;
  style?: any;
  onBlur?: SelectSearchProps["onBlur"];
  onFocus?: SelectSearchProps["onFocus"];
  type?: SmartSelectType;
};

export enum SmartSelectType {
  Default = "default",
  Stores = "stores",
  Warehouses = "warehouses",
  PaymentMethods = "paymentMethods",
  Customers = "customers",
}

const renderPaymentMethod = (
  props: Pick<DomProps, "value" | "disabled">,
  option: SelectSearchOption,
  rest: OptionSnapshot
) => {
  const currentType = parseMethodItem(option.type as PaymentMethod);
  const CustomIcon = currentType?.CustomIcon;

  return (
    <button
      {...props}
      className={classNames(
        "d-flex align-items-center gap-1 select-search-option",
        {
          "select-search-is-selected": rest.selected,
        }
      )}
    >
      {currentType?.CustomIcon ? (
        <CustomIcon />
      ) : (
        <Icon name={currentType?.icon} color="black" />
      )}

      {option.name}
    </button>
  );
};

const renderStore = (
  props: Pick<DomProps, "value" | "disabled">,
  option: SelectSearchOption,
  rest: OptionSnapshot
) => {
  const currentType = findPointOfSale(option.type as StoreType);
  const StoreIcon = () => currentType?.icon;

  return (
    <button
      {...props}
      className={classNames(
        "d-flex align-items-center gap-1 select-search-option",
        {
          "select-search-is-selected": rest.selected,
        }
      )}
    >
      <StoreIcon />

      {option.name}
    </button>
  );
};

const renderCustomer = (
  props: Pick<DomProps, "value" | "disabled">,
  option: SelectSearchOption,
  rest: OptionSnapshot
) => {
  const Icon =
    option.genre === "M"
      ? Man
      : option.genre === "F"
      ? Woman
      : option.genre === "S"
      ? Building
      : Book;

  return (
    <button
      {...props}
      className={classNames(
        "d-flex align-items-center gap-1 select-search-option",
        {
          "select-search-is-selected": rest.selected,
        }
      )}
    >
      <Icon />

      {option.name}
    </button>
  );
};

const renderWarehouse = (
  props: Pick<DomProps, "value" | "disabled">,
  option: SelectSearchOption,
  rest: OptionSnapshot
) => {
  return (
    <button
      {...props}
      className={classNames(
        "d-flex align-items-center gap-1 select-search-option",
        {
          "select-search-is-selected": rest.selected,
        }
      )}
    >
      {option.name}

      {option.types?.map((type, index) => {
        const currentType = findPointOfSale(type as StoreType);
        const StoreIcon = () => currentType?.icon;

        return <StoreIcon key={index} />;
      })}
    </button>
  );
};

const SmartSelect = memo(
  ({
    debounce = 300,
    emptyLabel,
    emptyValue = -1,
    name,
    options,
    resultsCount = 6,
    search,
    onBlur,
    onFocus,
    type = SmartSelectType.Default,
    ...props
  }: SmartSelectProps) => {
    const handleBlur = (e: Event) => {
      if (onBlur) {
        onBlur?.(e);
      }
    };

    const handleFocus = (e: Event) => {
      if (onFocus) {
        onFocus?.(e);
      }
    };

    if (type === SmartSelectType.Stores) {
      props.renderOption = renderStore;
    }

    if (type === SmartSelectType.Warehouses) {
      props.renderOption = renderWarehouse;
    }

    if (type === SmartSelectType.PaymentMethods) {
      props.renderOption = renderPaymentMethod;
    }

    if (type === SmartSelectType.Customers) {
      props.renderOption = renderCustomer;
    }

    return (
      <SelectSearch
        {...props}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        debounce={debounce}
        emptyMessage={emptyLabel}
        options={options}
        search={search}
        fuzzySearch={search}
        filterOptions={
          search
            ? [
                (items: SelectSearchOption[], q: string) => {
                  if (!search) {
                    return items;
                  }

                  return items
                    .filter((item) =>
                      normalizeChars(item.name)
                        .toLowerCase()
                        .includes(q.toLowerCase())
                    )
                    .slice(0, resultsCount);
                },
              ]
            : []
        }
      />
    );
  }
);

export type { SelectSearchOption };
export default SmartSelect;
