import {
  CCol,
  CButton,
  CCardFooter,
  CRow,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
  CNavItem,
  CNavLink,
  CNav,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
} from "@coreui/react-pro";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router";

import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";

import { Customer } from "src/api/customers";

import { Store } from "src/api/stores";
import { useRef, useState } from "react";
import { AppLoader } from "src/components/Loader/Loader";

import CustomerData from "./components/CustomerData";
import CustomerSales from "./components/CustomerSales";
import CustomerChecking from "./components/CustomerChecking";

const CustomerScreen = () => {
  const params = useParams();
  const customerId = Number(params.id);
  const navigate = useNavigate();

  if (!customerId) {
    navigate("/customers");
  }

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const { data: customer, refetch } = useQuery<GraphQLFind<Customer>>(
    Api.Customers.GET_CUSTOMER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: customerId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  if (!stores?.data || !customer) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form
        customer={customer.data}
        stores={stores.data.data}
        refetch={refetch}
      />
    </CCol>
  );
};

const TabIndex = ["#basic", "#sales", "#account"];

const Form = ({
  customer,
  stores,
  refetch,
}: {
  customer: Customer;
  stores: Store[];
  refetch: () => void;
}) => {
  const customerDataRef = useRef<{
    save: () => void;
    setValues: (val: Partial<Customer>) => void;
    getValue: (val: keyof Customer) => void;
  }>(null);

  const location = useLocation();
  const { hash, pathname } = location;

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const initialTab = TabIndex.find((index) => index === hash)
    ? TabIndex.findIndex((index) => index === hash)
    : 0;

  const [currentTab, setCurrentTab] = useState<number>(initialTab);

  const onTabChange = (newIndex: number) => {
    navigate(`${pathname}${TabIndex[newIndex]}`);
    setCurrentTab(newIndex);
  };

  const [deleteMutation, { loading: delLoading }] = useMutation(
    Api.Customers.DELETE_CUSTOMER,
    {
      onCompleted: () => {
        navigate("/customers");
      },
    }
  );

  const [createAccountMutation, { loading: createLoading }] = useMutation(
    Api.CheckingAccounts.CREATE_ACCOUNT,
    {
      onCompleted: () => {
        refetch();
      },
    }
  );

  const [fetchAfipDataMutation, { loading: fetching }] = useLazyQuery(
    Api.Afip.FETCH_UTID,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ data }) => {
        if (data) {
          customerDataRef.current?.setValues({
            name: data.name,
            lastname: data.lastName,
            streetName: data.address.streetName,
            streetNumber: data.address.streetNumber ?? "",
          });
        } else {
          alert("No se ha encontrado informacion para el CUIT / CUIL");
        }
      },
      onError: () => {
        alert("No se ha encontrado informacion para el CUIT / CUIL");
      },
    }
  );

  const onDelete = () => {
    if (!delLoading) {
      deleteMutation({
        variables: {
          id: customer.id,
        },
      });
    }
  };

  const hasCheckingAccount = customer.accounts?.length > 0;
  const isLoading = createLoading || delLoading || fetching;

  return (
    <CCard>
      <CCardHeader>
        #{customer.id} - {customer.name} {customer.lastname}
      </CCardHeader>

      <CCardBody>
        <CNav variant="tabs" role="tablist">
          <CNavItem>
            <CNavLink onClick={() => onTabChange(0)} active={currentTab === 0}>
              Datos Básicos
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink onClick={() => onTabChange(1)} active={currentTab === 1}>
              Compras
            </CNavLink>
          </CNavItem>
          {hasCheckingAccount && (
            <CNavItem>
              <CNavLink
                onClick={() => onTabChange(2)}
                active={currentTab === 2}
              >
                Cuenta Corriente
              </CNavLink>
            </CNavItem>
          )}
        </CNav>
        <CTabContent className="px-0 pt-3 pb-0">
          <CTabPane visible={currentTab === 0}>
            <CustomerData
              ref={customerDataRef}
              customer={customer}
              stores={stores}
            />
          </CTabPane>

          <CTabPane visible={currentTab === 1}>
            <CustomerSales customer={customer} />
          </CTabPane>

          {hasCheckingAccount && (
            <CTabPane visible={currentTab === 2}>
              <CustomerChecking customer={customer} />
            </CTabPane>
          )}
        </CTabContent>
      </CCardBody>

      {currentTab === 0 && (
        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="6">
              {!hasCheckingAccount && (
                <CLoadingButton
                  size="sm"
                  type="button"
                  color="info"
                  disabled={isLoading}
                  loading={createLoading}
                  className="mr-2"
                  onClick={() => {
                    createAccountMutation({
                      variables: {
                        input: {
                          customerId: customer.id,
                        },
                      },
                    });
                  }}
                >
                  Crear cuenta corriente
                </CLoadingButton>
              )}

              <CLoadingButton
                size="sm"
                type="button"
                color="secondary"
                disabled={isLoading}
                loading={fetching}
                onClick={() => {
                  const utid = customerDataRef.current?.getValue("utid");

                  if (utid) {
                    fetchAfipDataMutation({
                      variables: {
                        utid: customer.utid,
                      },
                    });
                  } else {
                    alert(
                      "Debes ingresar un CUIT / CUIL valido para consultar"
                    );
                  }
                }}
              >
                Obtener datos desde AFIP
              </CLoadingButton>
            </CCol>
            <CCol md="6" className="flex justify-content-end">
              {customer.deletedAt === null && (
                <>
                  <CButton
                    size="sm"
                    type="button"
                    color="danger"
                    className="mr-2"
                    disabled={isLoading}
                    onClick={() => setShowModal(true)}
                  >
                    Eliminar
                  </CButton>

                  <CModal
                    alignment="center"
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                  >
                    <CModalHeader closeButton>Eliminar Cliente</CModalHeader>
                    <CModalBody>
                      ¿Estás seguro de eliminar este cliente?
                    </CModalBody>
                    <CModalFooter>
                      <CButton
                        size="sm"
                        color="secondary"
                        onClick={() => setShowModal(false)}
                      >
                        Cancelar
                      </CButton>
                      <CButton size="sm" color="danger" onClick={onDelete}>
                        Si, quiero eliminarlo
                      </CButton>
                    </CModalFooter>
                  </CModal>
                </>
              )}

              <CLoadingButton
                size="sm"
                type="button"
                color="primary"
                disabled={isLoading}
                onClick={() => customerDataRef.current?.save()}
              >
                Guardar {customer.deletedAt !== null ? "y rehabilitar" : ""}
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      )}
    </CCard>
  );
};

export default CustomerScreen;
