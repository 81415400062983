import { ChangeEvent, forwardRef, useState } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormSelect,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
  CModalFooter,
  CModal,
  CButton,
  CModalHeader,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { normalize, normalizeUtid } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { Supplier, UpdateSupplier } from "src/api/suppliers";
import { useAdminStore } from "src/store";

const Screen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const supplierId = Number(params.id);

  if (!supplierId) {
    navigate("/suppliers");
  }

  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );
  const { data: supplier } = useQuery<GraphQLFind<Supplier>>(
    Api.Suppliers.GET_SUPPLIER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: supplierId,
      },
    }
  );

  if (!companies?.data.data || !supplier?.data) {
    return <AppLoader />;
  }

  if (companies?.data.data.length === 0) {
    navigate(`/suppliers`);
    return null;
  }

  return (
    <CCard>
      <Form companies={companies.data.data ?? []} supplier={supplier.data} />
    </CCard>
  );
};

const Form = ({
  supplier,
  companies,
}: {
  supplier: Supplier;
  companies: Company[];
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();

  const [mutation, { loading }] = useMutation(Api.Suppliers.UPDATE_SUPPLIER, {
    onCompleted: () => {
      navigate(-1);
    },
  });

  const [deleteSupplier, { loading: deleting }] = useMutation(
    Api.Suppliers.DELETE_SUPPLIER,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("identifier", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<UpdateSupplier>({
    initialValues: {
      companyId: supplier.company.id,
      name: supplier.name,
      notes: supplier.notes,
      legalName: supplier.legalName,
      utid: supplier.utid,
      email: supplier.email,
      phone: supplier.phone,
      contactEmail: supplier.contactEmail,
      contactPhone: supplier.contactPhone,
      contactName: supplier.contactName,
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          id: supplier.id,
          companyId: Number(input.companyId),
          input: {
            name: input.name?.trim().toUpperCase(),
            notes: input.notes?.trim() ?? null,
            utid: normalizeUtid(input.utid),
            legalName: input.legalName,

            email: input.email ?? null,
            phone: input.phone ?? null,

            contactEmail: input.contactEmail ?? null,
            contactPhone: input.contactPhone ?? null,
            contactName: input.contactName ?? null,
          },
        };

        logEvent("supplier.update", {
          input: data,
        });

        mutation({
          variables: data,
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCardHeader>Editar Proveedor: {supplier.name}</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="3">
            <CFormSelect
              id="companyId"
              disabled={companies.length <= 1}
              readOnly={companies.length <= 1}
              name="companyId"
              floatingLabel="Empresa"
              onChange={formik.handleChange}
              value={formik.values.companyId}
            >
              <option key="0" value="0">
                Selecciona Empresa
              </option>
              {companies.map((company: Company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </CFormSelect>
          </CCol>

          <CCol md="3">
            <CFormInput
              id="name"
              placeholder="Nombre"
              name="name"
              floatingLabel="Nombre"
              onChange={onNameChange}
              value={formik.values.name}
              {...getValidity(formik.values.name, formik.errors.name)}
            />
          </CCol>

          <CCol md="3">
            <CFormInput
              id="legalName"
              placeholder="Razón Social"
              name="legalName"
              floatingLabel="Razón Social"
              onChange={formik.handleChange}
              defaultValue={formik.values.legalName ?? ""}
              {...getValidity(formik.values.legalName, formik.errors.legalName)}
            />
          </CCol>

          <CCol md="3">
            <CFormInput
              id="utid"
              placeholder="CUIL / CUIT"
              name="utid"
              floatingLabel="CUIL / CUIT"
              onChange={formik.handleChange}
              defaultValue={formik.values.utid ?? ""}
              {...getValidity(formik.values.utid, formik.errors.utid)}
            />
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md="2">
            <CFormInput
              id="phone"
              placeholder="Teléfono"
              name="phone"
              type="phone"
              floatingLabel="Teléfono"
              onChange={formik.handleChange}
              defaultValue={formik.values.phone ?? ""}
              {...getValidity(formik.values.phone, formik.errors.phone)}
            />
          </CCol>

          <CCol md="3">
            <CFormInput
              id="email"
              placeholder="Email"
              name="email"
              type="email"
              floatingLabel="Email"
              onChange={formik.handleChange}
              defaultValue={formik.values.email ?? ""}
              {...getValidity(formik.values.email, formik.errors.email)}
            />
          </CCol>

          <CCol md="2">
            <CFormInput
              id="contactName"
              placeholder="Nombre del Contacto"
              name="contactName"
              floatingLabel="Nombre del Contacto"
              onChange={formik.handleChange}
              defaultValue={formik.values.contactName ?? ""}
              {...getValidity(
                formik.values.contactName,
                formik.errors.contactName
              )}
            />
          </CCol>

          <CCol md="3">
            <CFormInput
              id="contactEmail"
              placeholder="Email del Contacto"
              name="contactEmail"
              type="email"
              floatingLabel="Email del Contacto"
              onChange={formik.handleChange}
              defaultValue={formik.values.contactEmail ?? ""}
              {...getValidity(
                formik.values.contactEmail,
                formik.errors.contactEmail
              )}
            />
          </CCol>

          <CCol md="2">
            <CFormInput
              id="contactPhone"
              placeholder="Teléfono del Contacto"
              name="contactPhone"
              type="phone"
              floatingLabel="Teléfono del Contacto"
              onChange={formik.handleChange}
              defaultValue={formik.values.contactPhone ?? ""}
              {...getValidity(
                formik.values.contactPhone,
                formik.errors.contactPhone
              )}
            />
          </CCol>
        </CRow>

        <CRow className="mt-3">
          <CCol md={12}>
            <CFormInput
              id="notes"
              floatingLabel="Notas"
              placeholder="Notas"
              onChange={formik.handleChange}
              name="notes"
              defaultValue={formik.values.notes}
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow className="px-0">
          <CCol md="6">
            <Link to={`/suppliers/${supplier.id}/shipments`}>
              <CButton size="sm" color="info" type="button">
                Ver pedidos
              </CButton>
            </Link>
            <Link to={`/suppliers/${supplier.id}/products`} className="ml-2">
              <CButton size="sm" color="primary" type="button">
                Ver productos
              </CButton>
            </Link>
          </CCol>
          <CCol md="6" className="flex justify-content-end">
            {hasPermission("DELETE_SUPPLIER") && (
              <>
                <CLoadingButton
                  loading={showModal || deleting}
                  size="sm"
                  color="danger"
                  className="mr-2"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar
                </CLoadingButton>

                <CModal
                  alignment="center"
                  visible={showModal}
                  onClose={() => setShowModal(false)}
                >
                  <CModalHeader closeButton>
                    ¿Estás seguro de eliminar este proveedor?
                    {(supplier.products?.length ?? 0) > 0 && (
                      <>
                        <br />
                        Ten en cuenta que hay {supplier.products.length}{" "}
                        producto(s) asignado(s) a este proveedor. Estos mismos
                        seran desvinculados.
                      </>
                    )}
                  </CModalHeader>
                  <CModalFooter>
                    <CButton
                      size="sm"
                      color="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cancelar
                    </CButton>
                    <CLoadingButton
                      loading={deleting}
                      size="sm"
                      color="danger"
                      onClick={() => {
                        deleteSupplier({
                          variables: {
                            id: supplier.id,
                          },
                        });
                      }}
                    >
                      Si, quiero eliminarlo
                    </CLoadingButton>
                  </CModalFooter>
                </CModal>
              </>
            )}
            <CLoadingButton
              loading={loading}
              size="sm"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Guardar cambios
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export default forwardRef(Screen);
