import { forwardRef } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Category, CategorySchema, UpdateCategory } from "src/api/categories";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { normalize } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";

const Screen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const categoryId = Number(params.id);

  if (!categoryId) {
    navigate("/categories");
  }

  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );
  const { data: category } = useQuery<GraphQLFind<Category>>(
    Api.Categories.GET_CATEGORY,
    {
      variables: {
        id: categoryId,
      },
    }
  );

  if (!companies?.data.data || !category?.data) {
    return <AppLoader />;
  }

  if (companies?.data.data.length === 0) {
    navigate(`/categories`);
    return null;
  }

  return (
    <CCard>
      <Form companies={companies.data.data ?? []} category={category.data} />
    </CCard>
  );
};

const Form = ({
  category,
  companies,
}: {
  category: Category;
  companies: Company[];
}) => {
  const navigate = useNavigate();

  const [mutation, { error, loading }] = useMutation(
    Api.Categories.UPDATE_CATEGORY,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const onNameChange = (e) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("identifier", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<UpdateCategory>({
    initialValues: {
      companyId: category.company.id,
      name: category.name,
      identifier: category.identifier,
      notes: category.notes,
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          id: category.id,
          companyId: Number(input.companyId),
          name: input.name?.trim().toUpperCase(),
          identifier: input.identifier ?? normalize(input.name),
          notes: input.notes.trim() ?? null,
        };

        logEvent("category.update", {
          input: data,
        });

        mutation({
          variables: data,
        });
      }
    },
    validationSchema: CategorySchema,
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCardHeader>Editar Categoría: {category.name}</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs="12">
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="companyId">Empresa</CFormLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CFormSelect
                  id="companyId"
                  disabled={companies.length <= 1}
                  readOnly={companies.length <= 1}
                  name="companyId"
                  onChange={formik.handleChange}
                  value={formik.values.companyId}
                >
                  <option key="0" value="0">
                    Selecciona Empresa
                  </option>
                  {companies.map((company: Company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={onNameChange}
                  value={formik.values.name}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="identifier">Identificador</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="identifier"
                  placeholder="Identificador"
                  name="identifier"
                  disabled
                  value={formik.values.identifier ?? ""}
                  readOnly
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="notes">Notas</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="notes"
                  placeholder="Notas"
                  onChange={formik.handleChange}
                  name="notes"
                  defaultValue={formik.values.notes}
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow className="px-0">
          <CCol md="9">
            {error && (
              <ErrorMessage text="Los datos ingresados son incorrectos" />
            )}
          </CCol>
          <CCol md="3" className="flex justify-content-end">
            <CLoadingButton
              loading={loading}
              size="sm"
              disabled={loading}
              color="primary"
              type="submit"
            >
              Guardar cambios
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export default forwardRef(Screen);
