import { memo, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router";
import { CContainer } from "@coreui/react-pro";

// routes config
import routes from "../routes";
import MaintenanceAlert from "src/components/MaintenanceAlert";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  return (
    <main className="c-main h-100">
      <MaintenanceAlert />

      <CContainer fluid>
        <Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              );
            })}
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default memo(TheContent);
