import { memo } from "react";
import { useLocation } from "react-router";
import routes from "src/routes";
import { HelpCircle } from "lucide-react";
import { Route } from "src/types";
import { CLink, CTooltip } from "@coreui/react-pro";

const Help = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname: string, routes: Route[]) => {
    const currentRoute = routes.find((route) => route.path === pathname);

    return currentRoute?.helpVideo;
  };

  const currentHelp = getRouteName(currentLocation, routes);

  if (!currentHelp) {
    return null;
  }

  return (
    <CTooltip content="Ver video de ayuda">
      <CLink href={currentHelp} target="_blank">
        <HelpCircle />
      </CLink>
    </CTooltip>
  );
};

export default memo(Help);
