import { NavLink } from "react-router";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { CBadge, CNavLink, CSidebarNav } from "@coreui/react-pro";
import { Nullable } from "src/types";

export const AppSidebarNav = ({ items }) => {
  const navLink = (
    name: string,
    icon: string,
    badge: Nullable<{ text: string; color: string }>,
    indent = false
  ) => {
    return (
      <>
        {icon ||
          (indent && (
            <span className="nav-icon">
              <span className="nav-icon-bullet"></span>
            </span>
          ))}
        {name ?? ""}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item, index, indent = false) => {
    const {
      component,
      name,
      badge,
      icon,
      permissionKey,
      featureFlag,
      ...rest
    } = item;

    const Component = component;

    return (
      <Component as="div" key={index}>
        {rest.to || rest.href ? (
          <CNavLink {...(rest.to && { as: NavLink })} {...rest}>
            {navLink(name, icon, badge, indent)}
          </CNavLink>
        ) : (
          navLink(name, icon, badge, indent)
        )}
      </Component>
    );
  };

  const navGroup = (item, index) => {
    const { component, name, icon, items, to, featureFlag, ...rest } = item;

    const Component = component;

    return (
      <Component
        compact
        as="div"
        key={index}
        toggler={navLink(name, icon, null)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index, true)
        )}
      </Component>
    );
  };

  return (
    <CSidebarNav as={SimpleBar}>
      {items?.map((item, index) =>
        item.items ? navGroup(item, index) : navItem(item, index)
      )}
    </CSidebarNav>
  );
};
