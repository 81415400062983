import {
  CCol,
  CButton,
  CCard,
  CCardBody,
  CRow,
  CSmartTable,
  CCardHeader,
} from "@coreui/react-pro";
import { CashRegister, CashTransactionType } from "src/api/registers";
import { Link } from "react-router";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { GraphQLMeta } from "src/types";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import { parseTransacionKind } from "src/helpers/payments";

const MethodsScreen = () => {
  const { hasPermission } = useAdminStore();

  const { page, pageChange } = usePagination("registers/methods");
  const { data: methods, loading } = useQuery<GraphQLMeta<CashRegister>>(
    Api.Registers.LIST_TRANSACTION_TYPES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={4}>Métodos de Ingreso / Egreso</CCol>
                <CCol sm={8} className="d-flex justify-content-end">
                  {hasPermission("CREATE_REGISTER") && (
                    <>
                      <Link to="/registers/methods/new">
                        <CButton size="sm" color="info">
                          Crear método
                        </CButton>
                      </Link>
                    </>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={methods?.data.data || []}
                columns={[
                  {
                    key: "name",
                    label: "Nombre",
                  },
                  {
                    key: "type",
                    label: "Tipo",
                  },
                ]}
                scopedColumns={{
                  name: (item: CashTransactionType) => <td>{item.name}</td>,
                  type: (item: CashTransactionType) => (
                    <td>{parseTransacionKind(item.type)?.label}</td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />

              <Pagination meta={methods} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MethodsScreen;
