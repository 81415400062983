import Icon from "./Icon";
import Afip from "./Afip";
import BBVA from "./BBVA";
import CuentaDNI from "./CuentaDNI";
import Daruma from "./Daruma";
import Edenred from "./Edenred";
import GetNet from "./GetNet";
import MercadoLibre from "./MercadoLibre";
import MercadoPago from "./MercadoPago";
import MercadoShops from "./MercadoShops";
import Nave from "./Nave";
import Rappi from "./Rappi";
import TiendaNube from "./TiendaNube";
import ViuMi from "./ViuMi";

export {
  Afip,
  BBVA,
  CuentaDNI,
  Daruma,
  Edenred,
  GetNet,
  MercadoLibre,
  MercadoPago,
  MercadoShops,
  Nave,
  Rappi,
  TiendaNube,
  ViuMi,
};

export default Icon;
