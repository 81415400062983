import { gql } from "@apollo/client";
import { PriceListItem } from "./price-lists";

export type RappiItem = {
  available_quantity: number;
  price: number;
};

export type RappiVolumetry = {
  fragile: boolean;
  height: number;
  isHeavy: boolean;
  length: number;
  size: number | null;
  sourceDimensions: number;
  weight: number;
  width: number;
};

export type RappiPresentation = {
  description: string;
  isFrozen: boolean;
  isRefrigerated: boolean;
  purchasingUnits: number;
  quantity: number;
  unitType: string;
};

export type RappiProduct = {
  id: number;
  barcode: string;
  sku: string;
  name: string;
  description: string;
  status: string;
  price: number;
  stock: number;

  darumaStock: number;
  priceListItem: PriceListItem;
};

export type RappiOrderItem = {
  description: string;
  ean: string;
  id: number;
  name: string;
  price: number;
  price_with_discount: number;
  quantity: {
    type: string;
    units: number;
  };
  sku: string;
  unit_price: number;
  volumetry: RappiVolumetry;
};

export type RappiStoreProduct = {
  id: number;
  stock: number;
  price: number;
  storeID: number;
  salePrice: number;
};

export type RappiTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export type RappiStore = {
  id: number;
  country: string;
  status: string;
  name: string;
  lat: string;
  long: string;
  address: string;

  physical_store_id: number;
};

export type RappiAddress = {
  city: string;
  latitude: number;
  longitude: number;
  number: string;
  region: string;
  street_address: string;
  zip_code: string;
};

export type RappiCancelationInfo = {
  canceled_by: string;
  description: string;
};

export type RappiIdentification = {
  number: string;
  type: string;
};

export type RappiClient = {
  email: string;
  fidelity_id: string;
  first_name: string;
  identification: RappiIdentification;
  last_name: string;
  phone: string;
};

export type RappiQuantity = {
  type: string;
  units: number;
};

export type RappiCourier = {
  first_name: string;
  last_name: string;
  phone: string;
  vehicle_type: string;
};

export type RappiOrder = {
  address: RappiAddress;
  cancelation_info: RappiCancelationInfo;
  client: RappiClient;
  courier: RappiCourier;
  created_at: string;
  delivered_at: string;
  event_uuid: string;
  id: number;
  payment_method: string;
  place_at: string;
  products: RappiOrderItem[];
  status: RappiStatus;
  store: Pick<RappiStore, "id" | "name" | "physical_store_id">;
  total_price: number;
};

export type RappiEvent = RappiOrder;

export enum RappiStatus {
  Created = "created",
  InvoiceReported = "invoice_reported",
  Cancelled = "canceled",
  Finished = "finished",
}

export const DOWNLOAD_RAPPI_PRODUCTS = gql`
  mutation downloadRappiProducts($priceListId: Int!) {
    data: downloadRappiProducts(priceListId: $priceListId) {
      data {
        id
        name
        price
        sku
      }
      meta {
        lastPage
      }
    }
  }
`;

export const DOWNLOAD_IMAGES = gql`
  mutation downloadRappiImages($storeId: Int!) {
    data: downloadRappiImages(storeId: $storeId) {
      data {
        id
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query rappiProducts($filters: RappiProductsFilters) {
    data: rappiProducts(filters: $filters) {
      data {
        id
        name
        sku
        barcode
        stock
        price

        darumaStock

        priceListItem {
          id
          productId
          fractionId
          price

          product {
            sku
            name
            cost
            price
            stockeable
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const SYNC_PRODUCT = gql`
  mutation syncRappiProduct($itemId: Int!) {
    data: syncRappiProduct(itemId: $itemId) {
      id
    }
  }
`;

export const SYNC_PRODUCTS = gql`
  mutation syncRappiProducts($itemIds: [Int!]!) {
    data: syncRappiProducts(itemIds: $itemIds) {
      id
    }
  }
`;

export const DOWNLOAD_RAPPI_PRODUCT = gql`
  mutation downloadRappiProduct($storeId: Int!, $sku: String!, $price: Float!) {
    data: downloadRappiProduct(storeId: $storeId, sku: $sku, price: $price) {
      id
    }
  }
`;
