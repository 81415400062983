import { gql } from "@apollo/client";
import { Product, ProductSupplier } from "./products";
import { User } from "./users";
import { Category } from "./categories";
import request from "./request";

export const STOCK_DATE_START = new Date("2025-01-20T00:00:00Z");

export enum StockType {
  Unlimited = "UNLIMITED",
  Limited = "LIMITED",
  Mixed = "MIXED",
}

export type WarehouseStock = {
  id: number;
  warehouseId: number;
  cost: number;
  quantity: number;
  currentQuantity: number;
  initialQuantity: number;
  notes: string;
  retireId: number | null;

  product: Pick<Product, "id" | "name">;
  batch: {
    updatedAt: Date;
    user: User;
  };
  retire: {
    updatedAt: Date;
    user: User;
  };
};

export type Stock = {
  stock: number;
  cost: number;
  updatedAt: Date;
  productName: string;
  isLowStock: boolean;
  nextExpiresAt?: Date;
  product: Pick<Product, "id" | "name" | "barcodes" | "price"> & {
    category: Pick<Category, "name">;
    suppliers: ProductSupplier[];
  };
  items: WarehouseStock[];
};

export const LIST_STOCK = gql`
  query stock($filters: WarehouseStockFilters!) {
    data: stock(filters: $filters) {
      data {
        stock
        cost
        updatedAt
        productName
        isLowStock
        nextExpiresAt

        product {
          id
          name
          barcodes {
            barcode
          }
          price

          category {
            name
          }

          suppliers {
            supplier {
              name
            }
          }
        }

        items {
          id
          cost
          quantity
          notes
          retireId

          batch {
            updatedAt

            user {
              name
            }
          }

          retire {
            updatedAt

            user {
              name
            }
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const FETCH_WAREHOUSE_STOCK = gql`
  query fetchWarehouseStock($warehouseId: Int!, $productId: Int!, $limit: Int) {
    data: fetchWarehouseStock(
      warehouseId: $warehouseId
      productId: $productId
      limit: $limit
    ) {
      warehouseId
      productId
      data {
        id
        cost
        currentQuantity
        quantity
        notes
        retireId

        product {
          name
        }

        batch {
          updatedAt

          user {
            name
          }
        }

        retire {
          updatedAt

          user {
            name
          }
        }
      }
    }
  }
`;

export const uploadBaseStock = (body: any) =>
  request.post(`/imports/stock`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
