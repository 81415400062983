import { CBadge, CCard, CCardHeader, CCol, CRow } from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { Order, OrderStatus, parseOrderStatus } from "src/api/orders";
import { Product } from "src/api/products";
import { useAdminStore } from "src/store";
import Api from "src/api";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import PendingOrder from "./components/PendingOrder";
import ConfirmedOrder from "./components/ConfirmedOrder";
import { useCanNavigate } from "src/helpers/permissions";
import { useParams } from "react-router";

const EditOrder = () => {
  const params = useParams();
  const orderId = Number(params.id);

  useCanNavigate("SHOW_SALE", { route: "/orders", condition: !!orderId });

  const { currentCompany } = useAdminStore();
  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      variables: {
        filters: {
          companyId: currentCompany?.id,
          limit: 0,
        },
      },
    }
  );
  const { data: order } = useQuery<GraphQLFind<Order>>(Api.Orders.GET_ORDER, {
    variables: {
      id: orderId,
    },
  });

  if (!order?.data || !productsList?.data) {
    return <AppLoader />;
  }

  const orderStatus = order.data.status;
  const parsed = parseOrderStatus(orderStatus);

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="row align-items-center justify-content-center">
              <CCol sm={6}>Pedido #{order.data.id}</CCol>
              <CCol sm={6} className="text-right">
                <CBadge color={parsed.color}>{parsed.label}</CBadge>
              </CCol>
            </CRow>
          </CCardHeader>

          {orderStatus === OrderStatus.Pending && (
            <PendingOrder
              order={{
                ...order.data,
                items: order.data.items.map((item) => ({
                  ...item,
                  confirmedPrice:
                    item.priceListItem.price ?? item.requestedPrice,
                  confirmedTax: item.priceListItem.tax ?? item.requestedTax,
                  confirmedQuantity: item.requestedQuantity,
                })),
              }}
            />
          )}

          {[OrderStatus.Cancelled, OrderStatus.Confirmed].includes(
            orderStatus
          ) && <ConfirmedOrder order={order.data} />}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default EditOrder;
