import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CRow,
  CCardHeader,
  CFormTextarea,
  CFormSelect,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { useFormik } from "formik";

import { GraphQLMeta, GraphQLMutation } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { Customer, CustomerCreate, CustomerSchema } from "src/api/customers";
import { useAdminStore } from "src/store";
import { Store } from "src/api/stores";
import { logEvent } from "src/helpers/analytics";

const NewCustomer = () => {
  const { user, currentCompany } = useAdminStore();
  const navigate = useNavigate();

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          limit: 0,
          permission: "LIST_SALES",
        },
      },
    }
  );

  const [mutation, { loading }] = useMutation(Api.Customers.CREATE_CUSTOMER, {
    onCompleted: (res: GraphQLMutation<Customer>) => {
      navigate(`/customers/${res.data.id}`);
    },
  });

  const formik = useFormik<CustomerCreate>({
    initialValues: {
      companyId: currentCompany?.id ?? 0,
      storeId: 0,
      name: "",
      lastname: "",
      email: "",
      alias: "",
      genre: "",
      utid: "",
      notes: "",
      phone: "",
      streetName: "",
      streetNumber: "",
      discount: 0,
    },
    onSubmit: (data) => {
      if (!loading) {
        const input = {
          ...data,
          companyId: Number(data.companyId),
          storeId: Number(data.storeId),
        };

        logEvent("customers.create", {
          input,
        });

        mutation({
          variables: {
            input,
          },
        });
      }
    },
    validationSchema: CustomerSchema,
  });

  if (!stores?.data) {
    return null;
  }

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Crear Cliente</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" md="3">
                <CFormSelect
                  id="storeId"
                  floatingLabel="Punto de Venta"
                  name="storeId"
                  defaultValue={formik.values.storeId}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.storeId, formik.errors.storeId)}
                >
                  <option key="0" value="0">
                    Seleccione Punto de Venta
                  </option>
                  {stores.data.data.map((store) => (
                    <option key={store.id} value={store.id}>
                      {user?.isAdmin ? `${store.company.name} - ` : ""}
                      {store.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>

              <CCol xs="12" md="3">
                <CFormInput
                  id="utid"
                  floatingLabel="CUIL / CUIT"
                  placeholder="CUIL / CUIT"
                  name="utid"
                  defaultValue={formik.values.utid}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.utid, formik.errors.utid)}
                />
              </CCol>

              <CCol xs="12" md="3">
                <CFormSelect
                  id="genre"
                  name="genre"
                  floatingLabel="Sexo"
                  defaultValue={formik.values.genre}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.genre, formik.errors.genre)}
                >
                  <option value="U">Seleccione Sexo / Tipo</option>
                  <option value="M">Masculino</option>
                  <option value="F">Femenino</option>
                  <option value="S">Empresa</option>
                  <option value="U">Otro</option>
                </CFormSelect>
              </CCol>
            </CRow>

            <CRow className="mt-3">
              <CCol xs="12" md="3">
                <CFormInput
                  id="name"
                  floatingLabel="Nombre"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.name}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>

              <CCol xs="12" md="3">
                <CFormInput
                  id="lastname"
                  floatingLabel="Apellido"
                  placeholder="Apellido"
                  name="lastname"
                  defaultValue={formik.values.lastname}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.lastname,
                    formik.errors.lastname
                  )}
                />
              </CCol>

              <CCol xs="12" md="3">
                <CFormInput
                  id="alias"
                  floatingLabel="Alias"
                  placeholder="Alias"
                  name="alias"
                  defaultValue={formik.values.alias}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.alias, formik.errors.alias)}
                />
              </CCol>

              <CCol xs="12" md="2">
                <CFormInput
                  id="discount"
                  floatingLabel="Descuento"
                  placeholder="0 %"
                  name="discount"
                  type="number"
                  defaultValue={formik.values.discount}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.discount,
                    formik.errors.discount
                  )}
                />
              </CCol>
            </CRow>

            <CRow className="mt-3">
              <CCol xs="12" md="3">
                <CFormInput
                  id="email"
                  floatingLabel="Email"
                  placeholder="Email"
                  defaultValue={formik.values.email}
                  name="email"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.email, formik.errors.email)}
                />
              </CCol>

              <CCol xs="12" md="3">
                <CFormInput
                  id="phone"
                  floatingLabel="Teléfono"
                  placeholder="Teléfono"
                  defaultValue={formik.values.phone}
                  name="phone"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.phone, formik.errors.phone)}
                />
              </CCol>

              <CCol xs="12" md="4">
                <CFormInput
                  id="streetName"
                  floatingLabel="Calle"
                  placeholder="Calle"
                  name="streetName"
                  defaultValue={formik.values.streetName}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.streetName,
                    formik.errors.streetName
                  )}
                />
              </CCol>

              <CCol xs="12" md="2">
                <CFormInput
                  id="streetNumber"
                  floatingLabel="Altura"
                  placeholder="Altura"
                  name="streetNumber"
                  defaultValue={formik.values.streetNumber}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.streetNumber,
                    formik.errors.streetNumber
                  )}
                />
              </CCol>
            </CRow>

            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="notes">Notas</CFormLabel>
              </CCol>
              <CCol xs="12" md="12">
                <CFormTextarea
                  id="notes"
                  name="notes"
                  rows={4}
                  defaultValue={formik.values.notes}
                  onChange={formik.handleChange}
                  style={{ resize: "none" }}
                  {...getValidity(formik.values.notes, formik.errors.notes)}
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className="px-0 align-items-center">
              <CCol md="6"></CCol>
              <CCol md="6" className="flex justify-content-end">
                <CButton
                  color="primary"
                  size="sm"
                  disabled={loading || !formik.isValid}
                  type="submit"
                >
                  Crear
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewCustomer;
