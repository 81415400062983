import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CLink,
  CButton,
  CLoadingButton,
  CBadge,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";

import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import Api from "src/api";
import { GraphQLFind } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import { calculateSummary, parseSaleStatus } from "src/helpers/mercadoLibre";
import { useAdminStore } from "src/store";
import { StoreType } from "src/api/stores";
import {
  MercadoShopsSale,
  MercadoShopsSaleItem,
  MercadoShopsSalePayment,
} from "src/api/mercado-shops";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload, cilCloudUpload } from "@coreui/icons";
import { ElectronicInvoice } from "src/api/afip";
import { logEvent } from "src/helpers/analytics";
import { MercadoShops } from "src/components/Icon";
import { MercadoLibreOrderCheck } from "src/api/mercado-libre";
import SummaryRow from "../orders/components/SummaryRow";

const CustomerRow = ({
  customer,
}: {
  customer: MercadoShopsSale["customer"];
}) => {
  return (
    <>
      Comprador:{" "}
      <CLink href={customer.url} target="_blank">
        {customer.username}
      </CLink>
    </>
  );
};

const MercadoShopsSaleScreen = () => {
  const { hasPermission } = useAdminStore();
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const orderId = Number(params.id);
  const storeId = Number(params.storeId);
  const canSeeSale = hasPermission("SHOW_SALE", { type: "store", id: storeId });

  if (!storeId || !canSeeSale) {
    navigate("/mercado-shops");
  }

  if (storeId && !orderId) {
    navigate(`/mercado-shops/${storeId}/sales`);
  }

  const { data: sale } = useQuery<GraphQLFind<MercadoShopsSale>>(
    Api.MercadoShops.GET_MS_SALE,
    {
      fetchPolicy: "no-cache",
      variables: {
        storeId,
        orderId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  const { data: check } = useQuery<GraphQLFind<MercadoLibreOrderCheck>>(
    Api.MercadoLibre.CHECK_SALE,
    {
      variables: {
        storeId,
        orderId,
      },
    }
  );

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.MercadoLibre.DOWNLOAD_ORDER,
    {
      onCompleted: ({ data }) => {
        navigate(`/sales/${data.id}`);
      },
    }
  );

  const [attachMutation, { loading: attaching }] = useMutation(
    Api.Sales.ATTACH_INVOICE,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const downloadInvoice = async (invoice: ElectronicInvoice, save = false) => {
    if (!sale || downloading) {
      return null;
    }

    try {
      setDownloadingInvoice(true);

      logEvent("mercado-shops-sale.invoice.download", {
        orderId: sale.data.id,
        cae: invoice.cae,
      });

      const response = await Api.Afip.DOWNLOAD_ELECTRONIC_INVOICE(
        sale.data.id,
        invoice.cae,
        "invoice"
      );

      if (!save) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();

        logEvent("mercado-shops-sale.invoice.downloaded", {
          orderId: sale.data.id,
          cae: invoice.cae,
          filename: response.fileName,
        });

        link.remove();
      } else {
        return response;
      }
    } catch (e: any) {
      logEvent("mercado-shops-sale.invoice.download.error", {
        orderId: sale.data.id,
        cae: invoice.cae,
        error: e.message,
      });
    } finally {
      setDownloadingInvoice(false);
    }
  };

  if (!sale?.data) {
    return <AppLoader />;
  }

  const currentSale = sale.data;
  const summary = calculateSummary(currentSale);
  const currentCheck = check?.data;
  const productsCheck = currentCheck?.items?.every((item) => item.downloaded)
    ? "Descargados"
    : currentCheck?.items?.some((item) => item.downloaded)
    ? "Algunos descargados"
    : "No descargados";
  const parsed = parseSaleStatus(currentSale.status);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="flex">
              <CCol sm={6} className="d-flex align-items-center">
                <MercadoShops size={24} />{" "}
                <span className="ml-2">
                  Venta&nbsp;
                  <CLink
                    target="_blank"
                    href={`https://www.mercadolibre.com.ar/ventas/${currentSale.id}/detalle`}
                  >
                    #{currentSale.id}
                  </CLink>
                </span>
                <CBadge
                  className="ml-2"
                  style={{ height: 20, userSelect: "none" }}
                  color={parsed.color}
                >
                  {parsed.label}
                </CBadge>
              </CCol>
              <CCol
                sm={6}
                className="d-flex justify-content-end align-items-center"
              >
                {currentCheck?.sale.downloadable && (
                  <CLoadingButton
                    color="primary"
                    loading={downloading}
                    disabled={downloading}
                    onClick={() =>
                      downloadMutation({
                        variables: {
                          storeId,
                          orderId,
                        },
                      })
                    }
                    size="sm"
                  >
                    Descargar Venta
                  </CLoadingButton>
                )}
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow className="align-items-center justify-content-center mb-2">
                <CCol sm={6}>
                  <CustomerRow customer={currentSale.customer} />
                </CCol>
                <CCol sm={6} className="text-right">
                  Fecha y hora:{" "}
                  {dateFormat(currentSale.closedAt, "dd/MM/yyyy HH:mm 'hs'")}
                </CCol>
              </CRow>
              <hr className="pt-0 mt-0" />
              <CSmartTable
                itemsPerPage={currentSale.items.length}
                items={currentSale.items}
                columns={[
                  {
                    key: "quantity",
                    label: "Cantidad",
                    _props: { className: "text-left" },
                  },
                  { key: "product", label: "Producto" },
                  {
                    key: "price",
                    label: "Precio",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "subtotal",
                    label: "Subtotal",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  product: (item: MercadoShopsSaleItem) => <td>{item.name}</td>,
                  quantity: (item: MercadoShopsSaleItem) => (
                    <td className="text-left">{item.quantity}</td>
                  ),
                  price: (item: MercadoShopsSaleItem) => (
                    <td className="text-right">{formatCurrency(item.price)}</td>
                  ),
                  subtotal: (item: MercadoShopsSaleItem) => (
                    <td className="text-right">
                      {formatCurrency(item.quantity * item.price)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />

              <hr className="pt-0 mt-0" />

              <SummaryRow
                leftLabel="Cliente"
                leftValue={
                  currentCheck?.customer.downloaded
                    ? "Descargado"
                    : "No descargado"
                }
                label="Pagado por Cliente"
                value={summary.paid}
              />
              <SummaryRow
                leftLabel="Productos"
                leftValue={productsCheck}
                label="Envio"
                value={summary.shipping}
              />
              <SummaryRow
                leftLabel="Venta"
                leftValue={
                  currentCheck?.sale.downloaded ? "Descargada" : "No descargada"
                }
                leftLink={`/#/sales/${currentCheck?.sale.saleId}`}
                label="Subtotal"
                value={summary.subtotal}
              />
              <SummaryRow label="Comision" value={summary.fee} />
              <SummaryRow label="Recibido" value={summary.total} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className="mt-4">
        <CCol md={8} xl={6}>
          <CCard>
            <CCardHeader>
              <CCol sm={12}>Pagos</CCol>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                items={currentSale.payments || []}
                columns={[
                  { key: "type", label: "Tipo" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  type: (item: MercadoShopsSalePayment) => (
                    <td>{item.paymentMethod.name}</td>
                  ),
                  amount: (item: MercadoShopsSalePayment) => (
                    <td className="text-right">
                      {formatCurrency(item.amounts.paid)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>

        {sale.data.invoices.length > 0 && (
          <CCol sm={6}>
            <CCard>
              <CCardHeader>
                <CCol sm={12}>Facturas</CCol>
              </CCardHeader>
              <CCardBody>
                <CSmartTable
                  items={sale.data.invoices}
                  columns={[
                    { key: "cae", label: "CAE" },
                    { key: "type", label: "Tipo de Factura" },
                    { key: "date", label: "Fecha" },
                    // {
                    //   key: "amount",
                    //   label: "Monto",
                    //   _props: { className: "text-right" },
                    // },
                    {
                      key: "actions",
                      label: "Acciones",
                      _props: { className: "text-right" },
                    },
                  ]}
                  scopedColumns={{
                    cae: (invoice: ElectronicInvoice) => <td>{invoice.cae}</td>,
                    date: (invoice: ElectronicInvoice) => (
                      <td>
                        {dateFormat(invoice.createdAt, "dd/MM/yyyy HH:mm")}
                      </td>
                    ),
                    type: (invoice: ElectronicInvoice) => (
                      <td>{invoice.voucher.voucher.name}</td>
                    ),
                    amount: (invoice: ElectronicInvoice) => (
                      <td className="text-right">
                        {formatCurrency(invoice.voucher.total)}
                      </td>
                    ),
                    actions: (invoice: ElectronicInvoice) => (
                      <td className="text-right">
                        <CButton
                          size="sm"
                          color="primary"
                          disabled={downloadingInvoice}
                          onClick={() => {
                            downloadInvoice(invoice);
                          }}
                        >
                          <CIcon icon={cilCloudDownload} />
                        </CButton>

                        {!invoice.voucher.packId &&
                          sale.data.store.type === StoreType.MercadoShops && (
                            <CButton
                              size="sm"
                              className="ml-2"
                              disabled={attaching}
                              color="success"
                              onClick={() => {
                                attachMutation({
                                  variables: {
                                    id: sale.data.id,
                                    cae: invoice.voucher.cae,
                                  },
                                });
                              }}
                            >
                              <CIcon icon={cilCloudUpload} />
                            </CButton>
                          )}
                      </td>
                    ),
                  }}
                  tableProps={{
                    striped: true,
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        )}
      </CRow>
    </>
  );
};

export default MercadoShopsSaleScreen;
