import {
  CButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useMemo } from "react";
import { Store } from "src/api/stores";
import { dateFormat } from "src/helpers/dates";
import { storesWithPermission } from "src/helpers/permissions";
import { getValidity } from "src/helpers/validation";
import { useAdminStore } from "src/store";

const DuplicateModal = ({
  visible,
  formik,
  onDismiss,
  stores,
}: {
  stores: Store[];
  visible: boolean;
  onDismiss: () => void;
  formik: any;
}) => {
  const { user } = useAdminStore();

  const filteredStores = useMemo(() => {
    if (!stores) {
      return [];
    }

    const storesIds = storesWithPermission(
      user?.permissions ?? [],
      "CREATE_PRICE_LIST"
    );

    return stores
      .filter((store) => user?.isAdmin || storesIds.includes(store.id))
      .sort((a, b) => a.company.name.localeCompare(b.company.name));
  }, [stores, user?.isAdmin, user?.permissions]);

  return (
    <CModal size="lg" alignment="center" visible={visible}>
      <CModalHeader closeButton>Duplicar Lista de Precios</CModalHeader>
      <CModalBody>
        <CRow className="mt-3">
          <CCol md="3">
            <CFormLabel htmlFor="name">Nombre</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="name"
              placeholder="Nombre"
              name="name"
              onChange={formik.handleChange}
              {...getValidity(formik.values.name, formik.errors.name)}
            />
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CCol md="3">
            <CFormLabel htmlFor="profit">Ganancia por Defecto</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="profit"
              placeholder="0.00"
              name="profit"
              type="number"
              defaultValue={formik.values.profit}
              onChange={formik.handleChange}
              {...getValidity(formik.values.profit, formik.errors.profit)}
            />
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CCol md="3">
            <CFormLabel htmlFor="storeId">Punto de Venta</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormSelect
              id="storeId"
              name="storeId"
              defaultValue={formik.values.storeId}
              onChange={formik.handleChange}
              {...getValidity(formik.values.storeId, formik.errors.storeId)}
            >
              <option key="0" value="0">
                Seleccione Punto de Venta
              </option>
              {filteredStores.map((store) => (
                <option key={store.id} value={store.id}>
                  {user?.isAdmin ? `${store?.company?.name} - ` : ""}
                  {store.name}
                </option>
              ))}
            </CFormSelect>
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CCol md="3">
            <CFormLabel htmlFor="dateFrom">Fecha de Inicio</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="dateFrom"
              type="date"
              placeholder="Fecha de Inicio"
              name="dateFrom"
              defaultValue={
                formik.values.dateFrom
                  ? dateFormat(formik.values.dateFrom, "yyyy-MM-dd")
                  : ""
              }
              onChange={formik.handleChange}
              {...getValidity(formik.values.dateFrom, formik.errors.dateFrom)}
            />
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CCol md="3">
            <CFormLabel htmlFor="dateTo">Fecha de Fin</CFormLabel>
          </CCol>
          <CCol xs="12" md="9">
            <CFormInput
              id="dateTo"
              type="date"
              placeholder="Fecha de Fin"
              name="dateTo"
              onChange={formik.handleChange}
              {...getValidity(formik.values.dateTo, formik.errors.dateTo)}
            />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton type="button" size="sm" color="secondary" onClick={onDismiss}>
          Cancelar
        </CButton>
        <CButton
          type="button"
          size="sm"
          color="success"
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          Duplicar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DuplicateModal;
