import { memo } from "react";
import { useLocation } from "react-router";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react-pro";
import routes from "src/routes";
import { Breadcrumb, Route } from "src/types";

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname: string, routes: Route[]) => {
    const currentRoute = routes.find((route) => route.path === pathname);

    return currentRoute?.name;
  };

  const getBreadcrumbs = (location: string) => {
    const breads: Breadcrumb[] = [];
    let currentPathname = "";

    location.split("/").forEach((curr, index, array) => {
      if (curr === "") {
        currentPathname = curr;
      } else {
        currentPathname = `${currentPathname}/${curr}`;
      }

      breads.push({
        pathname: `/#${currentPathname}`,
        name: getRouteName(currentPathname, routes),
        active: index + 1 === array.length,
      });
    });

    return breads.filter((breadcrumb) => breadcrumb.name);
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="d-flex m-0 ms-2">
      <CBreadcrumbItem href="/">Daruma</CBreadcrumbItem>

      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active
              ? { active: true }
              : { href: breadcrumb.pathname })}
            key={index}
          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default memo(AppBreadcrumb);
