import classNames from "classnames";
import { memo, useEffect } from "react";
import { useLocation } from "react-router";
import { logScreenView } from "src/helpers/analytics";
import Config from "src/config";
import { Env } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import Api from "src/api";
import { queryStringToObject } from "src/helpers/strings";
import { useAdminStore } from "src/store";
import { getFeatureFlags, getMaintenanceStatus } from "src/helpers/database";
import TheContent from "./TheContent";
import TheHeader from "./TheHeader";
import TheSidebar from "./TheSidebar";

const HandleCode = ({ code }) => {
  const handle = async () => {
    const response = await Api.TiendaNube.connectStore(code);

    if (response.storeId) {
      window.location.href = `/#/stores/${response.storeId}`;
    }
  };

  useEffect(() => {
    handle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppLoader />;
};

const TheLayout = () => {
  const location = useLocation();
  const { search } = window.location;
  const querySearch = queryStringToObject(search);
  const { user, setFeatureFlags, currentCompany } = useAdminStore();

  const classes = classNames(
    "c-app c-default-layout h-100 flex overflow-hidden"
  );

  useEffect(() => {
    const { pathname, hash, ...rest } = location;
    logScreenView(pathname, { screenParams: rest });
    getMaintenanceStatus(`/${hash}`);
  }, [location]);

  useEffect(() => {
    if (currentCompany && user) {
      getFeatureFlags({
        companyId: currentCompany.id,
      });
    }
  }, [currentCompany, setFeatureFlags, user]);

  if (querySearch.code && !querySearch.state) {
    return <HandleCode code={querySearch.code} />;
  }

  return (
    <>
      {Config.siteEnv === Env.QA && (
        <div
          style={{
            height: 64,
            backgroundColor: "#a83232",
            width: 500,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 24,
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          ESTE ES UN ENTORNO DE PRUEBA
        </div>
      )}

      {user?.isP && (
        <div
          style={{
            height: 64,
            backgroundColor: "#a83232",
            width: 700,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 24,
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          ESTE ES UN ENTORNO DE IMPERSONALIZACION
        </div>
      )}
      <div className={classes}>
        <TheSidebar />
        <div
          className="c-wrapper w-100 h-100"
          style={{ alignContent: "flex-start" }}
        >
          <TheHeader />
          <div className="c-body w-100">
            <TheContent />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TheLayout);
