import { useMutation, useQuery } from "@apollo/client";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLink,
  CLoadingButton,
  CRow,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import { FooterItem } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { useMemo, useRef, useState } from "react";
import Api from "src/api";
import SmartSelect, { SmartSelectType } from "src/components/SmartSelect";
import { formatCurrency, removePercentageFromValue } from "src/helpers/numbers";
import { Generic, GraphQLFind, GraphQLMeta, GraphQLMutation } from "src/types";
import { Quote, QuoteItem, QuoteStatus } from "src/api/quotes";
import { Link, useNavigate, useParams } from "react-router";
import { PriceListItem } from "src/api/price-lists";
import { AppLoader } from "src/components/Loader/Loader";
import { Customer } from "src/api/customers";
import { useAdminStore } from "src/store";
import { getCustomer } from "src/helpers/sales";
import { parse } from "date-fns/parse";
import { add, format } from "date-fns";
import { File, TrashIcon } from "lucide-react";
import { parseQuoteStatus } from "src/helpers/quotes";

const QuoteScreen = () => {
  const { currentCompany } = useAdminStore();
  const navigate = useNavigate();
  const params = useParams();
  const quoteId = Number(params.id);

  if (!quoteId) {
    navigate("/quotes");
  }

  const { data: quote } = useQuery<GraphQLFind<Quote>>(Api.Quotes.GET_QUOTE, {
    fetchPolicy: "no-cache",
    variables: {
      id: quoteId,
    },
  });

  const { data: customers } = useQuery<GraphQLMeta<Customer>>(
    Api.Customers.LIST_CUSTOMERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          storeId: quote?.data.store.id,
          companyId: currentCompany?.id,
        },
      },
    }
  );

  if (!quote?.data || !customers?.data.data) {
    return <AppLoader />;
  }

  return <QuoteForm quote={quote.data} customers={customers.data.data} />;
};

const QuoteForm = ({
  quote,
  customers,
}: {
  quote: Quote;
  customers: Customer[];
}) => {
  const quantityRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<Date>(quote.date ?? new Date());
  const [validUntil, setValidUntil] = useState<Date>(
    quote.validUntil ?? add(date, { days: 7 })
  );
  const [notes, setNotes] = useState<string>(quote.notes ?? "");
  const [customer, setCustomer] = useState<Pick<Customer, "id" | "utid">>(
    quote.customer
      ? {
          id: quote.customer.id,
          utid: quote.customer.utid,
        }
      : {
          id: -1,
          utid: "",
        }
  );
  const [items, setItems] = useState<
    Pick<
      QuoteItem,
      | "productId"
      | "tax"
      | "priceListItemId"
      | "discount"
      | "price"
      | "quantity"
    >[]
  >(quote.items);

  const { data: priceListItemList } = useQuery<GraphQLMeta<PriceListItem>>(
    Api.PriceLists.LIST_PRICE_LIST_ITEMS,
    {
      fetchPolicy: "no-cache",
      skip: !quote.priceList.id,
      variables: {
        filters: {
          priceListId: quote.priceList.id,
          limit: 0,
        },
      },
    }
  );

  const [updateMutation, { loading: updating }] = useMutation<
    GraphQLMutation<Quote>
  >(Api.Quotes.UPDATE_QUOTE, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const [changeStatusMutation, { loading: updatingStatus }] = useMutation<
    GraphQLMutation<Quote>
  >(Api.Quotes.UPDATE_QUOTE_STATUS, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const products = useMemo(() => {
    if (!priceListItemList?.data.data) {
      return [];
    }

    return priceListItemList.data.data.map((priceListItem) => ({
      name: `${priceListItem.name} - ${
        priceListItem.product.barcodes.length > 0
          ? priceListItem.product.barcodes.map((b) => b.barcode).join(", ")
          : "Sin código de barra"
      } `,
      value: priceListItem.id,
    }));
  }, [priceListItemList]);

  const columns = [
    {
      key: "product",
      label: "Producto",
      _props: { className: "font-weight-bold" },
    },
    {
      key: "quantity",
      label: "Cantidad",
      _props: { className: "text-center font-weight-bold" },
    },
    {
      key: "price",
      label: "Precio Unitario",
      _props: { className: "text-right font-weight-bold" },
    },
  ];

  if (quote.status === QuoteStatus.InProgress) {
    columns.push({
      key: "discount",
      label: "Descuento (%)",
      _props: { className: "text-right font-weight-bold" },
    });
  }

  columns.push({
    key: "subtotal",
    label: "Subtotal",
    _props: { className: "text-right font-weight-bold" },
  });

  if (quote.status === QuoteStatus.InProgress) {
    columns.push({
      key: "actions",
      label: "",
      _props: { className: "text-right" },
    });
  }

  const generateFooter = () => {
    const footerStack: (FooterItem | string)[] = [
      {
        label: "Totales",
      },
      {
        label: `${items.reduce((prev, curr) => prev + curr.quantity, 0)} items`,
        _props: { className: "text-center" },
      },
      "",
    ];

    if (quote.status === QuoteStatus.InProgress) {
      footerStack.push("");
    }

    footerStack.push({
      label: formatCurrency(
        items.reduce(
          (prev, curr) =>
            prev +
            removePercentageFromValue(curr.price, curr.discount) *
              curr.quantity,
          0
        )
      ),
      _props: { className: "text-right" },
    });

    if (quote.status === QuoteStatus.InProgress) {
      footerStack.push("");
    }

    return footerStack;
  };

  const formik = useFormik({
    initialValues: {
      productId: 0,
      priceListItemId: 0,
      product: {
        name: "",
      },
      price: 0,
      tax: 0,
      quantity: 0,
    },
    onSubmit: (values) => {
      if (values.productId > 0 && values.quantity > 0) {
        setItems((prev) => {
          if (prev.find((item) => item.productId === values.productId)) {
            return prev.map((prevItem) => {
              if (prevItem.productId === values.productId) {
                return {
                  ...prevItem,
                  quantity: prevItem.quantity + values.quantity,
                };
              }

              return prevItem;
            });
          }

          const newItem = {
            productId: values.productId,
            priceListItemId: values.priceListItemId,
            product: {
              name: values.product.name,
            },
            price: values.price,
            tax: values.tax,
            discount: 0,
            quantity: values.quantity,
          };

          return [...(prev ?? {}), newItem];
        });

        formik.resetForm();
      }
    },
  });

  const deleteItem = (item: QuoteItem) => {
    setItems((prev) =>
      prev.filter((i) => i.priceListItemId !== item.priceListItemId)
    );
  };

  const updateItem = (item: QuoteItem, value: number) => {
    setItems((prev) =>
      prev.map((i) => {
        if (i.priceListItemId === item.priceListItemId) {
          return {
            ...i,
            discount: value,
          };
        }

        return i;
      })
    );
  };

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={12}>
                Presupuesto #{quote.id} {quote.saleId && ` - `}
                {quote.saleId && (
                  <Link to={`/sales/${quote.saleId}`}>
                    Venta #{quote.saleId}
                  </Link>
                )}
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody>
            <CForm className="mb-3" onSubmit={formik.handleSubmit}>
              <CRow>
                <CCol sm={6}>
                  <CFormLabel>
                    Lista de Precios: <strong>{quote.priceList.name}</strong>
                  </CFormLabel>
                </CCol>
                <CCol sm={6} className="text-right">
                  <CBadge color={parseQuoteStatus(quote.status).color}>
                    {parseQuoteStatus(quote.status).label}
                  </CBadge>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={5}>
                  <CFormLabel htmlFor="customerId">
                    Cliente
                    {quote.status !== QuoteStatus.InProgress &&
                      `: ${getCustomer(quote.customer)}`}
                  </CFormLabel>
                  {quote.status === QuoteStatus.InProgress && (
                    <SmartSelect
                      name="customerId"
                      search
                      type={SmartSelectType.Customers}
                      placeholder="Selecciona un cliente"
                      emptyLabel="No tienes clientes"
                      options={customers.map((c) => ({
                        value: c.id,
                        name: getCustomer(c),
                        genre: c.genre,
                      }))}
                      onChange={(e) => {
                        const currentCustomer = customers.find(
                          (c) => c.id === Number(e)
                        );

                        if (currentCustomer) {
                          setCustomer({
                            id: currentCustomer.id,
                            utid: currentCustomer.utid,
                          });
                        } else {
                          setCustomer({
                            id: -1,
                            utid: "",
                          });
                        }
                      }}
                      value={customer?.id?.toString()}
                    />
                  )}
                </CCol>

                {quote.status === QuoteStatus.InProgress && (
                  <>
                    <CCol sm={3}>
                      <CFormLabel htmlFor="date">Fecha</CFormLabel>

                      <CFormInput
                        type="date"
                        name="date"
                        defaultValue={format(date, "yyyy-MM-dd")}
                        onChange={(e) => {
                          setDate(
                            parse(e.target.value, "yyyy-MM-dd", new Date())
                          );
                        }}
                      />
                    </CCol>
                    <CCol sm={3}>
                      <CFormLabel htmlFor="validUntil">
                        Fecha de Expiracion
                      </CFormLabel>
                      <CFormInput
                        type="date"
                        name="validUntil"
                        defaultValue={format(validUntil, "yyyy-MM-dd")}
                        onChange={(e) => {
                          setValidUntil(
                            parse(e.target.value, "yyyy-MM-dd", new Date())
                          );
                        }}
                      />
                    </CCol>
                  </>
                )}
              </CRow>

              {quote.status !== QuoteStatus.InProgress && (
                <>
                  <CRow>
                    <CCol sm={5}>
                      <CFormLabel>
                        {`Fecha del Presupuesto: ${format(
                          quote.date,
                          "dd/MM/yyyy"
                        )}`}
                      </CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol sm={5}>
                      <CLink
                        className="d-flex align-items-center"
                        target="_blank"
                        href={quote.pdfUrl}
                      >
                        <File className="mr-2" /> Descargar en PDF
                      </CLink>
                    </CCol>
                  </CRow>
                </>
              )}

              {quote.status === QuoteStatus.InProgress && (
                <CRow className="mt-3">
                  <CCol className="product-search" sm={5}>
                    <CFormLabel htmlFor="productId">Producto</CFormLabel>
                    <SmartSelect
                      name="productId"
                      search
                      placeholder="Selecciona un producto"
                      emptyLabel="No tienes productos stockeables"
                      options={products}
                      onChange={(e) => {
                        const priceListItem = priceListItemList?.data.data.find(
                          (p) => p.id === Number(e)
                        );

                        if (priceListItem) {
                          formik.setValues({
                            productId: priceListItem.productId,
                            priceListItemId: priceListItem.id,
                            price: priceListItem.price,
                            product: {
                              name: priceListItem.name,
                            },
                            tax: priceListItem.tax,
                            quantity: formik.values.quantity,
                          });

                          quantityRef.current?.focus();
                        }
                      }}
                      value={formik.values.priceListItemId.toString()}
                    />
                  </CCol>
                  <CCol sm={2}>
                    <CFormLabel>Precio</CFormLabel>
                    <CFormInput
                      value={formik.values.price}
                      name="price"
                      type="number"
                      min={0}
                      onChange={formik.handleChange}
                    />
                  </CCol>
                  <CCol sm={2}>
                    <CFormLabel>Cantidad</CFormLabel>
                    <CFormInput
                      value={formik.values.quantity}
                      name="quantity"
                      ref={quantityRef}
                      min={1}
                      type="number"
                      onChange={formik.handleChange}
                    />
                  </CCol>
                  <CCol
                    sm={3}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <CLoadingButton color="primary" type="submit" size="sm">
                      Agregar
                    </CLoadingButton>
                  </CCol>
                </CRow>
              )}
            </CForm>

            <CSmartTable
              itemsPerPage={items.length ?? 10}
              items={items}
              noItemsLabel="Aun no has agregado items a este presupuesto"
              columns={columns}
              footer={generateFooter()}
              scopedColumns={{
                product: (item: QuoteItem) => <td>{item.product.name}</td>,
                quantity: (item: QuoteItem) => (
                  <td align="center">{item.quantity}</td>
                ),
                price: (item: QuoteItem) => (
                  <td align="right">{formatCurrency(item.price)}</td>
                ),
                subtotal: (item: QuoteItem) => (
                  <td align="right">
                    {formatCurrency(
                      removePercentageFromValue(item.price, item.discount) *
                        item.quantity
                    )}
                  </td>
                ),
                discount: (item: QuoteItem) => (
                  <td align="right" width="150">
                    <CFormInput
                      onChange={(e) => {
                        const value = Number(e.target.value);

                        updateItem(item, value);
                      }}
                      defaultValue={item.discount}
                      className="text-right"
                      max={100}
                      min={0}
                    />
                  </td>
                ),
                actions: (item: QuoteItem) => (
                  <td align="right">
                    <CTooltip content="Eliminar item">
                      <CButton
                        type="button"
                        size="sm"
                        color="danger"
                        onClick={() => deleteItem(item)}
                        className="d-flex align-items-center"
                      >
                        <TrashIcon size={18} />
                      </CButton>
                    </CTooltip>
                  </td>
                ),
              }}
            />
            <CRow>
              <CCol sm={6}>
                {quote.status === QuoteStatus.InProgress ? (
                  <CFormTextarea
                    style={{ height: 120, resize: "none" }}
                    name="notes"
                    label="Notas"
                    rows={6}
                    defaultValue={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                ) : (
                  <>{notes !== "" && <CFormLabel>Notas: {notes}</CFormLabel>}</>
                )}
              </CCol>
            </CRow>
          </CCardBody>
          {[
            QuoteStatus.InProgress,
            QuoteStatus.Sent,
            QuoteStatus.Accepted,
          ].includes(quote.status) && (
            <CCardFooter>
              <CRow>
                <CCol className="d-flex justify-content-end">
                  {quote.status === QuoteStatus.InProgress && (
                    <>
                      <FooterButton
                        loading={updating}
                        customAction
                        onClick={() => {
                          updateMutation({
                            variables: {
                              id: quote.id,
                              input: {
                                date,
                                validUntil,
                                customer: {
                                  id: customer.id,
                                  utid: customer.utid ?? "",
                                },
                                notes,
                                items: items.map((item) => ({
                                  productId: item.productId,
                                  priceListItemId: item.priceListItemId,
                                  quantity: item.quantity,
                                  price: item.price,
                                  tax: item.tax,
                                  discount: item.discount,
                                })),
                              },
                            },
                          });
                        }}
                        quoteId={quote.id}
                        text="Guardar Cambios"
                      />

                      <FooterButton
                        loading={updatingStatus}
                        onClick={changeStatusMutation}
                        quoteId={quote.id}
                        status={QuoteStatus.Sent}
                        text="Enviar Presupuesto"
                        color="success"
                      />
                    </>
                  )}

                  {quote.status === QuoteStatus.Sent && (
                    <FooterButton
                      loading={updatingStatus}
                      onClick={changeStatusMutation}
                      quoteId={quote.id}
                      status={QuoteStatus.Accepted}
                      color="success"
                      text="Presupuesto Aceptado"
                    />
                  )}

                  {quote.status === QuoteStatus.Accepted && (
                    <FooterButton
                      loading={updatingStatus}
                      onClick={changeStatusMutation}
                      quoteId={quote.id}
                      status={QuoteStatus.SaleCreated}
                      text="Convertir en Venta"
                    />
                  )}
                </CCol>
              </CRow>
            </CCardFooter>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

const FooterButton = ({
  onClick,
  loading,
  quoteId,
  customAction = false,
  status,
  color = "primary",
  text,
}: {
  onClick: (p?: Generic) => void;
  loading: boolean;
  quoteId: number;
  text: string;
  color?: "danger" | "success" | "primary";
  customAction?: boolean;
  status?: QuoteStatus;
}) => {
  return (
    <CLoadingButton
      type="button"
      size="sm"
      className="ml-2"
      color={color}
      loading={loading}
      onClick={() => {
        if (!loading) {
          if (customAction) {
            onClick();
          } else {
            onClick({
              variables: {
                id: quoteId,
                status,
              },
            });
          }
        }
      }}
    >
      {text}
    </CLoadingButton>
  );
};
export default QuoteScreen;
