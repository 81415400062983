import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useLocation, useNavigate, useParams } from "react-router";
import Api from "src/api";
import { Batch } from "src/api/batches";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import { findBatchType } from "src/helpers/batches";
import { queryStringToObject } from "src/helpers/strings";

import SmartSelect from "src/components/SmartSelect";
import Pagination from "src/components/Pagination";
import { ChevronLeft } from "lucide-react";
import { Warehouse } from "src/api/warehouses";

export const AddStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  cost: yup.number().min(0.1).required(),
  quantity: yup.number().min(0.1).required(),
});

const InitialScreen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const { hasPermission, currentCompany, user } = useAdminStore();
  const querySearch = { ...queryStringToObject(search) };

  const warehouseId = Number(params.warehouseId);

  if (!warehouseId) {
    navigate(-1);
  }

  const { data: batches, refetch } = useQuery<GraphQLMeta<Batch>>(
    Api.Batches.LIST_BATCHES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          companyId: currentCompany?.id,
          warehouseId,
          type:
            !querySearch.type || querySearch.type === "ALL"
              ? undefined
              : [querySearch.type],
        },
      },
    }
  );

  const { data: warehouseData } = useQuery<GraphQLFind<Warehouse>>(
    Api.Warehouses.GET_WAREHOUSE,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: warehouseId,
      },
    }
  );
  const [createBatch, { loading }] = useMutation(Api.Batches.CREATE_BATCH, {
    onCompleted: ({ data }) => {
      navigate(`/stock/${warehouseId}/batches/${data.id}`);
      refetch();
    },
  });

  const { page, pageChange, resetAndSearch } = usePagination(
    `stock/${warehouseId}/batches`,
    refetch
  );

  if (!batches?.data.data || !warehouseData?.data) {
    return <AppLoader />;
  }

  const batchesList = batches.data.data;
  const warehouse = warehouseData.data;

  let canCreateBatch = false;

  if (warehouse.stores?.length > 0) {
    canCreateBatch = warehouse.stores?.some((store) =>
      hasPermission("CREATE_BATCH", { type: "store", id: store.id })
    );
  } else {
    canCreateBatch = hasPermission("CREATE_BATCH", {
      type: "company",
      id: warehouse.companyId,
    });
  }

  return (
    <CCard>
      <CCardHeader>
        <CRow className="align-items-center justify-content-center">
          <CCol sm={6} className="px-0 d-flex align-items-center">
            <CButton
              onClick={() => navigate("/stock")}
              variant="ghost"
              color="default"
              size="sm"
            >
              <ChevronLeft color="black" />
            </CButton>
            Ingresos de {warehouse?.name}
          </CCol>
          <CCol sm={6} className="text-right d-flex justify-content-end">
            <CCol sm={3}>
              <SmartSelect
                defaultValue={querySearch.type}
                onChange={(e) => {
                  resetAndSearch({
                    warehouseId,
                    type: e === "ALL" ? [] : [e],
                  });
                }}
                name="type"
                options={[
                  {
                    name: "Todos",
                    value: "ALL",
                  },
                  {
                    name: "Movimientos",
                    value: "MOVE",
                  },
                  {
                    name: "Facturas",
                    value: "INVOICE",
                  },
                  {
                    name: "Ajustes",
                    value: "COUNT",
                  },
                  {
                    name: "Devoluciones",
                    value: "RETURN",
                  },
                  {
                    name: "Remitos",
                    value: "QUOTE",
                  },
                  {
                    name: "Nuevo Producto",
                    value: "NEW_PRODUCT",
                  },
                ]}
              />
            </CCol>

            {canCreateBatch && (
              <CLoadingButton
                color="primary"
                loading={loading}
                disabled={loading}
                className="ml-3"
                size="sm"
                onClick={() => {
                  createBatch({
                    variables: {
                      input: {
                        companyId: currentCompany?.id,
                        userId: user?.id,
                        warehouseId,
                      },
                    },
                  });
                }}
              >
                Crear nuevo ingreso
              </CLoadingButton>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CSmartTable
          itemsPerPage={20}
          items={batchesList ?? []}
          noItemsLabel="Aun no has ingresado stock"
          columns={[
            { key: "id", label: "ID" },
            { key: "user", label: "Usuario" },
            { key: "warehouse", label: "Depósito" },
            {
              key: "type",
              label: "Tipo",
              _props: { className: "text-center" },
            },
            {
              key: "createdAt",
              label: "Fecha de Creación",
              _props: { className: "text-center" },
            },
            {
              key: "updatedAt",
              label: "Ultima actualización",
              _props: { className: "text-center" },
            },
            {
              key: "status",
              label: "Estado",
              _props: { className: "text-right" },
            },
          ]}
          scopedColumns={{
            user: (item: Batch) => (
              <td>
                {item.user.name} {item.user.lastname}
              </td>
            ),
            warehouse: (item: Batch) => <td>{item.warehouse.name}</td>,
            type: (item: Batch) => (
              <td className="text-center">{findBatchType(item.type)?.name}</td>
            ),
            createdAt: (item: Batch) => (
              <td className="text-center">
                {dateFormat(item.createdAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            updatedAt: (item: Batch) => (
              <td className="text-center">
                {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}
              </td>
            ),
            status: (item: Batch) => (
              <td className="text-right">
                {item.status === "OPEN" && "Abierto"}
                {item.status === "FULFILLED" && "Cargado"}
                {item.status === "CLOSED" && "Cerrado"}
              </td>
            ),
          }}
          tableProps={{
            striped: true,
          }}
          clickableRows
          onRowClick={(item: Item) => {
            navigate(`/stock/${warehouseId}/batches/${item.id}`);
          }}
        />

        <Pagination meta={batches} page={page} pageChange={pageChange} />
      </CCardBody>
    </CCard>
  );
};

export default InitialScreen;
