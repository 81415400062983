import packageInfo from "../package.json";
import { Company } from "./api/companies";
import { StoreUser, UserPermission, UserRole } from "./api/users";
import { OverridedMixpanel } from "mixpanel-browser";
import { AfipInvoiceType, AfipPointOfSale } from "./api/afip";
import { CNavLinkProps } from "@coreui/react-pro/dist/esm/components/nav/CNavLink";
import { ApolloError } from "@apollo/client";

export type Generic<T = unknown> = {
  [key: string]: T;
};

export type ExcelRow = {
  value: string;
  span?: number;
  type?: string;
  align?: string;
};

export type VoidFunction = () => void;

export type OnChangeEvent = {
  currentTarget: {
    name: string;
    value: string;
  };
};

export type WithDirty<T> = T & {
  dirty?: boolean;
};

export type WithMeta<T = unknown> = {
  data: T[];
  meta: {
    from: number;
    lastPage: number;
    page: number;
    perPage: number;
    to: number;
    total: number;
  };
};

export type GraphQLFind<T = unknown> = {
  data: T;
};

export type GraphQLMeta<T = unknown> = {
  data: WithMeta<T>;
};

export type GraphQLMutation<T = unknown> = {
  data: T;
};

export type NavItem = {
  component?: React.ForwardRefExoticComponent<
    CNavLinkProps & React.RefAttributes<HTMLLIElement>
  >;
  name: string;
  icon?: JSX.Element;
  route?: string;
  to?: string;
  permissionKey?: string;
  items?: NavItem[];
};

export const AppVersion = packageInfo.version;
export const CommitHash = packageInfo.commitHash;

/**
 *  USERS
 */

export type User = {
  id: number;
  name: string;
  lastname: string;
  email: string;
  createdAt: Date;
};

export type UserWithToken = User & {
  accessToken: string;
  isAdmin: boolean;
  image: string;
  permissions: UserPermission[];
  companies: Company[];
  companyUsers: CompanyUser[];
  storeUsers: StoreUser[];
  isP: boolean;
};

export type CompanyUser = {
  companyId: number;
  role: UserRole;
  userId: number;
};

export type SearchForm = {
  search: string;
  trashed?: number;
  status?: string;
};

export type WithDate<T = unknown> = T & {
  date: string;
};

declare module "yup" {
  interface StringSchema {
    integer(): StringSchema;
    numeric(): StringSchema;
    dniOrCuit(): StringSchema;
  }

  interface DateSchema {
    afterThan(ref: any): DateSchema;
  }
}

export type MixPanel = OverridedMixpanel;

export enum Env {
  Local = "local",
  QA = "qa",
  Production = "production",
}

export enum EventKey {
  ScreenView = "ScreenView",
  Logout = "Logout",
  LogEvent = "LogEvent",
}

export type LogEvent = {
  eventName: string;
  eventData: Generic;
};

export type WithTrash<T> = T & {
  deletedAt: Nullable<string>;
};

export type RoundingMethod = {
  floor: number;
  none: Nullable<number>;
  ceil: Nullable<number>;
};

export type RoundingConfig = {
  rounding: string;
  roundingMethod?: RoundingMethod;
};

export type ForwardedModalProps = {
  open: (...args: any[]) => void;
  close: (...args: any[]) => void;
};

export type MyEvent = {
  detail: string;
};

export type StorePermissionValue = {
  storeId: number;
  value: boolean;
};

export type CompanyPermissionValue = {
  companyId: number;
  value: boolean;
};

export type AfipData = {
  invoiceTypes: AfipInvoiceType[];
  pos: AfipPointOfSale[];
};

export enum Entity {
  Category = "category",
  Product = "product",
  Supplier = "supplier",
}

export enum Operation {
  Discount = "discount",
  Recharge = "recharge",
}

export enum Currency {
  Euro = "EUR",
  Peso = "ARS",
  Dolar = "USD",
  Real = "BRL",
  Chilean = "CLP",
  Guarani = "PYG",
  Sol = "PEN",
  Pound = "GBP",
  Uruguayan = "UYU",
}

export type ApolloErrorWithData<T = unknown> = ApolloError & {
  graphQLErrors: {
    data: T;
  }[];
};

export type Nullable<T = unknown> = T | null;

export type Breadcrumb = {
  pathname?: string;
  name?: string;
  active?: boolean;
};

export type Route = {
  path: string;
  name: string;
  helpVideo?: string;
};
