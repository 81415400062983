import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CFormSelect,
  CCardHeader,
} from "@coreui/react-pro";
import { useMutation } from "@apollo/client";
import { Company, CompanyCreation, CompanySchema } from "src/api/companies";
import { useNavigate } from "react-router";
import { useFormik } from "formik";

import Api from "src/api";
import { GraphQLMutation } from "src/types";
import { getValidity } from "src/helpers/validation";

const NewCompany = () => {
  const navigate = useNavigate();
  const [mutation, { loading }] = useMutation(Api.Companies.CREATE_COMPANY, {
    onCompleted: (res: GraphQLMutation<Company>) => {
      navigate(`/companies/${res.data.id}`);
    },
  });

  const formik = useFormik<CompanyCreation>({
    initialValues: {
      name: "",
      legalName: "",
      streetName: "",
      streetNumber: "",
      province: "",
      city: "",
      zipCode: "",
      description: "",
      utid: "",
      isFranchise: false,
    },
    onSubmit: (body) => {
      if (!loading) {
        mutation({
          variables: {
            input: {
              ...body,
              isFranchise:
                typeof body.isFranchise === "string"
                  ? body.isFranchise === "1"
                  : body.isFranchise,
            },
          },
        });
      }
    },
    validationSchema: CompanySchema,
  });

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Crear Empresa</CCardHeader>
          <CCardBody>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="legalName">Razón Social</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="legalName"
                  placeholder="Razón Social"
                  name="legalName"
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.legalName,
                    formik.errors.legalName
                  )}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="streetName">Calle</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="streetName"
                  placeholder="Calle"
                  name="streetName"
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.streetName,
                    formik.errors.streetName
                  )}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="streetNumber"
                  placeholder="Altura"
                  name="streetNumber"
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.streetNumber,
                    formik.errors.streetNumber
                  )}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="utid">CUIT</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="utid"
                  placeholder="CUIT"
                  name="utid"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.utid, formik.errors.utid)}
                />
              </CCol>
            </CRow>

            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="description">Descripción</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="description"
                  placeholder="Descripción"
                  defaultValue={formik.values.description}
                  name="description"
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.description,
                    formik.errors.description
                  )}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3">
                <CFormLabel htmlFor="isFranchise">Franquicia</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="isFranchise"
                  name="isFranchise"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.isFranchise ? 1 : 0}
                >
                  <option value="0">No</option>
                  <option value="1">Si</option>
                </CFormSelect>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className="px-0">
              <CCol md="9"></CCol>
              <CCol md="3" className="flex justify-content-end">
                <CButton
                  color="primary"
                  size="sm"
                  disabled={loading || !formik.isValid}
                  type="submit"
                >
                  Crear
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewCompany;
