import { Link, useNavigate, useParams } from "react-router";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CBadge,
  CLink,
  CLoadingButton,
  CButton,
} from "@coreui/react-pro";

import { useMutation, useQuery } from "@apollo/client";

import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import Api from "src/api";
import { GraphQLFind } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import {
  TiendaNubeOrder,
  TiendaNubeOrderCheck,
  TiendaNubeOrderItem,
} from "src/api/tienda-nube";
import SummaryRow from "../orders/components/SummaryRow";
import { parseOrderStatus } from "src/helpers/tiendaNube";
import { Customer } from "src/api/customers";
import Icon, { TiendaNube } from "src/components/Icon";
import { useState } from "react";
import { useAdminStore } from "src/store";
import { logEvent } from "src/helpers/analytics";
import { ElectronicInvoice } from "src/api/afip";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";

const renderCustomer = (customer: Customer) => {
  if (customer.createdAt) {
    return (
      <span>
        Cliente:{" "}
        <CLink href={`/#/customers/${customer.id}`}>{customer.name}</CLink>
      </span>
    );
  }

  return <span>Cliente: {customer.name}</span>;
};

const TiendaNubeSaleScreen = () => {
  const { hasPermission } = useAdminStore();
  const [downloadingInvoice, setDownloadingInvoice] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const storeId = Number(params.storeId);
  const orderId = Number(params.orderId);
  const canSeeSale = hasPermission("SHOW_SALE", { type: "store", id: storeId });

  if (!storeId || !canSeeSale) {
    navigate("/tienda-nube");
  }

  if (storeId && !orderId) {
    navigate(`/tienda-nube/${storeId}/sales`);
  }

  const { data: sale } = useQuery<GraphQLFind<TiendaNubeOrder>>(
    Api.TiendaNube.GET_SALE,
    {
      variables: {
        storeId,
        orderId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  const { data: check, loading: checking } = useQuery<
    GraphQLFind<TiendaNubeOrderCheck>
  >(Api.TiendaNube.CHECK_SALE, {
    variables: {
      storeId,
      orderId,
    },
  });

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.TiendaNube.DOWNLOAD_ORDER,
    {
      onCompleted: ({ data }) => {
        navigate(`/sales/${data.id}`);
      },
    }
  );

  const downloadInvoice = async (invoice: ElectronicInvoice, save = false) => {
    if (!sale || downloading) {
      return null;
    }

    try {
      setDownloadingInvoice(true);

      logEvent("tienda-nube-sale.invoice.download", {
        saleId: sale.data.id,
        cae: invoice.cae,
      });

      const response = await Api.Afip.DOWNLOAD_ELECTRONIC_INVOICE(
        sale.data.id,
        invoice.cae,
        "invoice"
      );

      if (!save) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName);
        document.body.appendChild(link);
        link.click();

        logEvent("tienda-nube-sale.invoice.downloaded", {
          saleId: sale.data.id,
          cae: invoice.cae,
          filename: response.fileName,
        });

        link.remove();
      } else {
        return response;
      }
    } catch (e: any) {
      logEvent("tienda-nube-sale.invoice.download.error", {
        saleId: sale.data.id,
        cae: invoice.cae,
        error: e.message,
      });
    } finally {
      setDownloadingInvoice(false);
    }
  };

  if (!sale?.data) {
    return <AppLoader />;
  }

  const currentSale = sale.data;
  const currentCheck = check?.data;
  const productsCheck = currentCheck?.items?.every((item) => item.downloaded)
    ? "Descargados"
    : currentCheck?.items?.some((item) => item.downloaded)
    ? "Algunos descargados"
    : "No descargados";
  const parsed = parseOrderStatus(currentSale.status);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="flex">
              <CCol sm={6} className="d-flex align-items-center">
                <TiendaNube size={24} />{" "}
                <span className="ml-2">Venta #{currentSale.number}</span>
                <CBadge
                  className="ml-2"
                  style={{ height: 20, userSelect: "none" }}
                  color={parsed.color}
                >
                  {parsed.label}
                </CBadge>
              </CCol>
              <CCol
                sm={6}
                className="d-flex justify-content-end align-items-center"
              >
                {currentCheck?.sale.downloadable && (
                  <CLoadingButton
                    color="primary"
                    loading={downloading}
                    disabled={downloading}
                    onClick={() =>
                      downloadMutation({
                        variables: {
                          storeId,
                          orderId,
                        },
                      })
                    }
                    size="sm"
                  >
                    Descargar Venta
                  </CLoadingButton>
                )}
              </CCol>
            </CCardHeader>
            <CCardBody>
              <CRow className="align-items-center justify-content-center mb-2">
                <CCol sm={6}>{renderCustomer(currentSale.customer)}</CCol>
                <CCol sm={6} className="text-right">
                  Fecha y hora:{" "}
                  {dateFormat(currentSale.date, "dd/MM/yyyy HH:mm 'hs'")}
                </CCol>
              </CRow>
              <hr className="pt-0 mt-0" />
              <CSmartTable
                itemsPerPage={currentSale.items.length}
                items={currentSale.items ?? []}
                columns={[
                  {
                    key: "downloaded",
                    label: "",
                    _props: { className: "text-left" },
                  },
                  {
                    key: "quantity",
                    label: "Cantidad",
                    _props: { className: "text-center" },
                  },
                  { key: "product", label: "Producto" },
                  {
                    key: "price",
                    label: "Precio",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "discount",
                    label: "Descuento",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "subtotal",
                    label: "Subtotal",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{
                  downloaded: (item: TiendaNubeOrderItem) => {
                    const tiendaNubeItem = currentCheck?.items?.find(
                      (tnItem) =>
                        tnItem.tiendaNubeProductId === item.productId &&
                        tnItem.tiendaNubeVariantId === item.variantId
                    );
                    const isDownloaded = tiendaNubeItem?.downloaded;

                    return (
                      <td>
                        <Icon
                          name={
                            checking
                              ? "clock"
                              : isDownloaded
                              ? "check"
                              : "x-mark"
                          }
                          color="black"
                        />
                      </td>
                    );
                  },
                  product: (item: TiendaNubeOrderItem) => {
                    const tiendaNubeItem = currentCheck?.items?.find(
                      (tnItem) =>
                        tnItem.tiendaNubeProductId === item.productId &&
                        tnItem.tiendaNubeVariantId === item.variantId
                    );

                    if (tiendaNubeItem?.productId) {
                      return (
                        <td>
                          <Link to={`/products/${tiendaNubeItem.productId}`}>
                            {item.name}
                          </Link>
                        </td>
                      );
                    }

                    return <td>{item.name}</td>;
                  },
                  quantity: (item: TiendaNubeOrderItem) => (
                    <td className="text-center">{item.quantity}</td>
                  ),
                  price: (item: TiendaNubeOrderItem) => (
                    <td className="text-right">{formatCurrency(item.price)}</td>
                  ),
                  discount: () => (
                    <td className="text-right">{formatCurrency(0)}</td>
                  ),
                  subtotal: (item: TiendaNubeOrderItem) => (
                    <td className="text-right">
                      {formatCurrency(item.quantity * item.price)}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                }}
              />

              <SummaryRow
                leftLabel="Cliente"
                leftValue={
                  checking
                    ? "Comprobando..."
                    : currentCheck?.customer.downloaded
                    ? "Descargado"
                    : "No descargado"
                }
                label="Subtotal"
                value={currentSale.summary.subtotal}
              />
              <SummaryRow
                leftLabel="Productos"
                leftValue={checking ? "Comprobando..." : productsCheck}
                label="Descuento"
                value={currentSale.summary.discount}
              />
              <SummaryRow
                leftLabel="Venta"
                leftValue={
                  checking
                    ? "Comprobando..."
                    : currentCheck?.sale.downloaded
                    ? "Descargada"
                    : "No descargada"
                }
                leftLink={
                  currentCheck?.sale.downloaded
                    ? `/#/sales/${currentCheck?.sale.saleId}`
                    : undefined
                }
                label="Total"
                value={
                  currentSale.summary.subtotal - currentSale.summary.discount
                }
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {sale.data.invoices.length > 0 && (
        <CRow className="mt-4">
          <CCol sm={12}>
            <CCard>
              <CCardHeader>
                <CCol sm={12}>Facturas</CCol>
              </CCardHeader>
              <CCardBody>
                <CSmartTable
                  items={sale.data.invoices}
                  columns={[
                    { key: "cae", label: "CAE" },
                    { key: "type", label: "Tipo de Factura" },
                    { key: "date", label: "Fecha" },
                    // {
                    //   key: "amount",
                    //   label: "Monto",
                    //   _props: { className: "text-right" },
                    // },
                    {
                      key: "actions",
                      label: "Acciones",
                      _props: { className: "text-right" },
                    },
                  ]}
                  scopedColumns={{
                    cae: (invoice: ElectronicInvoice) => <td>{invoice.cae}</td>,
                    date: (invoice: ElectronicInvoice) => (
                      <td>
                        {dateFormat(invoice.createdAt, "dd/MM/yyyy HH:mm")}
                      </td>
                    ),
                    type: (invoice: ElectronicInvoice) => (
                      <td>{invoice.voucher.voucher.name}</td>
                    ),
                    amount: (invoice: ElectronicInvoice) => (
                      <td className="text-right">
                        {formatCurrency(invoice.voucher.total)}
                      </td>
                    ),
                    actions: (invoice: ElectronicInvoice) => (
                      <td className="text-right">
                        <CButton
                          size="sm"
                          color="primary"
                          disabled={downloadingInvoice}
                          onClick={() => {
                            downloadInvoice(invoice);
                          }}
                        >
                          <CIcon icon={cilCloudDownload} />
                        </CButton>
                      </td>
                    ),
                  }}
                  tableProps={{
                    striped: true,
                  }}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}
    </>
  );
};

export default TiendaNubeSaleScreen;
