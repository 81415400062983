import writeXlsxFile from "write-excel-file";
import { truncateString } from "./strings";
import { alphabeticalOrder } from "./comparators";
import { formatCurrency } from "./numbers";
import { dateFormat } from "./dates";
import { findPaymentMethod } from "./payments";
import { Product } from "src/api/products";
import { Category } from "src/api/categories";
import { Supplier } from "src/api/suppliers";
import { PaymentMethod } from "src/api/sales";

export type PickedCategory = Pick<Category, "id" | "name">;
export type PickedSupplier = Pick<Supplier, "id" | "name">;
export type SummedItems = {
  product: Pick<Product, "id" | "name" | "suppliers" | "category">;
  quantity: number;
  price: number;
  cost: number;
};

export type SellsByEntity = {
  entity: PickedCategory | PickedSupplier;
  items: SummedItems[];
};

export type FilterValues = {
  storeId?: number;
  storeName?: string;
  dateFrom: string;
  dateTo: string;
  paymentMethod?: PaymentMethod;
};

export const generateSalesReport = (
  data: SellsByEntity[],
  filters: FilterValues
) => {
  const excelData: any[][] = [[]];
  const consolidatedSheet: any[] = [];

  const consolidated = data.flatMap(({ items }) => items);

  consolidatedSheet.push([
    { value: "Producto" },
    { value: "Proveedor(es)" },
    { value: "Categoría" },
    { value: "Cantidad" },
    { value: "Costo Total" },
    { value: "Importe Total" },
  ]);

  consolidated.forEach((item) => {
    consolidatedSheet.push([
      {
        value: item.product.name,
      },
      {
        value:
          item.product.suppliers
            .map((s) => s.supplier.name)
            .sort(alphabeticalOrder)
            .join("; ")
            .toUpperCase() ?? "",
      },
      {
        value: item.product.category?.name ?? "",
      },
      {
        value: item.quantity,
      },
      {
        value: item.cost,
      },
      {
        value: item.price,
      },
    ]);
  });

  const { tQuantity, tCost, tPrice } = consolidated.reduce(
    (prev, curr) => {
      return {
        ...prev,
        tQuantity: prev.tQuantity + curr.quantity,
        tCost: prev.tCost + curr.cost,
        tPrice: prev.tPrice + curr.price,
      };
    },
    {
      tQuantity: 0,
      tCost: 0,
      tPrice: 0,
    }
  );

  consolidatedSheet.push([
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: tQuantity,
    },
    {
      value: formatCurrency(tCost),
    },
    {
      value: formatCurrency(tPrice),
    },
  ]);

  data.forEach(({ items }, index) => {
    const excelSheet: any[] = [];

    excelSheet.push([
      { value: "Producto" },
      { value: "Proveedor(es)" },
      { value: "Categoría" },
      { value: "Cantidad" },
      { value: "Costo Total" },
      { value: "Importe Total" },
    ]);

    if (items.length === 0) {
      excelData[index] = excelSheet;

      return;
    }

    items.forEach((item) => {
      excelSheet.push([
        {
          value: item.product.name,
        },
        {
          value:
            item.product.suppliers
              .map((s) => s.supplier.name)
              .sort(alphabeticalOrder)
              .join("; ")
              .toUpperCase() ?? "",
        },
        {
          value: item.product.category?.name ?? "",
        },
        {
          value: item.quantity,
        },
        {
          value: item.cost,
        },
        {
          value: item.price,
        },
      ]);
    });

    const { quantity, cost, price } = items.reduce(
      (prev, curr) => {
        return {
          ...prev,
          quantity: prev.quantity + curr.quantity,
          cost: prev.cost + curr.cost,
          price: prev.price + curr.price,
        };
      },
      {
        quantity: 0,
        cost: 0,
        price: 0,
      }
    );

    excelSheet.push([
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: "",
      },
      {
        value: quantity,
      },
      {
        value: formatCurrency(cost),
      },
      {
        value: formatCurrency(price),
      },
    ]);

    excelData[index] = excelSheet;
  });

  const dateFrom = dateFormat(filters.dateFrom, "dd/MM/yyyy", {
    add: { hours: 3 },
  });
  const dateTo = dateFormat(filters.dateTo, "dd/MM/yyyy", {
    add: { hours: 3 },
  });

  const reportName = ["Reporte de egresos"];

  if (dateFrom === dateTo) {
    reportName.push(` del ${dateFrom}`);
  } else {
    reportName.push(` desde ${dateFrom} hasta ${dateTo}`);
  }

  reportName.push(` del punto de venta ${filters.storeName}`);

  if (filters.paymentMethod) {
    reportName.push(
      ` con método de pago ${findPaymentMethod(filters.paymentMethod)?.name}`
    );
  }

  writeXlsxFile([consolidatedSheet, ...excelData], {
    sheets: [
      `Consolidado (${consolidated.length})`,
      ...data.map(
        ({ entity, items }) =>
          `${truncateString(entity.name.replaceAll("/", "-"), 20)} (${
            items.length
          })`
      ),
    ],
    fileName: `${reportName.join("")}.xlsx`,
  });
};
