import { useMutation, useQuery } from "@apollo/client";
import {
  CAlert,
  CLoadingButton,
  CCol,
  CForm,
  CFormSelect,
  CRow,
  CFormInput,
  CFormCheck,
  CFormLabel,
  CMultiSelect,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { sortBy } from "lodash";
import { Fragment, useRef } from "react";
import Api from "src/api";
import {
  CashRegister,
  CashRegisterConfig,
  CashTransactionType,
} from "src/api/registers";
import { StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { paymentMethods } from "src/helpers/payments";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";

const RegisterConfigRow = ({
  register,
  transactionTypes,
}: {
  register: CashRegister;
  transactionTypes: CashTransactionType[];
}) => {
  const { hasPermission, user } = useAdminStore();
  const canEdit = hasPermission("UPDATE_REGISTER");
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Registers.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();
    },
  });

  const formik = useFormik<CashRegisterConfig>({
    initialValues: {
      ...register.config,
      acceptDiscounts: register.config.acceptDiscounts ? 1 : 0,
    },
    onSubmit: (values) => {
      if (!loading) {
        const defaultPaymentMethod = values?.paymentMethods.includes(
          values.defaultPaymentMethod
        )
          ? values.defaultPaymentMethod
          : values?.paymentMethods?.[0];

        const input = {
          paymentMethods: values?.paymentMethods,
          defaultPaymentMethod: paymentMethods.find(
            (pm) => pm.identifier === defaultPaymentMethod
          )?.type,
          postSaleAction: values.postSaleAction,
          acceptDiscounts: values.acceptDiscounts.toString() === "1",
          autoInvoice: Number(values.autoInvoice),
          autoInvoicePaymentMethods: values.autoInvoicePaymentMethods ?? [],
          defaultDiscount: Number(values.defaultDiscount),
          printerPaperSize: values.printerPaperSize,
          transactionTypes: values.transactionTypes,
          granelBarcode: values.granelBarcode,
        };

        mutation({
          variables: {
            id: register.id,
            input,
          },
        });
      }
    },
  });

  const options = paymentMethods
    .filter(({ id, digital }) => id > 0 && !digital)
    .map((paymentMethod) => ({
      text: paymentMethod.name,
      label: paymentMethod.name,
      value: paymentMethod.type,
      selected: register.config.autoInvoicePaymentMethods?.includes(
        paymentMethod.type
      ),
    }));

  const defaultPaymentMethod = paymentMethods.find(
    (pm) => pm.type === formik.values?.defaultPaymentMethod
  );

  return (
    <div className="position-relative">
      <CRow>
        <h5>
          🗳️ {register.name} - 🏣 {register.store.name}
        </h5>
      </CRow>

      <CRow className="mb-3">
        <CFormLabel>Métodos de Pago soportados</CFormLabel>
        <CCol md="12">
          {sortBy(
            paymentMethods.filter(({ id, digital }) => id > 0 && !digital),
            "name"
          ).map((paymentMethod) => (
            <CFormCheck
              inline
              key={paymentMethod.id}
              id={`paymentMethod-${paymentMethod.id}-${register.id}`}
              type="checkbox"
              name="paymentMethods"
              disabled={
                formik.values?.paymentMethods.length === 1 &&
                formik.values?.paymentMethods?.[0] === paymentMethod.identifier
              }
              value={paymentMethod.identifier}
              label={paymentMethod.name}
              defaultChecked={formik.values?.paymentMethods.includes(
                paymentMethod.identifier
              )}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  formik.setFieldValue("paymentMethods", [
                    ...formik.values.paymentMethods,
                    e.currentTarget.value,
                  ]);
                } else {
                  formik.setFieldValue(
                    "paymentMethods",
                    formik.values.paymentMethods.filter(
                      (pm) => pm !== e.currentTarget.value
                    )
                  );
                }
              }}
            />
          ))}
        </CCol>
      </CRow>

      {transactionTypes.length > 0 && (
        <CRow className="mb-3">
          <CFormLabel>Métodos de Ingreso / Egreso</CFormLabel>
          <CCol md="12">
            {sortBy(transactionTypes, "name").map((transactionType) => (
              <CFormCheck
                inline
                key={transactionType.id}
                id={`transactionType-${transactionType.id}-${register.id}`}
                type="checkbox"
                name="transactionTypes"
                value={transactionType.id}
                label={transactionType.name}
                defaultChecked={
                  !!formik.values?.transactionTypes?.find(
                    (t) => t === transactionType.id
                  )
                }
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    formik.setFieldValue("transactionTypes", [
                      ...formik.values.transactionTypes,
                      Number(e.currentTarget.value),
                    ]);
                  } else {
                    formik.setFieldValue(
                      "transactionTypes",
                      formik.values.transactionTypes.filter(
                        (t) => t !== Number(e.currentTarget.value)
                      )
                    );
                  }
                }}
              />
            ))}
          </CCol>
        </CRow>
      )}

      <CRow className="mb-3">
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Método de Pago por Defecto"
            name="paymentMethod"
            onChange={formik.handleChange}
            defaultValue={defaultPaymentMethod?.type}
          >
            <option value="">Ninguno</option>
            {paymentMethods
              ?.filter(
                ({ id, identifier, digital }) =>
                  formik.values.paymentMethods.includes(identifier) &&
                  !digital &&
                  id > 0
              )

              .sort((a, b) => a.name.localeCompare(b.name))
              .map((paymentMethod) => (
                <option value={paymentMethod.type} key={paymentMethod.id}>
                  {paymentMethod.name}
                </option>
              ))}
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Acción por defecto al finalizar venta"
            name="postSaleAction"
            onChange={formik.handleChange}
            defaultValue={formik.values.postSaleAction}
          >
            <option value="NONE">Abrir selector</option>
            <option value="PRINT">Imprimir</option>
            <option value="GO_SALES">Ir a ventas</option>
            <option value="GO_SALE">Ir a la venta</option>
            <option value="SALE">Seguir vendiendo</option>
          </CFormSelect>
        </CCol>
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Acepta descuentos?"
            name="acceptDiscounts"
            onChange={formik.handleChange}
            defaultValue={formik.values.acceptDiscounts ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormInput
            disabled={!canEdit}
            floatingLabel="Descuento por Defecto (en %)"
            name="defaultDiscount"
            onChange={formik.handleChange}
            defaultValue={formik.values.defaultDiscount}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Tamaño de Papel"
            name="printerPaperSize"
            onChange={formik.handleChange}
            defaultValue={formik.values.printerPaperSize}
          >
            <option value="A4">A4</option>
            <option value="HusaresA4">Husares A4</option>
            {user?.isAdmin && (
              <option value="HusaresPrueba">Husares Pruebas</option>
            )}
            <option value="Width80">80mm</option>
          </CFormSelect>
        </CCol>
        {register.store.config.invoiceTypes.length > 0 && (
          <>
            <CCol md="3">
              <CFormSelect
                disabled={!canEdit}
                floatingLabel="Facturación Automática"
                name="autoInvoice"
                onChange={formik.handleChange}
                defaultValue={formik.values.autoInvoice}
              >
                <option value="0">No</option>
                {register.store.config.invoiceTypes
                  .filter((invoiceType) => !invoiceType.name.includes("Nota"))
                  .map((invoiceType) => (
                    <option key={invoiceType.id} value={invoiceType.id}>
                      {invoiceType.name}
                    </option>
                  ))}
              </CFormSelect>
            </CCol>
            {formik.values.autoInvoice > 0 && (
              <CCol md="6">
                <CMultiSelect
                  id="autoInvoicePaymentMethods"
                  options={options}
                  label="Métodos de Pago"
                  selectionType="text"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "autoInvoicePaymentMethods",
                      e.map(({ value }) => value)
                    );
                  }}
                />
              </CCol>
            )}
          </>
        )}
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="Código de Barra a Granel"
            name="granelBarcode"
            onChange={formik.handleChange}
            defaultValue={formik.values.granelBarcode}
          >
            <option value="ByWeight">Por Peso</option>
            <option value="ByPrice">Por Precio</option>
          </CFormSelect>
        </CCol>
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />

      <CRow>
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              loading={loading}
              disabled={loading}
              color="primary"
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </div>
  );
};

const RegisterSettings = ({
  storeIds,
  transactionTypes,
}: {
  storeIds: number[];
  transactionTypes: CashTransactionType[];
}) => {
  const { data: registers } = useQuery<GraphQLMeta<CashRegister>>(
    Api.Registers.LIST_FLAT_REGISTERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          storeIds,
          type: StoreType.Store,
        },
      },
    }
  );

  if (!registers?.data.data) {
    return <AppLoader />;
  }

  return (
    <CForm>
      {registers.data.data.map((register, index) => (
        <Fragment key={register.id}>
          {registers.data.data.length > 1 && index > 0 && <hr />}
          <RegisterConfigRow
            register={register}
            transactionTypes={transactionTypes}
          />
        </Fragment>
      ))}
    </CForm>
  );
};

export default RegisterSettings;
