import { CAlert } from "@coreui/react-pro";
import { isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { dateFormat } from "src/helpers/dates";
import { fetchMaintenanceDate } from "src/helpers/database";
import MyEvents from "src/helpers/myEvents";

type MaintenanceDateRange = {
  start: Date;
  end: Date;
};

const MaintenanceAlert = () => {
  const [maintenanceDate, setMaintenanceDate] =
    useState<MaintenanceDateRange>();

  useEffect(() => {
    fetchMaintenanceDate();
  }, []);

  useEffect(() => {
    MyEvents.addEventListener(
      "maintenanceDate",
      (e: { detail: MaintenanceDateRange }) => {
        if (e.detail?.start && e.detail?.end) {
          setMaintenanceDate({
            start: new Date(e.detail.start),
            end: new Date(e.detail.end),
          });
        } else {
          setMaintenanceDate(undefined);
        }
      }
    );
  }, [setMaintenanceDate]);

  if (!maintenanceDate) {
    return null;
  }

  if (isAfter(new Date(), maintenanceDate.end)) {
    return null;
  }

  return (
    <CAlert color="danger">
      Te recordamos que el sistema tiene planeado estar en modo mantenimiento el
      día {dateFormat(maintenanceDate.start, "dd/MM")} desde las{" "}
      {dateFormat(maintenanceDate.start, "HH:mm")}hs hasta el día{" "}
      {dateFormat(maintenanceDate.end, "dd/MM")} a las{" "}
      {dateFormat(maintenanceDate.end, "HH:mm")}hs. Esto quiere decir que es
      probable que el sistema no este disponible o tenga funcionalidades
      reducidas.
    </CAlert>
  );
};

export default MaintenanceAlert;
