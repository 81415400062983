import { forwardRef, useImperativeHandle, useState } from "react";
import { CButton, CModal, CModalHeader, CModalFooter } from "@coreui/react-pro";

const DeleteModal = ({ onDelete }, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setShowModal(true),
      close: () => setShowModal(false),
    }),
    []
  );

  return (
    <CModal
      alignment="center"
      visible={showModal}
      onClose={() => setShowModal(false)}
    >
      <CModalHeader closeButton>
        ¿Estás seguro de eliminar esta lista de precios?
      </CModalHeader>
      <CModalFooter>
        <CButton
          size="sm"
          color="secondary"
          onClick={() => setShowModal(false)}
        >
          Cancelar
        </CButton>
        <CButton size="sm" color="danger" onClick={onDelete}>
          Si, quiero eliminarlo
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
export default forwardRef(DeleteModal);
