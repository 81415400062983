import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CAlert,
  CFormSelect,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Store } from "src/api/stores";
import {
  CashRegister,
  CashRegisterCreate,
  RegisterSchema,
} from "src/api/registers";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import Api from "src/api";
import { GraphQLMeta, GraphQLMutation } from "src/types";
import { getValidity } from "src/helpers/validation";

const NewRegister = () => {
  const navigate = useNavigate();
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const [mutation, { error, loading }] = useMutation(
    Api.Registers.CREATE_REGISTER,
    {
      onCompleted: (res: GraphQLMutation<CashRegister>) => {
        navigate(`/registers/${res.data.id}`);
      },
    }
  );

  const formik = useFormik<CashRegisterCreate>({
    initialValues: {
      storeId: 0,
      name: "",
      description: "",
    },
    onSubmit: (data) => {
      if (!loading) {
        mutation({
          variables: {
            ...data,
            storeId: Number(data.storeId),
          },
        });
      }
    },
    validationSchema: RegisterSchema,
  });

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Nueva Caja Registradora</CCardHeader>
          <CCardBody>
            <CRow className="mt-3">
              <CCol md="3" className="table-center">
                <CFormLabel>Punto de Venta</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="storeId"
                  name="storeId"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.storeId, formik.errors.storeId)}
                >
                  <option key="0" value="0">
                    Selecciona Punto de Venta
                  </option>
                  {stores?.data.data.map((store: Store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol md="3" className="table-center">
                <CFormLabel htmlFor="description">Descripción</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="description"
                  placeholder="Descripción"
                  name="description"
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.description,
                    formik.errors.description
                  )}
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className="px-0 align-items-center">
              <CCol md="9">
                {error && (
                  <CAlert color="danger" className="my-0">
                    Ha ocurrido un error. Vuelve a intentar
                  </CAlert>
                )}
              </CCol>
              <CCol md="3" className="flex justify-content-end">
                <CLoadingButton
                  color="primary"
                  loading={loading}
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  Crear
                </CLoadingButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewRegister;
