import { forwardRef, useImperativeHandle, useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { Warehouse } from "src/api/warehouses";
import SmartSelect from "src/components/SmartSelect";
import { logEvent } from "src/helpers/analytics";
import { useNavigate } from "react-router";

const ShipmentToWarehouseModal = ({ shipmentId }, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const { data: warehouses } = useQuery<GraphQLMeta<Warehouse>>(
    Api.Warehouses.LIST_WAREHOUSES,
    { fetchPolicy: "no-cache", variables: { filters: { limit: 0 } } }
  );

  const [insertMutation, { loading }] = useMutation(
    Api.Batches.INSERT_INTO_BATCHES,
    {
      onCompleted: ({ data }) => {
        navigate(`/stock/${data.warehouseId}/batches`, {
          state: {
            batchId: data.id,
            warehouseId: data.warehouseId,
          },
        });
      },
    }
  );

  useImperativeHandle(
    ref,
    () => ({
      open: () => setShowModal(true),
      close: () => {
        if (!loading) {
          setShowModal(false);
        }
      },
    }),
    [loading]
  );

  const formik = useFormik({
    initialValues: {
      warehouseId: 0,
      shipmentId,
    },
    onSubmit: (input) => {
      if (!loading) {
        insertMutation({
          variables: input,
        });
      }
    },
  });

  return (
    <CModal
      alignment="center"
      visible={showModal}
      backdrop="static"
      onClose={() => {
        if (!loading) {
          setShowModal(false);
          formik.resetForm();
        }
      }}
    >
      <CModalHeader closeButton>Cargar pedido como ingreso</CModalHeader>
      <CModalBody>
        <SmartSelect
          search
          defaultValue={formik.values.warehouseId?.toString()}
          onChange={(e) => {
            logEvent("shipment.send-to-warehouse", {
              warehouseId: e,
            });

            formik.setFieldValue("warehouseId", e ?? 0);
          }}
          name="warehouseId"
          options={[
            { value: 0, name: "Selecciona un depósito" },
            ...(warehouses?.data.data?.map((warehouse) => ({
              value: warehouse.id,
              name: warehouse.name,
            })) ?? []),
          ]}
        />
      </CModalBody>

      <CModalFooter>
        <CButton
          type="button"
          size="sm"
          disabled={loading}
          color="secondary"
          onClick={() => {
            if (!loading) {
              setShowModal(false);
            }
          }}
        >
          Cancelar
        </CButton>
        <CLoadingButton
          type="button"
          size="sm"
          onClick={() => formik.handleSubmit()}
          loading={loading}
          disabled={formik.values.warehouseId <= 0 || loading}
          color="success"
        >
          Ingresar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};
export default forwardRef(ShipmentToWarehouseModal);
