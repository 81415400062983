import { useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CSmartTable,
  CTooltip,
} from "@coreui/react-pro";
import { isBefore } from "date-fns/isBefore";
import { useNavigate, useParams } from "react-router";
import Api from "src/api";
import { STOCK_DATE_START, WarehouseStock } from "src/api/stock";
import { AppLoader } from "src/components/Loader/Loader";
import FeatureAlert from "src/containers/FeatureAlert";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { getPaginationProps } from "src/hooks/pagination";
import { GraphQLFind } from "src/types";

const StockDetailScreen = () => {
  const params = useParams();
  const navigate = useNavigate();

  const warehouseId = Number(params.warehouseId);
  const productId = Number(params.productId);

  if (!warehouseId) {
    navigate("/stock");
  }

  if (!productId) {
    navigate(`/stock?warehouseId=${warehouseId}`);
  }

  const { data: stockDetail } = useQuery<
    GraphQLFind<{
      productId: number;
      warehouseId: number;
      data: WarehouseStock[];
    }>
  >(Api.Stock.FETCH_WAREHOUSE_STOCK, {
    fetchPolicy: "no-cache",
    variables: { warehouseId, productId },
  });

  if (!stockDetail?.data?.data) {
    return <AppLoader />;
  }

  return (
    <>
      <FeatureAlert />

      <CCard>
        <CCardHeader>
          Detalles de Movimientos{" "}
          <strong>"{stockDetail.data.data[0]?.product.name}"</strong>
        </CCardHeader>
        <CCardBody>
          <CSmartTable
            items={stockDetail.data.data}
            itemsPerPage={20}
            {...getPaginationProps(stockDetail?.data.data ?? [])}
            columns={[
              { key: "user", label: "Usuario" },
              { key: "notes", label: "Notas" },
              {
                key: "quantity",
                label: "Cantidad Modificada",
                _props: { className: "text-center" },
              },
              {
                key: "newQuantity",
                label: "Cantidad Nueva",
                _props: { className: "text-center" },
              },
              { key: "cost", label: "Costo" },
              { key: "date", label: "Fecha de Cambio" },
            ]}
            scopedColumns={{
              user: (item: WarehouseStock) => (
                <td>
                  Modificado por{" "}
                  {item.batch ? item.batch.user.name : item.retire.user.name}
                </td>
              ),
              notes: (item: WarehouseStock) => <td>{item.notes}</td>,
              quantity: (item: WarehouseStock) => (
                <td className="text-center">
                  {item.retireId === 0 && "-"}
                  {item.quantity}
                </td>
              ),
              newQuantity: (item: WarehouseStock) => (
                <td className="text-center">
                  {isBefore(
                    item.batch ? item.batch.updatedAt : item.retire.updatedAt,
                    STOCK_DATE_START
                  ) ? (
                    <CTooltip content="No disponemos de informacion previa al 20/01/2025">
                      <span>-</span>
                    </CTooltip>
                  ) : (
                    item.currentQuantity
                  )}
                </td>
              ),
              cost: (item: WarehouseStock) => (
                <td className="text-right">
                  {item.batch ? formatCurrency(item.cost) : "-"}
                </td>
              ),
              date: (item: WarehouseStock) => (
                <td className="text-right">
                  {" "}
                  {item.batch
                    ? dateFormat(item.batch.updatedAt, "dd/MM/yyyy HH:mm")
                    : dateFormat(item.retire.updatedAt, "dd/MM/yyyy HH:mm")}
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default StockDetailScreen;
